import React, { useState } from "react";
import Heading from "../../tailwindUI/Heading";
import StackedList from "../../tailwindUI/StackedList";
import Pagination from "../../tailwindUI/Pagination";
import classes from "../../module.css/CancelledBookings.module.css";

import FollowUpRemarks from "../../Components/FollowUpRemarks";
import SmWrapper from "../../Components/SmWrapper";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import TableHeader from "../../Components/TableHeader";
const AutoAllocationCancelled = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const UserToken = localStorage.getItem("UserToken");
  const [searchTerm, setSearchTerm] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page") ? parseInt(queryParams.get("page")) : 1;
  const navigate = useNavigate();
  useEffect(() => {
    BookingList();
  }, [searchTerm === "", page]);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };
  const BreadCrumb = {
    pagename: "Auto Allocation Cancelled",
    pagePath: "/sm-auto-allocation-cancelled",
    pagename1: "",
    pagePath1: "",
  };
  const BookingDetail = (bookingId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      BookingID: bookingId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/srm/SRMBookingDetails`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        }
        if (result.status === "Success" && result.status_code === 200) {
          setBookingData(result.data);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };
  const BookingList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      page: page,
      content: 20,
      UserToken: UserToken,
      SearchTerm: searchTerm,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/srm/SRMAutoAllocationCancelledList`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        }
        if (result.status_code == 200 && result.status == "Success") {
          setData(result.data);
          setCount(result.count);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };
  const maxCount = Math.ceil(count / 20);

  return (
    <SmWrapper>
      <Heading
        BreadCrumb={BreadCrumb}
        headingType={"Page Headings"}
        BreadCrumHomeLink={"/sm-dashboard"}
        headingText={"Auto Allocation Cancelled"}
        subheadingText={localStorage.getItem("Location")}
        subheading={
          "Please contact  stock manager for committed date extention"
        }
      />
      <div className={classes.mainContainer}>
        {errorMessage && <ErrorMessage message={errorMessage} />}
        {open && (
          <FollowUpRemarks
            bookingData={bookingData}
            setOpen={setOpen}
            open={open}
          />
        )}

        <div className="mb-4">
          <TableHeader
            buttonText="Search"
            tableHeading="Search Criteria"
            subHeading="subHeading"
            totalResult={count}
            pageResult={data.length}
            placeholderType={
              "For Ex:-(Order ID, Model, Variant, Fuel Type, Colour, Status, ASM, Executive, Customer Name, Customer Number)"
            }
            apicall={BookingList}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
        </div>

        <div className={classes.innerContainer}>
          {loading ? (
            <div className={classes.loader}>
              {" "}
              <img src="assets/loadingGif.gif" alt="loading" />
            </div>
          ) : (
            <>
              <div className={classes.list}>
                <div className={classes.stackListContainer}>
                  <div role="list" className={classes.stackListWrapper}>
                    <StackedList
                      data={data}
                      BookingDetail={BookingDetail}
                      setOpen={setOpen}
                      type="PendingBookings"
                    />
                  </div>
                </div>
              </div>
              {maxCount > 1 ? <Pagination maxPages={maxCount} /> : <></>}
            </>
          )}
        </div>
      </div>
    </SmWrapper>
  );
};

export default AutoAllocationCancelled;
