import React, { useEffect, useState } from "react";
import Heading from "../../tailwindUI/Heading";
import Navbar from "../../tailwindUI/Navbar";
import classes from "../../module.css/StmAllocationStatus.module.css";
import StackedList from "../../tailwindUI/StackedList";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import Pagination from "../../tailwindUI/Pagination";
import DataNotFound from "../../Components/DataNotFound";
import FollowUpRemarks from "../../Components/FollowUpRemarks";
import TableHeader from "../../Components/TableHeader";

const AdminAllocationStatus = () => {
  const getUsersData = JSON.parse(localStorage.getItem("userdata"));

  const stockManagerFilter = getUsersData?.filter(
    (stockManager) => stockManager.UserRole === "StockManager"
  );
  const stockManagerToken = stockManagerFilter[0].UserToken;
  let navigate = useNavigate();
  const location = useLocation();
  const pathname = window.location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const term = queryParams.get("term");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [dataCount, setDataCount] = useState("");
  const [followUpModal, setFollowUpModal] = useState(false);
  const [carDatail, setCarDetail] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;
  const maxPages = Math.ceil(dataCount / 20);

  useEffect(() => {
    if (term) {
      AMAllocationAgingListApi();
    } else AMAllocatedListApi();
  }, [searchTerm === "", page]);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };
  const AMAllocatedListApi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      SearchTerm: searchTerm,
      UserToken: stockManagerToken,
      page: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${global.apiBaseUrl}/api/v1/stockone/am/AMAllocated`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "data found successfully"
        ) {
          if (searchTerm && result.allocation.length < 2) {
            navigate(pathname);
          }
          setData(result.allocation);
          setDataCount(result.datacount);
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "vin is not present"
        ) {
          if (searchTerm) {
            navigate(pathname);
            setDataCount(0);
          }
          setData([]);
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const AMAllocationAgingListApi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: stockManagerToken,
      page: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMAllocationAgingList`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "data found successfully"
        ) {
          // if (searchTerm && result.data.length < 2) {
          //   navigate(pathname);
          // }
          setData(result.data);
          setDataCount(result.dataCount);
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const BreadCrumb = {
    pagename: "Allocation Status",
    pagePath: "/creta-allocation-status",
    pagename1: "",
    pagePath1: "",
  };
  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };

  const followUpHandler = (carDetail) => {
    setFollowUpModal(true);
    setCarDetail(carDetail);
  };

  return (
    <>
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
          apiFailled={apiFailled}
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}

      {followUpModal && (
        <FollowUpRemarks
          bookingData={carDatail}
          setOpen={setFollowUpModal}
          open={followUpModal}
        />
      )}
      <Navbar navbarType={"admin navbar"} />
      <div className={classes.maincontainer}>
        <Heading
          headingType={"Page Headings"}
          headingText={term ? "Allocation Aging" : "Allocation Status"}
          BreadCrumb={BreadCrumb}
          BreadCrumHomeLink={"/creta-dashboard"}
        />
        <div className={classes.background}>
          <TableHeader
            buttonText="Search"
            tableHeading="Search Criteria"
            subHeading="subHeading"
            totalResult={dataCount}
            pageResult={data.length}
            placeholderType="Enter Vin No., Model, Outlet or Variant"
            apicall={AMAllocatedListApi || AMAllocationAgingListApi}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          {loading ? (
            <div className={"w-full bg-white  "}>
              <img
                className="m-auto"
                src="../assets/loadingGif.gif"
                alt="loading"
              />
            </div>
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  {data?.map((item) => {
                    return (
                      <div className={classes.list}>
                        <div className={classes.stackListContainer}>
                          <div role="list" className={classes.stackListWrapper}>
                            <StackedList
                              type="Stm Allocation Status"
                              data={item}
                              followUpHandler={followUpHandler}
                              condition={location.search}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <DataNotFound />
              )}
            </>
          )}

          <div className="bg-white">
            {maxPages > 1 && <Pagination maxPages={maxPages} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAllocationStatus;
