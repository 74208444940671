import React, { useState } from "react";
import classes from "../../module.css/RealTimeStockOne.module.css";
import Heading from "../../tailwindUI/Heading";
import Filter from "../../tailwindUI/Filter";
import Card from "../../tailwindUI/Card";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import DataNotFound from "../../Components/DataNotFound";
import Navbar from "../../tailwindUI/Navbar";

const AsmDashboard = () => {
  const [carListData, setCarListData] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const [carVarientData, setVarientData] = useState([]);
  const [carColorData, setCarColorData] = useState([]);
  const [carFuelData, setCarFuelData] = useState([]);
  const [checkedModel, setCheckedModel] = useState([]);
  const [checkedVarient, setCheckedVarient] = useState([]);
  const [checkedColor, setCheckedColor] = useState([]);
  const [checkedFuel, setCheckedFuel] = useState([]);
  const [loading, setLoading] = useState(false);
  const UserToken = localStorage.getItem("UserToken");

  const navigate = useNavigate();

  useEffect(() => {
    carListApi();
  }, [checkedModel, checkedVarient, checkedColor, checkedFuel]);

  useEffect(() => {
    GetFilterListApi();
    window.scrollTo(0, 0);
  }, [checkedModel]);

  useEffect(() => {
    if (checkedModel.length === 0) {
      setCheckedVarient([]);
      setCheckedColor([]);
      setCheckedFuel([]);
    }
  }, [checkedModel]);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };
  const carListApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      Models: checkedModelforApi.toString(),
      Variant: checkedVarientforApi.toString(),
      Colours: checkedColorforApi.toString(),
      FuelType: checkedFuelforApi.toString(),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/common/RTSModelList`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (
          result.status === "Success" &&
          result.status_code === 200 &&
          result.message === "Data Found Successfully"
        ) {
          setCarListData(result.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
        alert("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const GetFilterListApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Model: checkedModelforApi.toString(),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/common/GetFilterList`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setCarModel(
            result.data.Model?.map((item, index) => {
              return {
                id: `model-${index}`,
                name: item,
              };
            })
          );
          setVarientData(
            result.data.Variant?.map((item, index) => {
              return {
                id: `variant-${index}`,
                name: item,
              };
            })
          );

          setCarColorData(
            result.data.Colour?.map((item, index) => {
              return {
                id: `color-${index}`,
                name: item,
              };
            })
          );
          setCarFuelData(
            result.data.FuelType?.map((item, index) => {
              return {
                id: `fuel-${index}`,
                name: item,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        alert("Something went wrong please contact support !");
      });
  };
  const BreadCrumb = {
    pagename: "Real Time stock",
    pagePath: "/asm-dashboard",
  };
  const sortOptions = [
    { name: "Most Popular", href: "#", current: true },
    { name: "Best Rating", href: "#", current: false },
    { name: "Newest", href: "#", current: false },
  ];
  const modelCheckboxHandler = (option) => {
    const isChecked = checkedModel.some((item) => item.id === option.id);
    if (isChecked) {
      setCheckedModel(checkedModel.filter((item) => item.id !== option.id));
    } else {
      setCheckedModel(checkedModel.concat(option));
    }
  };

  const varientCheckboxHandler = (option) => {
    const isChecked = checkedVarient.some((item) => item.id === option.id);
    if (isChecked) {
      setCheckedVarient(checkedVarient.filter((item) => item.id !== option.id));
    } else {
      setCheckedVarient(checkedVarient.concat(option));
    }
  };

  const colorCheckboxHandler = (option) => {
    const isChecked = checkedColor.some((item) => item.id === option.id);
    if (isChecked) {
      setCheckedColor(checkedColor.filter((item) => item.id !== option.id));
    } else {
      setCheckedColor(checkedColor.concat(option));
    }
  };

  const fuelCheckboxHandler = (option) => {
    const isChecked = checkedFuel.some((item) => item.id === option.id);
    if (isChecked) {
      setCheckedFuel(checkedFuel.filter((item) => item.id !== option.id));
    } else {
      setCheckedFuel(checkedFuel.concat(option));
    }
  };

  const removeModel = (id) => {
    const remove = checkedModel.filter((item) => {
      return item.id !== id;
    });
    setCheckedModel(remove);
  };

  const removeVarient = (id) => {
    const remove = checkedVarient.filter((item) => {
      return item.id !== id;
    });
    setCheckedVarient(remove);
  };

  const removeColor = (id) => {
    const remove = checkedColor.filter((item) => {
      return item.id !== id;
    });
    setCheckedColor(remove);
  };

  const removeFuel = (id) => {
    const remove = checkedFuel.filter((item) => {
      return item.id !== id;
    });
    setCheckedFuel(remove);
  };

  const checkedModelforApi = checkedModel.map((item) => item.name);
  const checkedVarientforApi = checkedVarient.map((item) => item.name);
  const checkedColorforApi = checkedColor.map((item) => item.name);
  const checkedFuelforApi = checkedFuel.map((item) => item.name);
  return (
    <>
      <Navbar navbarType="green navbar" />
      <Heading
        headingType={"Page Headings"}
        headingText={"Dashboard"}
        subheadingText={localStorage.getItem("Location")}
        BreadCrumb={BreadCrumb}
      />

      {loading ? (
        <div className={classes.loader}>
          <img src="../assets/loadingGif.gif" alt="loading" />
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <Filter
            sortOptions={sortOptions}
            carModel={carModel}
            modelCheckboxHandler={modelCheckboxHandler}
            checkedModel={checkedModel}
            carVarientData={carVarientData}
            varientCheckboxHandler={varientCheckboxHandler}
            checkedVarient={checkedVarient}
            carColorData={carColorData}
            colorCheckboxHandler={colorCheckboxHandler}
            checkedColor={checkedColor}
            carFuelData={carFuelData}
            fuelCheckboxHandler={fuelCheckboxHandler}
            checkedFuel={checkedFuel}
            removeModel={removeModel}
            removeVarient={removeVarient}
            removeColor={removeColor}
            removeFuel={removeFuel}
          />
          <div>
            {carListData.length > 0 ? (
              <ul
                role="list"
                className="my-8 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
              >
                {carListData.map((item, index) => (
                  <Card
                    cardType={"carImage"}
                    item={item}
                    key={index}
                    url={"/asm-real-time-stock-one/"}
                  />
                ))}
              </ul>
            ) : (
              <DataNotFound />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AsmDashboard;
