import React, { useEffect, useState } from "react";
import Heading from "../../tailwindUI/Heading";
import Navbar from "../../tailwindUI/Navbar";
import classes from "../../module.css/StmDashboard.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../tailwindUI/Table";
import TableHeader from "../../Components/TableHeader";
import Pagination from "../../tailwindUI/Pagination";
import DataNotFound from "../../Components/DataNotFound";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
const AdminSMAllStock = () => {
  const getUsersData = JSON.parse(localStorage.getItem("userdata"));
  const stockManagerFilter = getUsersData?.filter(
    (stockManager) => stockManager.UserRole === "StockManager"
  );
  const stockManagerToken = stockManagerFilter[0].UserToken;

  let navigate = useNavigate();
  const location = useLocation();
  const pathname = window.location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;

  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [amAllStock, setAmAllStock] = useState([]);
  const [amAllStockData, setAmAllStockData] = useState([]);
  const [amAllStockCount, setAmAllStockCount] = useState();
  const maxPages = Math.ceil(amAllStockCount / 20);

  useEffect(() => {
    AMAllStockDetailAPI();
  }, [searchTerm === "", page]);

  const AMAllStockDetailAPI = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: stockManagerToken,
      page: page,
      SearchTerm: searchTerm,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMAllStockDetail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          if (searchTerm && result.data.length < 2) {
            navigate(pathname);
          }
          setAmAllStock(result.data);
          setAmAllStockData(result.alldata);
          setAmAllStockCount(result.count);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const tablecolumns = [
    {
      name: "Model",
    },
    {
      name: "Fuel",
    },
    {
      name: "Variant",
    },
    {
      name: "Colour",
    },

    {
      name: "VIN No.",
    },
    {
      name: "Type",
    },
    {
      name: "YOM",
    },
    {
      name: "Status",
    },
    {
      name: "Allocation Status",
    },
    {
      name: "Outlet",
    },

    {
      name: "ASM",
    },
    {
      name: "Executive",
    },
    {
      name: "Customer Name",
    },
    {
      name: "Customer No.",
    },
    {
      name: "Allocation Date",
    },
    {
      name: "Truck/Rail No.",
    },
    {
      name: "Driver Name",
    },
    {
      name: "Driver No.",
    },
    {
      name: "Last Location",
    },
    {
      name: "Allocation Aging (Day's)",
    },
  ];
  const BreadCrumb = {
    pagename: "All Stock Detail",
    pagePath: "/creta-all-stock-details",
    pagename1: "",
    pagePath1: "",
  };
  return (
    <>
      <Navbar navbarType={"admin navbar"} />

      <div className="mb-4">
        <Heading
          headingType={"Page Headings"}
          headingText={"All Stock Detail"}
          // subheadingText={localStorage.getItem("Location")}
          BreadCrumb={BreadCrumb}
          BreadCrumHomeLink={"/creta-dashboard"}
        />{" "}
      </div>
      {loading ? (
        <img
          src="../assets/loadingGif.gif"
          alt="loading"
          className={classes.loader}
        />
      ) : (
        <div className={classes.form}>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-bold leading-6 text-gray-900">
                All Stock Detail
              </h1>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <ExcelDownload
                csvData={amAllStockData}
                fileName="All Model Booking Status Report"
                className="block rounded-md bg-zinc-800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
              />
            </div>
          </div>

          <TableHeader
            buttonText="Search"
            tableHeading="Search Criteria"
            subHeading="subHeading"
            totalResult={amAllStockCount}
            pageResult={amAllStock.length}
            placeholderType="Enter Model, Fuel, Variant, Color, YOM, VIN, Status, Allocation Status (For Example:-Grand,i10,Fiery red,Telibagh)"
            apicall={AMAllStockDetailAPI}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />

          {amAllStock.length > 0 ? (
            <Table
              type="stripped rows"
              TableData={amAllStock}
              tableHeading={tablecolumns}
              tabledataType="AMAllStockDetail List"
            />
          ) : (
            <DataNotFound />
          )}

          <div className="bg-white mt-2">
            {maxPages > 1 && <Pagination maxPages={maxPages} />}
          </div>
        </div>
      )}
    </>
  );
};

export default AdminSMAllStock;
