import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SmWrapper from "../../Components/SmWrapper";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import Heading from "../../tailwindUI/Heading";
import Input from "../../tailwindUI/Input";
import Button from "../../tailwindUI/Button";
import classes from "../../module.css/ASM.module.css";
import { useState } from "react";
import { useEffect } from "react";
import ErrorMessage from "../../tailwindUI/ErrorMessage";

const ASMUpdate = () => {
  const navigate = useNavigate();
  const UserToken = localStorage.getItem("UserToken");
  const location = localStorage.getItem("Location");
  const { AsmID } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [asmLocation, setLocation] = useState(location);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    AsmList();
  }, []);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  const AsmList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      content: 200,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${global.apiBaseUrl}/api/v1/stockone/srm/SRMASMList`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        }
        if (result.status_code == 200 && result.status == "Success") {
          if (result.data === "") {
            setPassword("");
            setEmail("");
          } else {
            const getsingleasm = result.data?.filter((item) => {
              return item?.AsmID === AsmID;
            });

            setName(getsingleasm[0]?.AsmName);
            setPassword(getsingleasm[0]?.AsmPassword);
            setEmail(getsingleasm[0]?.AsmEmail);
            setPhone(getsingleasm[0]?.AsmMobile);
            setLocation(getsingleasm[0]?.AsmLocation);
          }
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };

  const UpdateASMpassword = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      ASMId: AsmID,
      Name: name,
      Password: password,
      Email: email,
      Mobile: phone,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/srm/SRMAsmChangePassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (
          result.status_code === 200 &&
          result.message === "Status Updated Successfully"
        ) {
          setMessage("ASM details updated successfully");
        } else if (result.status_code === 200 && result.status === "Failed") {
          setErrorMessage("ASM details not updated");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const emailRegex =
    /^(([^<>()/.,;:\s@"]+(\.[^<>()/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const formValidation = () => {
    let errors = {};
    let isValid = true;

    if (email && !emailRegex.test(email)) {
      isValid = false;
      errors.email = "Please enter valid email address";
    }
    if (!password || password === "") {
      errors.password = "Please enter password";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
    return message;
  };
  const BreadCrumb = {
    pagename: "ASM",
    pagePath: `/asm`,
    pagename1: "Update ASM Password",
    pagePath1: `/asm/${AsmID}`,
  };
  return (
    <SmWrapper>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
        />
      )}

      <Heading
        BreadCrumb={BreadCrumb}
        BreadCrumHomeLink={"/sm-dashboard"}
        headingType={"Page Headings"}
        headingText={"Update ASM Password"}
        subheadingText={location}
      />

      <div className={classes.mainContainer}>
        <div className={classes.innerContainer}>
          <div className={classes.inputContainer}>
            <Input
              type="input with label"
              inputType="text"
              label="Name"
              value={name}
              disabled="disabled"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={classes.inputContainer}>
            <Input
              type="input with label"
              inputType="email"
              label="Email"
              error={error.email}
              onInput={() => (error.email = "")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p className="peer-invalid:visible text-red-500 font-light">
              {error.email}
            </p>
          </div>
          <div className={classes.inputContainer}>
            <Input
              type="input with label"
              inputType="tel"
              label="Mobile number"
              maxLength="10"
              onChange={(e) => setPhone(e.target.value)}
              disabled="disabled"
              value={phone}
            />
          </div>
          <div className={classes.inputContainer}>
            <Input
              type="input with label"
              inputType="password"
              label="Password"
              error={error.password}
              value={password}
              onInput={() => (error.password = "")}
              onChange={(e) => setPassword(e.target.value)}
            />
            <p className="peer-invalid:visible text-red-500 font-light">
              {error.password}
            </p>
          </div>
          <div className={classes.inputContainerDisable}>
            <Input
              type="input with label"
              inputType="text"
              label="Location"
              value={asmLocation}
              dropdownType={"showroom"}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
          <div
            className="mt-8"
            onClick={() => {
              formValidation()
                ? UpdateASMpassword()
                : console.log("Something went wrong");
            }}
          >
            <Button
              type={"redFilled"}
              buttonText={"Submit"}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </SmWrapper>
  );
};

export default ASMUpdate;
