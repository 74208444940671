import { BsDot } from "react-icons/bs";
import Button from "./Button";
import DataNotFound from "../Components/DataNotFound";

import classes from "../module.css/StackedList.module.css";
import { useNavigate } from "react-router-dom";
const dateHandler = (date) => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
  const year = dateObj.getFullYear();
  return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
};
const AdminStatus = (value) => {
  if (value === "Accepted") {
    return <span className="text-green-600">Accepted</span>;
  }
  if (value === "Declined" || value === "Denied") {
    return <span className="text-red-600">Declined</span>;
  }
  if (value === "0") {
    return <span className="text-orange-600">Pending</span>;
  }
};
const dateWithTime = (dateObj1) => {
  if (dateObj1) {
    const dateObj = new Date(dateObj1);
    const day = dateObj?.getDate();
    const monthNameLong = dateObj?.toLocaleString("en-US", { month: "short" });
    const year = dateObj?.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
  } else {
    return <></>;
  }
  // const dateObj = new Date(date);
};
const statusType = (type) => {
  switch (type) {
    case "CarAllocated":
      return "Car Allocated";
    case "OrderRequest":
      return "Order Request";
    case "ReallocationRequest":
      return "Reallocation Request";
    case "BookingRequest":
      return "Booking Request";
    case "AllocatedButVinAwaited":
      return "Allocated But VIN Awaited";
    case "OrderNow":
      return "Order Now ";
    case "DeliveryRequested":
      return "Delivery Requested";
    case "AutoAllocationCancelled":
      return "Auto Allocation Cancelled";
    default:
      return type;
  }
};

const changeStatusColorHandler = (status) => {
  switch (status) {
    case "CarAllocated":
      return "text-green-800 bg-green-100";
    case "Allocation Awaited":
      return "text-blue-800 bg-blue-100";
    case "Allocated":
      return "text-green-800 bg-green-100";
    case "OrderRequest":
      return "text-green-800 bg-green-100";
    case "ReallocationRequest":
      return "text-green-800 bg-green-100";
    case "BookingRequest":
      return "text-green-800 bg-green-100";
    case "AllocatedButVinAwaited":
      return "text-blue-800 bg-blue-100";
    case "OrderNow":
      return "text-green-800 bg-green-100";
    case "DeliveryRequested":
      return "text-green-800 bg-green-100";
    case "AutoAllocationCancelled":
      return "text-red-800 bg-red-100";
    case "Cancelled":
      return "text-red-800 bg-red-100";
    case "Delivered":
      return "text-orange-800 bg-orange-100";
    case "InTransit":
      return "text-green-800 bg-green-100 cursor-pointer";
    case "Physical":
      return "text-orange-800 bg-orange-100";
    case "Pending":
      return "text-red-800 bg-red-100";
    default:
      return;
  }
};

const findPreference = (status) => {
  switch (status) {
    case "allocated":
      return "text-blue-600";
    case "outOfStock":
      return "text-red-600";
    case "inStock":
      return "text-green-600";
    default:
      console.log("preference status not found");
  }
};
const StatusHandler = (value) => {
  if (value === "Declined" || value === "Denied") {
    return (
      <div className="mr-4 text-xs font-bold bg-red-200 text-red-800 p-1 rounded">
        {value}
      </div>
    );
  }
  if (value === "Accepted") {
    return (
      <div className="mr-4 text-xs font-bold bg-green-200 text-green-800 p-1 rounded">
        {value}
      </div>
    );
  } else {
    return (
      <div className="mr-4 text-xs font-bold bg-orange-200 text-orange-800 p-1 rounded">
        {"Pending"}
      </div>
    );
  }
};
const StackedList = (props) => {
  const navigate = useNavigate();
  const preferedCarModel = (
    model,
    variant,
    color,
    allocModel,
    allocVariant,
    allocColor
  ) => {
    return (
      model === allocModel && variant === allocVariant && color === allocColor
    );
  };
  const listType = (type) => {
    switch (type) {
      case "Reallocation Request Recieved":
        return (
          <>
            <ul
              role="list"
              className="divide-y divide-gray-200 hover:bg-gray-50"
            >
              <li>
                <a className="  flex items-center pl-2 ">
                  <div className="font-bold w-4">{props.data.serial_no}</div>
                  <div className="w-full py-4 px-6 font-medium overflow-x-auto">
                    <p className="flex items-center text-sm text-gray-600 ">
                      Booking Ref no : {props.bookingTo.booking_id}
                      <span className="ml-2">
                        Receipt no : {props.bookingTo.receipt_num}
                      </span>
                    </p>

                    <div className="flex items-center justify-between">
                      <p
                        onClick={() =>
                          navigate(
                            "/sm-update-booking/" + props.bookingTo.BookingID
                          )
                        }
                        className="cursor-pointer truncate overflow-scroll flex text-sm font-medium text-indigo-600"
                      >
                        {props.bookingTo.customer_name}{" "}
                        {props.bookingTo.LastName}
                        <span className=" ml-2 mr-2  ">
                          <BsDot size={20} />
                        </span>
                        {props.bookingTo.customer_mobile}
                        <span className=" ml-2 mr-2 ">
                          <BsDot size={20} />
                        </span>
                        <span
                        // className={findPreference(props.item.preference1status)}
                        >
                          {props.bookingTo.CSModel} {props.bookingTo.CSVarient}
                          {props.bookingTo.CSColour}
                        </span>
                      </p>
                      {props.tabType === "Re-Allocation Request Recieved" ? (
                        <div className="ml-2 flex flex-shrink-0 ">
                          <Button
                            buttonText={"Accept"}
                            type={"limeFilled"}
                            onClick={() => props.acceptHandler(props.data)}
                          />
                          <div className="ml-2 mr-2">
                            <Button
                              buttonText={"Decline"}
                              type={"redFilled"}
                              onClick={() => props.declineHandler(props.data)}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="ml-2 flex flex-shrink-0">
                          {/* {props.data.transfer_status === "Declined" ? (
                            <div className="mr-4 text-xs font-bold bg-red-200 text-red-800 p-1 rounded">
                              {props.data.transfer_status==="0"?"Pending":props.data.transfer_status}
                            </div>
                          ) : (
                            <div className="mr-4 text-xs font-bold bg-green-200 text-green-800 p-1 rounded">
                               {props.data.transfer_status==="0"?"Pending":props.data.transfer_status}
                            </div>
                          )} */}
                          {StatusHandler(props.data.transfer_status)}
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <div className={classes.pendingdetailAllocated}>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          {props.bookingTo.AsmName}
                        </p>
                        <span className="ml-1 mr-1  text-xs	">|</span>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          {props.bookingTo.ExecutiveName}
                        </p>
                        <span className="ml-1 mr-1  text-xs	">|</span>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Booking : Rs.{props.bookingTo.booking_amount}
                        </p>
                        <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                        <p className=" flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Booking Date :
                          {dateHandler(props.bookingTo.booking_date)}
                        </p>

                        <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                        <p className=" flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Committed Date :
                          {dateHandler(props.bookingTo.CommittedDeliveryDate)}
                        </p>
                        {props.bookingTo.AmEdd && (
                          <>
                            <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                            <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                              Stock Manager EDD :
                              {dateHandler(props.bookingTo.AmEdd)}
                            </p>
                          </>
                        )}
                      </div>
                      <div className="flex items-center text-[13px] text-indigo-500 mt-0 italic mr-4">
                        <p
                          className="cursor-pointer mt-2.5 "
                          onClick={() => {
                            props.setOpen(true);
                            props.BookingDetail(props.bookingTo.BookingID);
                          }}
                        >
                          View Remarks
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                <div className="text-sm font-bold pl-12 mb-1">
                  Request
                  {props.tabType === "Re-Allocation Request Sent" ||
                    props.tabType === "Re-Allocation Sent History"
                    ? " To"
                    : " From"}
                </div>
                <a className="  flex items-center pl-6 ">
                  <div className="w-full  px-6  font-medium overflow-x-auto">
                    <p className="flex items-center text-sm text-gray-600 mb-2 ">
                      Booking Ref no : {props.bookingFrom.booking_id}
                      <span className="ml-2">
                        Receipt no : {props.bookingFrom.receipt_num}
                      </span>
                      <span className="ml-2">
                        Location : {props.bookingFrom.Location}
                      </span>
                      <span className="ml-2">
                        Stock Manger Status :
                        {AdminStatus(props.data.accept_admin)}
                      </span>
                    </p>

                    <div className="flex items-center justify-between">
                      <p className="truncate overflow-scroll flex text-sm font-medium text-indigo-600">
                        <span
                        // className={findPreference(props.item.preference1status)}
                        >
                          {props.data.model_name} {props.data.variant}
                          {props.data.colour}
                        </span>
                      </p>
                    </div>
                    <div className="mb-3 mt-2 flex justify-between">
                      <div className={classes.pendingdetailAllocated}>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          {props.bookingFrom.AsmName}
                        </p>
                        <span className="ml-3 mr-3  text-xs	">|</span>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          {props.bookingFrom.ExecutiveName}
                        </p>
                        <span className="ml-3 mr-3  text-xs	">|</span>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Booking : Rs.{props.bookingFrom.booking_amount}
                        </p>
                        <span className="ml-3 mr-3 mt-0 text-xs	">|</span>
                        <p className=" flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Booking Date :
                          {dateHandler(props.bookingFrom.booking_date)}
                        </p>

                        <span className="ml-2 mr-2 mt-0 text-xs	">|</span>
                        <p className=" flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Committed Date :
                          {dateHandler(props.bookingFrom.CommittedDeliveryDate)}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </>
        );
      case "Reallocation Request Sent":
        return (
          <>
            <ul
              role="list"
              className="divide-y divide-gray-200 hover:bg-gray-50"
            >
              <li>
                <a className="  flex items-center pl-2 ">
                  <div className="font-bold w-4">{props.data.serial_no}</div>
                  <div className="w-full py-4 px-6 font-medium overflow-x-auto">
                    <p className="flex items-center text-sm text-gray-600 ">
                      Booking Ref no : {props.bookingFrom.booking_id}
                      <span className="ml-2">
                        Receipt no : {props.bookingFrom.receipt_num}
                      </span>
                    </p>
                    <div className="flex items-center justify-between">
                      <p
                        onClick={() =>
                          navigate(
                            "/sm-update-booking/" + props.bookingFrom.BookingID
                          )
                        }
                        className="cursor-pointer truncate overflow-scroll flex text-sm font-medium text-indigo-600"
                      >
                        {props.bookingFrom.customer_name}{" "}
                        {props.bookingFrom.LastName}
                        <span className=" ml-2 mr-2  ">
                          <BsDot size={20} />
                        </span>
                        {props.bookingFrom.customer_mobile}
                        <span className=" ml-2 mr-2 ">
                          <BsDot size={20} />
                        </span>
                        <span
                        // className={findPreference(props.item.preference1status)}
                        >
                          {props.data.model_name} {props.data.variant}
                          {props.data.colour}
                        </span>
                      </p>
                      {props.tabType === "Re-Allocation Request Recieved" ? (
                        <div className="ml-2 flex flex-shrink-0 ">
                          <Button
                            buttonText={"Accept"}
                            type={"limeFilled"}
                            onClick={() => props.setAccept(true)}
                          />
                          <div className="ml-2 mr-2">
                            <Button
                              buttonText={"Decline"}
                              type={"redFilled"}
                              onClick={() => props.setCancel(true)}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="ml-2 flex flex-shrink-0">
                          {StatusHandler(props.data.transfer_status)}
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <div className={classes.pendingdetailAllocated}>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          {props.bookingFrom.AsmName}
                        </p>
                        <span className="ml-1 mr-1 text-xs	">|</span>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          {props.bookingFrom.ExecutiveName}
                        </p>
                        <span className="ml-1 mr-1  text-xs	">|</span>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Booking : Rs.{props.bookingFrom.booking_amount}
                        </p>
                        <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                        <p className=" flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Booking Date :
                          {dateHandler(props.bookingFrom.booking_date)}
                        </p>

                        <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                        <p className=" flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Committed Date :
                          {dateHandler(props.bookingFrom.CommittedDeliveryDate)}
                        </p>
                        {props.bookingFrom.AmEdd && (
                          <>
                            <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                            <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                              Stock Manager EDD :
                              {dateHandler(props.bookingFrom.AmEdd)}
                            </p>
                          </>
                        )}
                      </div>
                      <div className="flex items-center text-xs text-indigo-500 mt-0 italic mr-4">
                        <p
                          className="cursor-pointer mt-2.5 "
                          onClick={() => {
                            props.setOpen(true);
                            props.BookingDetail(props.bookingFrom.BookingID);
                          }}
                        >
                          View Remarks
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                <div className="text-sm font-bold pl-12 mb-1">
                  Request
                  {props.tabType === "Re-Allocation Request Sent" ||
                    props.tabType === "Re-Allocation Sent History"
                    ? " To"
                    : " From"}
                </div>
                <a className="  flex items-center pl-6 ">
                  <div className="w-full  px-6  font-medium overflow-x-auto">
                    <p className="flex items-center text-sm text-gray-600 mb-2 ">
                      Booking Ref no : {props.bookingTo.booking_id}
                      <span className="ml-2">
                        Receipt no : {props.bookingTo.receipt_num}
                      </span>
                      <span className="ml-2  text-xs	">|</span>
                      <span className="ml-2">
                        Location : {props.bookingTo.Location}
                      </span>
                      <span className="ml-2  text-xs	">|</span>
                      <span className="ml-1">
                        Stock Manger Status :
                        {AdminStatus(props.data.accept_admin)}
                      </span>
                    </p>

                    <div className="flex items-center justify-between">
                      <p className="truncate overflow-scroll flex text-sm font-medium text-indigo-600">
                        <span
                        // className={findPreference(props.item.preference1status)}
                        >
                          {props.bookingTo.CSModel}
                          {props.bookingTo.CSVarient}
                          {props.bookingTo.CSColour}
                        </span>
                      </p>
                    </div>
                    <div className="mb-3 mt-2 flex justify-between">
                      <div className={classes.pendingdetailAllocated}>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          {props.bookingTo.AsmName}
                        </p>
                        <span className="ml-1 mr-1  text-xs	">|</span>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          {props.bookingTo.ExecutiveName}
                        </p>
                        <span className="ml-1 mr-1  text-xs	">|</span>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Booking : Rs.{props.bookingTo.booking_amount}
                        </p>
                        <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                        <p className=" flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Booking Date :
                          {dateHandler(props.bookingTo.booking_date)}
                        </p>

                        <span className="ml-2 mr-2 mt-0 text-xs	">|</span>
                        <p className=" flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Committed Date :
                          {dateHandler(props.bookingTo.CommittedDeliveryDate)}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </>
        );
      case "PendingBookings":
        return (
          <div>
            <ul role="list" className="divide-y divide-gray-200">
              {props.data?.length ? (
                props.data.map((item) => (
                  <li key={item.id}>
                    <div className=" w-full flex items-center justify-between  py-2 pl-4">
                      <div flex items-center justify-between>
                        {item.SrNo}
                      </div>
                      <div className="px-4 min-w-[1100px] w-full  py-4 sm:px-6">
                        <div className="flex mb-1 items-center justify-between">
                          <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mb-1 sm:mr-6">
                            Booking Ref no : {item.booking_id}
                            <span className="ml-6">
                              Receipt no : {item.receipt_num}
                            </span>
                            {item.delivered_on ? (
                              <span className="ml-6">
                                Delivery Date : {dateHandler(item.delivered_on)}
                              </span>
                            ) : (
                              <></>
                            )}
                            <span className="ml-6">
                              Booking Amount : Rs.{item.booking_amount}
                            </span>
                          </p>
                          <div className="ml-2 flex flex-shrink-0">
                            <p
                              className={`${changeStatusColorHandler(
                                item.status
                              )} inline-flex rounded-full  px-2 text-xs font-semibold leading-5 `}
                            >
                              {statusType(item.status)}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <p
                            className={
                              "cursor-pointer truncate overflow-scroll flex text-sm font-medium text-indigo-600"
                            }
                            onClick={() =>
                              navigate("/sm-update-booking/" + item.BookingID)
                            }
                          >
                            {item.customer_name} {item.LastName}
                            <span className="ml-2 mr-2 ">
                              <BsDot size={20} />
                            </span>
                            {item.customer_mobile}
                            <span className="ml-2 mr-2">
                              <BsDot size={20} />
                            </span>
                            {props.allocated ? (
                              <span
                                className={"text-green-500"}
                              >{`1. ${item?.CSModel} ${item?.CSVarient} ${item?.CSColour}`}</span>
                            ) : (
                              <>
                                {item?.car_model && (
                                  <span
                                    className={
                                      preferedCarModel(
                                        item?.car_model,
                                        item?.car_variant,
                                        item?.car_colour,
                                        item?.CSModel,
                                        item?.CSVarient,
                                        item?.CSColour
                                      ) && "text-green-500"
                                    }
                                  >{`1. ${item?.car_model} ${item?.car_variant} ${item?.car_colour}`}</span>
                                )}
                                {item?.CarModel2 && (
                                  <span
                                    className={`${preferedCarModel(
                                      item?.CarModel2,
                                      item?.CarVariant2,
                                      item?.CarColour2,
                                      item?.CSModel,
                                      item?.CSVarient,
                                      item?.CSColour
                                    ) && "text-green-500"
                                      } ml-4`}
                                  >{`2. ${item?.CarModel2} ${item?.CarVariant2} ${item?.CarColour2}`}</span>
                                )}
                              </>
                            )}
                          </p>
                          {/* <div className="ml-2 flex flex-shrink-0">
                            <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {statusType(item.status)}
                            </p>
                          </div> */}
                          <div className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                            <p className="text-sm">{item.win_num}</p>
                          </div>
                        </div>
                        <div className="mt-2 flex items-center justify-between">
                          <div
                            className={"truncate overflow-scroll flex text-sm "}
                          >
                            <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                              {item.AsmName}
                            </p>
                            <span className="ml-2 mr-2 mt-0.5 text-xs	">|</span>
                            <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                              {item.ExecutiveName}
                            </p>
                            {/* <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                            <div className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                              <p className="text-sm">
                                Booking Amount: Rs.{item.booking_amount}
                              </p>
                            </div> */}

                            <span className="ml-2 mr-2 mt-0.5 text-xs	">|</span>

                            <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                              Booking Date : {dateHandler(item.booking_date)}
                            </p>
                            <span className="ml-2 mr-2 mt-0.5 text-xs	">|</span>
                            <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                              Committed Date :
                              {dateHandler(item.CommittedDeliveryDate)}
                            </p>
                            {item?.AmEdd && (
                              <>
                                <span className="ml-2 mr-2 mt-0.5 text-xs	">
                                  |
                                </span>
                                <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                                  Stock Manager EDD : {dateHandler(item.AmEdd)}
                                </p>
                              </>
                            )}
                          </div>
                          <div
                            onClick={() => {
                              props.setOpen(true);
                              props.BookingDetail(item.BookingID);
                            }}
                            className=" text-xs cursor-pointer text-indigo-500 mt-1  "
                          >
                            View Remarks
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <DataNotFound text={"Booking not found"} />
              )}
            </ul>
          </div>
        );

      case "SM Allocated Bookings":
        return (
          <div>
            <ul role="list" className="divide-y divide-gray-200">
              {props.data?.length ? (
                props.data.map((item) => (
                  <li key={item.id}>
                    <div className=" w-full flex items-center justify-between  py-2 pl-4">
                      <div flex items-center justify-between>
                        {item.SrNo}
                      </div>
                      <div className="px-4 min-w-[1100px] w-full  py-4 sm:px-6">
                        <div className="flex mb-1 items-center justify-between">
                          <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mb-1 sm:mr-6">
                            Booking Ref no : {item.booking_id}
                            <span className="ml-6">
                              Receipt no : {item.receipt_num}
                            </span>
                            {item.delivered_on ? (
                              <span className="ml-6">
                                Delivery Date : {dateHandler(item.delivered_on)}
                              </span>
                            ) : (
                              <></>
                            )}
                            <span className="ml-6">
                              Booking Amount : Rs.{item.booking_amount}
                            </span>
                          </p>

                          <div className="ml-2 flex flex-shrink-0">
                       { item.availabilitystatus==="BBND" &&  <p
                              className="text-pink-800 bg-pink-100 inline-flex rounded-full  px-2 text-xs font-semibold leading-5"
                            >
                              {item.availabilitystatus}
                            </p>}
                            <div
                              title={
                                item.carstatus === "InTransit"
                                  ? "Click to view transit info"
                                  : ""
                              }
                              className={`${changeStatusColorHandler(
                                item.carstatus
                              )} inline-flex rounded-full  px-2 text-xs font-semibold leading-5 mx-2`}
                              onClick={() => {
                                props.setOpenCarStatus(
                                  item.carstatus === "InTransit" ? true : false
                                );
                                props.getInTransitInData(item);
                              }}
                            >
                              {item.carstatus}
                            </div>
                            <p
                              className={`${changeStatusColorHandler(
                                item.status
                              )} inline-flex rounded-full  px-2 text-xs font-semibold leading-5 `}
                            >
                              {statusType(item.status)}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-center justify-between">
                          <p
                            className={
                              "cursor-pointer truncate overflow-scroll flex text-sm font-medium text-indigo-600"
                            }
                            onClick={() =>
                              navigate("/sm-update-booking/" + item.BookingID)
                            }
                          >
                            {item.customer_name} {item.LastName}
                            <span className="ml-2 mr-2 ">
                              <BsDot size={20} />
                            </span>
                            {item.customer_mobile}
                            <span className="ml-2 mr-2">
                              <BsDot size={20} />
                            </span>
                            {props.allocated ? (
                              <span
                                className={"text-green-500"}
                              >{`1. ${item?.car_model} ${item?.car_variant} ${item?.car_colour}`}</span>
                            ) : (
                              <>
                                {item?.car_model && (
                                  <span
                                    className={
                                      preferedCarModel(
                                        item?.car_model,
                                        item?.car_variant,
                                        item?.car_colour,
                                        item?.CSModel,
                                        item?.CSVarient,
                                        item?.CSColour
                                      ) && "text-green-500"
                                    }
                                  >{`1. ${item?.car_model} ${item?.car_variant} ${item?.car_colour}`}</span>
                                )}
                                {item?.CarModel2 && (
                                  <span
                                    className={`${preferedCarModel(
                                      item?.CarModel2,
                                      item?.CarVariant2,
                                      item?.CarColour2,
                                      item?.CSModel,
                                      item?.CSVarient,
                                      item?.CSColour
                                    ) && "text-green-500"
                                      } ml-4`}
                                  >{`2. ${item?.CarModel2} ${item?.CarVariant2} ${item?.CarColour2}`}</span>
                                )}
                              </>
                            )}
                          </p>
                          {/* <div className="ml-2 flex flex-shrink-0">
                              <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                {statusType(item.status)}
                              </p>
                            </div> */}
                          <div className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                            <p className="text-sm">{item.win_num}</p>
                          </div>
                        </div>

                        <div className="mt-2 flex items-center justify-between">
                          <div
                            className={"truncate overflow-scroll flex text-sm "}
                          >
                            <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                              {item.AsmName}
                            </p>
                            <span className="ml-2 mr-2 mt-0.5 text-xs	">|</span>
                            <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                              {item.ExecutiveName}
                            </p>
                            {/* <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                              <div className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                                <p className="text-sm">
                                  Booking Amount: Rs.{item.booking_amount}
                                </p>
                              </div> */}

                            <span className="ml-2 mr-2 mt-0.5 text-xs	">|</span>

                            <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                              Booking Date : {dateHandler(item.booking_date)}
                            </p>
                            <span className="ml-2 mr-2 mt-0.5 text-xs	">|</span>
                            <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                              Committed Date :
                              {dateHandler(item.CommittedDeliveryDate)}
                            </p>
                            {item?.AmEdd && (
                              <>
                                <span className="ml-2 mr-2 mt-0.5 text-xs	">
                                  |
                                </span>
                                <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                                  Stock Manager EDD : {dateHandler(item.AmEdd)}
                                </p>
                              </>
                            )}
                          </div>
                          <div
                            onClick={() => {
                              props.setOpen(true);
                              props.BookingDetail(item.BookingID);
                            }}
                            className=" text-xs cursor-pointer text-indigo-500 mt-1  "
                          >
                            View Remarks
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <DataNotFound text={"Booking not found"} />
              )}
            </ul>
          </div>
        );
      case "update booking":
        return (
          <div>
            <div
              className={"  hover:bg-gray-50 flex items-center pl-4 "}
            >
              <div flex items-center justify-between>
                {props.item.SrNo}
              </div>
              <div className="px-4 w-full py-2 sm:px-6">
                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mb-1 sm:mr-6">
                  Booking Ref no : {props.item.booking_id}
                  <span className="ml-2">
                    Receipt no : {props.item.receipt_num}
                  </span>
                  <span className="ml-2">
                    Created on :{" "}
                    {dateHandler(props.item.CreatedAt.slice(0, 10))}
                  </span>
                  {props.item.win_num && (
                    <span className="ml-2">VIN no : {props.item.win_num}</span>
                  )}
                  {props.item.NameNumberChangeStatus === 1 && (
                    <div className="ml-4 bg-orange-100 py-1 px-1 text-black text-xs rounded">
                      Modified
                    </div>
                  )}
                  {props.item.CustomerSpecialNeed &&
                    props.item.CustomerSpecialNeed.split(",").map((item) => {
                      return (
                        <>
                          {item && (
                            <span className="ml-4 bg-amber-100 text-amber-800 p-1 rounded text-xs">
                              {item}
                            </span>
                          )}
                        </>
                      );
                    })}
                  {props.item.exchange === "No" ||
                    props.item.exchange === "no" ? (
                    ""
                  ) : (
                    <span className="ml-4 bg-indigo-100 text-indigo-800 p-1 rounded text-xs">
                      {props.item.exchange === "Yes" ||
                        props.item.exchange === "yes"
                        ? "Exchange"
                        : ""}
                    </span>
                  )}
                </p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center justify-between truncate">
                    <p
                      className={
                        "truncate overflow-scroll flex text-sm font-medium text-indigo-600"
                      }
                    >
                      {props.item.customer_name
                        ? `${props.item.customer_name} ${props.item.LastName}`
                        : "N/A"}
                      <span className="ml-2 mr-2 ">
                        <BsDot size={20} />
                      </span>
                      {props.item.customer_mobile}
                      <span className="ml-2 mr-2">
                        <BsDot size={20} />
                      </span>

                      {props.item?.car_model && (
                        <span
                          className={
                            preferedCarModel(
                              props.item?.car_model,
                              props.item?.car_variant,
                              props.item?.car_colour,
                              props.item?.CSModel,
                              props.item?.CSVarient,
                              props.item?.CSColour
                            ) && "text-green-500"
                          }
                        >{`1. ${props.item?.car_model} ${props.item?.car_variant} ${props.item?.car_colour}`}</span>
                      )}

                      {props.item?.CarModel2 && (
                        <span
                          className={`${preferedCarModel(
                            props.item?.CarModel2,
                            props.item?.CarVariant2,
                            props.item?.CarColour2,
                            props.item?.CSModel,
                            props.item?.CSVarient,
                            props.item?.CSColour
                          ) && "text-green-500"
                            } ml-4`}
                        >{`2. ${props.item?.CarModel2} ${props.item?.CarVariant2} ${props.item?.CarColour2}`}</span>
                      )}
                    </p>
                  </div>
                  <div className="ml-2 flex flex-shrink-0 items-center">
                    <p
                      className={`${changeStatusColorHandler(
                        props.item.status
                      )} inline-flex rounded-full  px-2 text-xs font-semibold leading-5`}
                    >
                      {statusType(props.item.status)
                        ? statusType(props.item.status)
                        : "N/A"}
                    </p>
                  { props.ListType!=="nobutton"&& <div
                      onClick={props.onClick}
                      className={`${props.item.status === "Cancelled"
                        ? "cursor-default"
                        : "bg-button   cursor-pointer s"
                        }   text-xs p-1 text-white ml-2 rounded-lg font-bold`}
                    >
                      Open Booking
                    </div>}
                  </div>
                </div>
                <div className="mt-2 sm:flex  sm:justify-between">
                  <div className={"truncate overflow-scroll flex text-sm "}>
                    <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                      {props.item.AsmName ? props.item.AsmName : "N/A"}
                    </p>
                    <span className="ml-4 mr-4 mt-0.5 text-xs	">|</span>
                    <p className=" flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                      {props.item.ExecutiveName
                        ? props.item.ExecutiveName
                        : "N/A"}
                    </p>
                    <span className="ml-4 mr-4 mt-0.5 text-xs	">|</span>
                    <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                      Booking Date :
                      {props.item.booking_date
                        ? dateHandler(props.item.booking_date)
                        : "N/A"}
                    </p>
                    <span className="ml-4 mr-4 mt-0.5 text-xs	">|</span>
                    <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                      Committed Date :
                      {props.item.CommittedDeliveryDate
                        ? dateHandler(props.item.CommittedDeliveryDate)
                        : "N/A"}
                    </p>
                    {props.item?.AmEdd && (
                      <>
                        <span className="ml-4 mr-4 mt-0.5 text-xs	">|</span>
                        <p className="flex items-center text-sm text-black-500 sm:mt-0 sm:mr-0">
                          Stock Manager EDD : {dateHandler(props.item.AmEdd)}
                        </p>
                      </>
                    )}
                  </div>


                  <div className="mt-2 flex  items-center  text-sm text-gray-500 sm:mt-0">
                    <p className="text-sm">
                      {props.item.delivered_on
                        ? `  Delivery Date :
                        ${dateWithTime(props.item.delivered_on)}`
                        : "Not delivered"}
                    </p>
                    <div
                      onClick={() => {
                        props.setOpen(true);
                        props.BookingDetail(props.item.BookingID);
                      }}
                      className=" text-xs  cursor-pointer px-2 text-indigo-500   "
                    >
                      View Remarks
                    </div>

                  </div>

                </div>
              </div>
            </div>


          </div>
        );
      case "modBookingList":
        return (
          <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {props.data?.length !== 0 ? (
                props.data?.map((item, index) => (
                  // <li key={item.id} onClick={props.modal(item.car_id)}>
                  <li key={item.id} onClick={() => { }}>
                    <div className="hover:bg-gray-50 flex items-center pl-4">
                      <div className="flex items-center justify-between">
                        {index + 1}
                      </div>
                      <div className="flex flex-col lg:flex-row px-4 w-full py-4 sm:px-6">
                        <div className="w-full lg:w-3/4 flex flex-col overflow-x-auto">
                          <div className="mt-1 flex text-gray-500 text-sm">
                            {item.booking_id ? (
                              <span className="text-sm whitespace-nowrap font-medium">
                                Booking Ref No :{item.booking_id}
                              </span>
                            ) : (
                              ""
                            )}
                            {item.receipt_num ? (
                              <span className="ml-4 text-sm whitespace-nowrap font-medium">
                                Receipt No :{item.receipt_num}
                              </span>
                            ) : (
                              ""
                            )}

                            <span className="ml-4 text-sm whitespace-nowrap font-medium">
                              Gate Pass No :
                              {/* {item.GetPassNumber ? item.GetPassNumber : "N/A"} */}
                              {item.SuspiciousActivity === "1" ? (
                                <>
                                  {item.GetPassNumber}

                                  <span className=" text-xs whitespace-nowrap text-red-600 bg-red-100 p-1 rounded ml-4">
                                    Reported
                                  </span>
                                </>
                              ) : (
                                item.GetPassNumber
                              )}
                            </span>
                          </div>

                          <div className="flex mt-1 font-medium text-indigo-600">
                            <span className="whitespace-nowrap text-md">
                              {item.customer_name} {item.LastName}
                            </span>

                            {/* <span className="text-indigo-600 mx-4 xl:mx-4 font-bold"></span> */}
                            <span className="ml-1 mr-2 mt-1">
                              <BsDot size={20} />
                            </span>
                            <span className="whitespace-nowrap text-md">
                              {item.win_num ? item.win_num : "N/A"}
                            </span>

                            {/* <span className="text-indigo-600 mx-4 xl:mx-4 font-bold">.</span> */}
                            <span className="ml-1 mr-2 mt-1">
                              <BsDot size={20} />
                            </span>
                            <span className="whitespace-nowrap pr-2 text-md ">
                              1
                            </span>
                            <span className="mt-1">
                              <BsDot size={20} />
                            </span>
                            <span className="whitespace-nowrap pr-2 text-md">
                              {item.car_model}
                            </span>
                            <span className="whitespace-nowrap pr-2 text-md">
                              {item.car_variant}
                            </span>
                            <span className="whitespace-nowrap text-md">
                              {item.car_colour}
                            </span>
                          </div>

                          <div className="flex mt-1 items-center">
                            <span className="text-sm whitespace-nowrap font-medium">
                              {item.Location}
                            </span>
                            <span className="xl:text-base text-sm xl:font-normal font-semibold mx-4">
                              |
                            </span>
                            <span className="text-sm whitespace-nowrap font-medium">
                              {item.AsmName}
                            </span>

                            <span className="xl:text-base text-sm xl:font-normal font-semibold mx-4">
                              |
                            </span>

                            <span className="text-sm whitespace-nowrap font-medium">
                              {item.ExecutiveName}
                            </span>

                            <span className="xl:text-base text-sm xl:font-normal font-semibold mx-4">
                              |
                            </span>
                            {/* {item.etaDate && (<><span className="text-sm whitespace-nowrap font-medium">
                              Estimated Date :
                              {dateHandler(item.etaDate)}
                            </span>
                              <span className="xl:text-base text-sm xl:font-normal font-semibold mx-4">
                                |
                              </span></>)
                            } */}

                            {item.booking_date && (
                              <span className="text-sm whitespace-nowrap font-medium">
                                Booking Date :{dateHandler(item.booking_date)}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="w-full lg:w-80 flex flex-col items-start lg:items-center justify-between mt-2 lg:mt-0">
                          <div
                            // className={`${props.tabNum === 1 || props.tabNum === 2
                            className={`flex w-full`}
                          >
                            <span
                              className={`${item.delivered_status === "Cancelled" ||
                                item.delivered_status === "Delivered"
                                ? "hidden"
                                : ""
                                } mr-3`}
                            >
                              <Button
                                type="whiteButton"
                                buttonText="Cancel"
                                onClick={() => {
                                  // props.cancelDelivery(
                                  //   item.car_id,
                                  //   item.BookingID
                                  // );
                                  props.modalState(
                                    "CANCEL",
                                    item.BookingID,
                                    item.car_id
                                  );
                                }}
                              />
                            </span>
                            <span
                              className={`${item.delivered_status === "Cancelled" ||
                                item.delivered_status === "Delivered"
                                ? "hidden"
                                : ""
                                } mr-3`}
                            >
                              <Button
                                type="greenFilled"
                                buttonText="Confirm"
                                onClick={() => {
                                  // props.confirmDelivery(
                                  //   item.car_id,
                                  //   item.BookingID
                                  // );
                                  props.modalState(
                                    "CONFIRM",
                                    item.BookingID,
                                    item.car_id,
                                    item.delivered_on.split("T")[0]
                                  );
                                }}
                              />
                            </span>

                            <span
                              className={`${item.delivered_status === "Cancelled" ||
                                item.delivered_status === "Delivered" ||
                                item.SuspiciousActivity
                                ? " hidden"
                                : ""
                                }`}
                            >
                              <Button
                                type="redFilled"
                                buttonText="Report"
                                onClick={() => {
                                  props.modalState("REPORT", item.BookingID);

                                  // props.reportSus(item.BookingID);
                                }}
                              />
                            </span>
                          </div>
                          {item.delivered_on && (
                            <span className="text-sm mt-2 lg:mt-0 font-medium">
                              Delivery Date :{dateHandler(item.delivered_on)}
                            </span>
                          )}
                          <div className="text-[13px] text-indigo-500 mt-0 italic mr-0">
                            <p
                              className="cursor-pointer"
                              onClick={() => { props.setOpenRemark(true); props.getremark(item) }}
                            >
                              View Remarks
                            </p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </li>
                ))
              ) : (
                // image for data not found
                <DataNotFound />
              )}
            </ul>
          </div>
        );
      case "AllocationAwaited":
        return (
          <ul role="list" className="divide-y divide-gray-200">
            <li>
              <a className=" hover:bg-gray-50 flex items-center pl-4 ">
                <div flex items-center justify-between className="font-bold">
                  {props.item.SrNo}
                </div>
                <div className="w-full py-4 px-6 font-medium overflow-x-auto">
                  <p className="flex items-center text-sm text-gray-600 ">
                    Booking Ref no : {props.item.booking_id}
                    <span className="ml-2">
                      Receipt no : {props.item.receipt_num}
                    </span>
                    {props.item.delivered_on && (
                      <span className="ml-2">
                        Delivered On : {dateHandler(props.item.delivered_on)}
                      </span>
                    )}
                    <span className="ml-2">
                      Created On :{" "}
                      {dateHandler(props.item.CreatedAt.slice(0, 10))}
                    </span>
                    {props.item.OwnerRef ? (
                      <span className="ml-4 bg-rose-100 text-rose-800 p-1 rounded text-xs">
                        {props.item.OwnerRef}
                      </span>
                    ) : (
                      ""
                    )}
                    {props.item.exchange === "No" ||
                      props.item.exchange === "no" ? (
                      ""
                    ) : (
                      <span className="ml-4 bg-indigo-100 text-indigo-800 p-1 rounded text-xs">
                        {props.item.exchange === "Yes" ||
                          props.item.exchange === "yes"
                          ? "Exchange"
                          : ""}
                      </span>
                    )}
                    {props.item.CustomerSpecialNeed &&
                      props.item.CustomerSpecialNeed.split(",").map((item) => {
                        return (
                          <span className="ml-4 bg-amber-100 text-amber-800 p-1 rounded text-xs">
                            {item ? item : "Booking Priority : N/A"}
                          </span>
                        );
                      })}
                  </p>

                  <div className="flex items-center justify-between ">
                    <p className={classes.overflowAllocationAllocation}>
                      {`${props.item.customer_name} ${props.item.LastName}`}
                      <span className="  mt-2 ">
                        <BsDot size={20} />
                      </span>
                      {props.item.customer_mobile}
                      <span className=" mt-2">
                        <BsDot size={20} />
                      </span>
                      <span
                        className={findPreference(props.item.preference1status)}
                      >
                        {`1.${props.item.car_model}  ${props.item.car_variant} ${props.item.car_colour}`}
                      </span>
                      {props.item.CarModel2 ? (
                        <>
                          <span className=" mt-2">
                            <BsDot size={20} />
                          </span>
                          <span
                            className={findPreference(
                              props.item.preference2status
                            )}
                          >
                            {`2.${props.item.CarModel2}  ${props.item.CarVariant2} ${props.item.CarColour2}`}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </p>
                    {props.CBT === "CBT" ? <></> : <div className="ml-2 flex flex-shrink-0">
                      <Button
                        buttonText={"Allocate Now"}
                        type={"limeFilled"}
                        onClick={() => {
                          props.carDetailHandler(props.item);
                        }}
                      />
                    </div>}
                  </div>
                  <div className=" flex justify-between">
                    <div className={classes.pendingdetailAllocated}>
                      <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                        {props.item.Location}
                      </p>
                      <span className="ml-2 mr-2  text-xs	">|</span>
                      <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                        {props.item.AsmName}
                      </p>
                      <span className="ml-2 mr-2  text-xs	">|</span>
                      <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                        {props.item.ExecutiveName}
                      </p>
                      <span className="ml-2 mr-2  text-xs	">|</span>
                      <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                        Booking : Rs.{props.item.booking_amount}
                      </p>
                      <span className="ml-2 mr-2 ext-xs	">|</span>
                      <p className=" flex items-center text-sm text-gray-600 mt-0 mr-0">
                        Booking Date : {dateHandler(props.item.booking_date)}
                      </p>

                      <span className="ml-2 mr-2 ext-xs	">|</span>
                      <p className=" flex items-center text-sm text-gray-600 mt-0 mr-0">
                        Committed Date :
                        {dateHandler(props.item.CommittedDeliveryDate)}
                      </p>
                    </div>
                    <div className=" items-center text-xs text-indigo-500 mt-0 italic mr-4">
                      <div
                        className="cursor-pointer flex justify-end py-1"
                        onClick={() => props.followUpHandler(props.item)}
                      >
                        View Remarks
                      </div>

                      <div
                        className={`cursor-pointer ${props.item.StockManagerRemark && "text-red-600  "}`}
                        onClick={() => { props.carBookingHandler(props.item); props.setCustomerRemark(props.item.StockManagerRemark) }}
                      >
                        {props.item.StockManagerRemark ? "Update AM Remark" : "Add AM Remark"}

                      </div>


                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        );

        case "CBTAllocationAwaited":
          return (
            <ul role="list" className="divide-y divide-gray-200">
              <li>
                <a className=" hover:bg-gray-50 flex items-center pl-4 ">
                  <div flex items-center justify-between className="font-bold">
                    {props.item.SrNo}
                  </div>
                  <div className="w-full py-4 px-6 font-medium overflow-x-auto">
                    <p className="flex items-center text-sm text-gray-600 ">
                      Booking Ref no : {props.item.booking_id}
                      <span className="ml-2">
                        Receipt no : {props.item.receipt_num}
                      </span>
                      {props.item.delivered_on && (
                        <span className="ml-2">
                          Delivered On : {dateHandler(props.item.delivered_on)}
                        </span>
                      )}
                      <span className="ml-2">
                        Created On :{" "}
                        {dateHandler(props.item.CreatedAt.slice(0, 10))}
                      </span>
                      {props.item.OwnerRef ? (
                        <span className="ml-4 bg-rose-100 text-rose-800 p-1 rounded text-xs">
                          {props.item.OwnerRef}
                        </span>
                      ) : (
                        ""
                      )}
                      {props.item.exchange === "No" ||
                        props.item.exchange === "no" ? (
                        ""
                      ) : (
                        <span className="ml-4 bg-indigo-100 text-indigo-800 p-1 rounded text-xs">
                          {props.item.exchange === "Yes" ||
                            props.item.exchange === "yes"
                            ? "Exchange"
                            : ""}
                        </span>
                      )}
                      {props.item.CustomerSpecialNeed &&
                        props.item.CustomerSpecialNeed.split(",").map((item) => {
                          return (
                            <span className="ml-4 bg-amber-100 text-amber-800 p-1 rounded text-xs">
                              {item ? item : "Booking Priority : N/A"}
                            </span>
                          );
                        })}
                    </p>
  
                    <div className="flex items-center justify-between ">
                      <p className={classes.overflowAllocationAllocation}>
                        {`${props.item.customer_name} ${props.item.LastName}`}
                        <span className="  mt-2 ">
                          <BsDot size={20} />
                        </span>
                        {props.item.customer_mobile}
                        <span className=" mt-2">
                          <BsDot size={20} />
                        </span>
                        <span
                          className={findPreference(props.item.preference1status)}
                        >
                          {`1.${props.item.car_model}  ${props.item.car_variant} ${props.item.car_colour}`}
                        </span>
                        {props.item.CarModel2 ? (
                          <>
                            <span className=" mt-2">
                              <BsDot size={20} />
                            </span>
                            <span
                              className={findPreference(
                                props.item.preference2status
                              )}
                            >
                              {`2.${props.item.CarModel2}  ${props.item.CarVariant2} ${props.item.CarColour2}`}
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </p>
                      {props.CBT === "CBT" ? <></> : <div className="ml-2 flex flex-shrink-0">
                        <Button
                          buttonText={"Allocate Now"}
                          type={"limeFilled"}
                          onClick={() => {
                            props.carDetailHandler(props.item);
                          }}
                        />
                      </div>}
                    </div>
                    <div className=" flex justify-between">
                      <div className={classes.pendingdetailAllocated}>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          {props.item.Location}
                        </p>
                        <span className="ml-2 mr-2  text-xs	">|</span>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          {props.item.AsmName}
                        </p>
                        <span className="ml-2 mr-2  text-xs	">|</span>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          {props.item.ExecutiveName}
                        </p>
                        <span className="ml-2 mr-2  text-xs	">|</span>
                        <p className="flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Booking : Rs.{props.item.booking_amount}
                        </p>
                        <span className="ml-2 mr-2 ext-xs	">|</span>
                        <p className=" flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Booking Date : {dateHandler(props.item.booking_date)}
                        </p>
  
                        <span className="ml-2 mr-2 ext-xs	">|</span>
                        <p className=" flex items-center text-sm text-gray-600 mt-0 mr-0">
                          Committed Date :
                          {dateHandler(props.item.CommittedDeliveryDate)}
                        </p>
                      </div>
                      <div className=" items-center text-xs text-indigo-500 mt-0 italic mr-4">
                        <div
                          className="cursor-pointer flex justify-end py-1"
                          onClick={() => props.followUpHandler(props.item)}
                        >
                          View Remarks
                        </div>
  
                        <div
                          className={`cursor-pointer ${props.item.CBTRemark && "text-red-600  "}`}
                          onClick={() => { props.carBookingHandler(props.item); props.setCustomerRemark(props.item.CBTRemark) }}
                        >
                          {props.item.CBTRemark ? "Update CBT Remark" : "Add CBT Remark"}
  
                        </div>
  
  
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          );
      case "Auto allocation cancelled":
        return (
          <ul role="list" className="divide-y divide-gray-200">
            {/* {console.log(props.item, "sss")} */}
            <li>
              <a className=" hover:bg-gray-50 flex items-center pl-4 ">
                <div flex items-center justify-between className="font-bold">
                  {props.data.SrNo}
                </div>
                <div className="w-full py-4 px-6 font-medium overflow-x-auto">
                  <p className="flex items-center text-sm text-gray-600 ">
                    Booking Ref no : {props.data.booking_id}
                    <span className="ml-2">
                      Receipt no :{props.data.receipt_num}
                    </span>
                    {props.data.delivered_on && (
                      <span className="ml-2">
                        Delivered On : {dateHandler(props.data.delivered_on)}
                      </span>
                    )}
                    {props.data.OwnerRef ? (
                      <span className="ml-4 bg-rose-100 text-rose-800 p-1 rounded text-xs">
                        {props.data.OwnerRef}
                      </span>
                    ) : (
                      ""
                    )}
                    {props.data.OtherRef ? (
                      <span className="ml-4 bg-sky-100 text-sky-800 p-1 rounded text-xs">
                        {props.data.OtherRef}
                      </span>
                    ) : (
                      ""
                    )}
                    {props.data.exchange === "No" ||
                      props.data.exchange === "NO" ? (
                      ""
                    ) : (
                      <span className="ml-4 bg-indigo-100 text-indigo-800 p-1 rounded text-xs">
                        Exchange
                      </span>
                    )}
                    {props.data.CustomerSpecialNeed
                      ? props.data.CustomerSpecialNeed.split(",").map(
                        (item) => (
                          <span className="ml-2 bg-amber-100 text-amber-800 p-1 rounded text-xs">
                            {item}
                          </span>
                        )
                      )
                      : ""}
                  </p>

                  <div className="flex items-center justify-between mt-1">
                    <p className={classes.overflowAllocation}>
                      {props.data.customer_name} {props.data.LastName}
                      <span className="  mt-2 ">
                        <BsDot size={20} />
                      </span>
                      {props.data.customer_mobile}
                      <span className=" mt-2">
                        <BsDot size={20} />
                      </span>
                      <span
                      // className={findPreference(props.item.preference1status)}
                      >
                        {`1.${props.data.model_name}  ${props.data.variant}  ${props.data.colour}`}
                      </span>
                      {/* <span className=" mt-2">
                        <BsDot size={20} />
                      </span>
                      <span
                      // className={findPreference(
                      //   props.item.preference2status
                      // )}
                      >
                        {`2.All New i20   2021 Asta 1.0 Turbo GDI DCT Polar White`}
                      </span> */}
                    </p>
                    <div className="ml-2 flex flex-shrink-0 ">
                      <Button
                        buttonText={"Cancel Allocation"}
                        type={"redFilled"}
                        onClick={() => {
                          props.setCancel(true);
                          props.Params(props.data);
                        }}
                      />
                      <div className="ml-2">
                        <Button
                          buttonText={"Extend CDD"}
                          type={"limeFilled"}
                          onClick={() => {
                            props.setExtendCDDModal(true);
                            props.Params(props.data);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-1 flex justify-between">
                    <div className={classes.pendingdetailAllocated}>
                      <p className="flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                        {props.data.Location}
                      </p>
                      <span className="ml-1 mr-2 mt-0.5 text-xs	">|</span>
                      <p className="flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                        {props.data.AsmName}
                      </p>
                      <span className="ml-1 mr-2 mt-0.5 text-xs	">|</span>
                      <p className="flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                        {props.data.ExecutiveName}
                      </p>
                      <span className="ml-1 mr-2 mt-0.5 text-xs	">|</span>
                      <p className="flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                        Booking : Rs.{props.data.booking_amount}
                      </p>
                      <span className="ml-1 mr-2 mt-0 text-xs	">|</span>
                      <p className=" flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                        Booking Date : {dateHandler(props.data.booking_date)}
                      </p>

                      <span className="ml-1 mr-2 mt-0 text-xs	">|</span>
                      <p className=" flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                        Committed Date :
                        {dateHandler(props.data.CommittedDeliveryDate)}
                      </p>
                      {props.data.PhysicalDate && (
                        <>
                          <span className="ml-1 mr-2 mt-0 text-xs	">|</span>
                          <p className=" flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                            Physical Date :
                            {dateHandler(props.data.PhysicalDate)}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="flex items-center text-[13px] text-indigo-500 mt-0 italic mr-0">
                      <p
                        className="cursor-pointer"
                        onClick={() => props.followUpHandler(props.data)}
                      >
                        View Remarks
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        );
      case "Stm Allocation Status":
        return (
          <ul role="list" className="divide-y divide-gray-200">
            {/* {console.log(props.item, "sss")} */}
            <li>
              <a className=" hover:bg-gray-50 flex items-center pl-4 ">
                <div className="font-bold">{props.data.SrNo}</div>
                <div className="w-full py-4 px-6 font-medium overflow-x-auto">
                  <p className="flex items-center text-sm text-gray-600 ">
                    Booking Ref no : {props.data.booking_id}
                    <span className="ml-2">
                      Receipt no :{props.data.receipt_num}
                    </span>
                    {props.data.delivered_on && (
                      <span className="ml-2">
                        Delivered on :{props.data.delivered_on}
                      </span>
                    )}
                    <span className="ml-2">
                      Created on :
                      {dateHandler(props.data.CreatedAt.slice(0, 10))}
                    </span>
                    {props.data.ETADate && <span className="ml-2">
                      {!props.data.PhysicalDate && (
                        <> ETA : {dateHandler(props.data.ETADate)} </>
                      )}
                    </span>}

                    {props.data.OwnerRef ? (
                      <span className="ml-4 bg-rose-100 text-rose-800 p-1 rounded text-xs">
                        {props.data.OwnerRef}
                      </span>
                    ) : (
                      ""
                    )}
                    {props.data.OtherRef ? (
                      <span className="ml-4 bg-sky-100 text-sky-800 p-1 rounded text-xs">
                        {props.data.OtherRef}
                      </span>
                    ) : (
                      ""
                    )}
                    {props.data.exchange === "No" ||
                      props.data.exchange === "NO" ? (
                      ""
                    ) : (
                      <span className="ml-4 bg-indigo-100 text-indigo-800 p-1 rounded text-xs">
                        Exchange
                      </span>
                    )}
                    {props.data.CustomerSpecialNeed
                      ? props.data.CustomerSpecialNeed.split(",").map(
                        (item) => (
                          <span className="ml-2 bg-amber-100 text-amber-800 p-1 rounded text-xs">
                            {item}
                          </span>
                        )
                      )
                      : ""}
                  </p>

                  <div className="flex items-center justify-between mt-2">
                    <p className={classes.overflowAllocation}>
                      {props.data.customer_name} {props.data.LastName}
                      <span className="  mt-2 ">
                        <BsDot size={20} />
                      </span>
                      {props.data.win_num}
                      <span className="  mt-2 ">
                        <BsDot size={20} />
                      </span>
                      {props.data.customer_mobile}
                      <span className=" mt-2">
                        <BsDot size={20} />
                      </span>
                      <span
                      >
                        {`1.${props.data.CSModel}  ${props.data.CSVarient}  ${props.data.CSColour}`}
                      </span>

                    </p>
                    {props.condition !== "?term=AllocationAging" && (
                      <div className="ml-2 flex flex-shrink-0 ">
                        <Button
                          buttonText={"Cancel Allocation"}
                          type={"redFilled"}
                          onClick={() => {
                            props.setCancel(true);
                            props.Params(props.data);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className=" flex justify-between ">
                    <div className={classes.pendingdetailAllocated}>
                      <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        {props.data.Location}
                      </p>
                      <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                      <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        {props.data.AsmName}
                      </p>
                      <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                      <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        {props.data.ExecutiveName}
                      </p>
                      <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                      <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        Booking : Rs.{props.data.booking_amount}
                      </p>
                      <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                      <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        Booking Date : {dateHandler(props.data.booking_date)}
                      </p>

                      <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                      <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        Committed Date :
                        {dateHandler(props.data.CommittedDeliveryDate)}
                      </p>
                      {props.buttonHide ? (
                        ""
                      ) : (
                        <>
                          {" "}
                          {props.data.allocated_on && (
                            <>
                              <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                              <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                                Allocated On:
                                {dateHandler(props.data.allocated_on)}
                              </p>
                            </>
                          )}
                        </>
                      )}

                      {props.data.PhysicalDate && (
                        <>
                          <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                          <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                            Physical Date :
                            {dateHandler(props.data.PhysicalDate)}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="flex items-center text-[12px] text-indigo-500 mt-0 italic mr-0">
                      <p
                        className="cursor-pointer"
                        onClick={() => props.followUpHandler(props.data)}
                      >
                        View Remarks
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        );
      case "Stm-booking-cancellation-by-showroom-manager":
        return (
          <ul role="list" className="divide-y divide-gray-200">
            {/* {console.log(props.item, "sss")} */}
            <li className="flex justify-between items-center px-4">
              <a className=" hover:bg-gray-50 flex items-center  ">
                <div className="font-bold">{props.data.SrNo}</div>
                <div className="w-full py-4 px-6 font-medium overflow-x-auto">
                  <p className="flex items-center text-sm text-gray-600 ">
                    Booking Ref no : {props.data.booking_id}
                    <span className="ml-2">
                      Receipt no :{props.data.receipt_num}
                    </span>
                    {props.data.delivered_on && (
                      <span className="ml-2">
                        Delivered On : {dateHandler(props.data.delivered_on)}
                      </span>
                    )}
                    {props.data.OwnerRef ? (
                      <span className="ml-4 bg-rose-100 text-rose-800 p-1 rounded text-xs">
                        {props.data.OwnerRef}
                      </span>
                    ) : (
                      ""
                    )}
                    {props.data.OtherRef ? (
                      <span className="ml-4 bg-sky-100 text-sky-800 p-1 rounded text-xs">
                        {props.data.OtherRef}
                      </span>
                    ) : (
                      ""
                    )}
                    {props.data.exchange === "No" ||
                      props.data.exchange === "NO" ? (
                      ""
                    ) : (
                      <span className="ml-4 bg-indigo-100 text-indigo-800 p-1 rounded text-xs">
                        Exchange
                      </span>
                    )}
                    {props.data.CustomerSpecialNeed
                      ? props.data.CustomerSpecialNeed.split(",").map(
                        (item) => (
                          <span className="ml-2 bg-amber-100 text-amber-800 p-1 rounded text-xs">
                            {item}
                          </span>
                        )
                      )
                      : ""}
                  </p>

                  <div className="flex items-center justify-between mt-2">
                    <p className={classes.overflowAllocation}>
                      {props.data.customer_name} {props.data.LastName}
                      <span className="  mt-2 ">
                        <BsDot size={20} />
                      </span>
                      {props.data.win_num}
                      <span className="  mt-2 ">
                        <BsDot size={20} />
                      </span>
                      {props.data.customer_mobile}
                      <span className=" mt-2">
                        <BsDot size={20} />
                      </span>
                      <span>
                        {`1.${props.data.car_model}  ${props.data.car_variant}  ${props.data.car_colour}`}
                      </span>
                    </p>
                  </div>
                  <div className=" flex justify-between items-center">
                    <div className={classes.pendingdetailAllocated}>
                      <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        {props.data.Location}
                      </p>
                      <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                      <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        {props.data.AsmName}
                      </p>
                      <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                      <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        {props.data.ExecutiveName}
                      </p>
                      <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                      <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        Booking : Rs.{props.data.booking_amount}
                      </p>
                      <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                      <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        Booking Date : {dateHandler(props.data.booking_date)}
                      </p>

                      <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                      <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        Committed Date :
                        {dateHandler(props.data.CommittedDeliveryDate)}
                      </p>
                      {props.buttonHide ? (
                        ""
                      ) : (
                        <>
                          {" "}
                          {props.data.allocated_on && (
                            <>
                              <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                              <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                                Allocated On:
                                {dateHandler(props.data.allocated_on)}
                              </p>
                            </>
                          )}
                        </>
                      )}

                      {props.data.PhysicalDate && (
                        <>
                          <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                          <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                            Physical Date :
                            {dateHandler(props.data.PhysicalDate)}
                          </p>
                        </>
                      )}
                    </div>
                    {/* {props.Type === "CBT" ?
                     <></>
                      : <div className="flex items-center text-[12px] text-indigo-500 mt-0 italic mr-0">
                        <p
                          className="cursor-pointer"
                          onClick={() => props.followUpHandler(props.data)}
                        >
                          View Remarks
                        </p>
                      </div>} */}
                  </div>
                </div>
              </a>
               {props.Type === "CBT"?<div>
               <div className="flex items-center text-[14px] text-white border py-2 px-2 rounded-md border-indigo-500 bg-indigo-500  hover:bg-white hover:text-indigo-600 mt-0  mr-0">
                        <p
                          className="cursor-pointer"
                          onClick={() => {props.followUpHandler(props.data.BookingID);props.setCustomerRemark(props.data.CBTRemark)}}
                        >
                          Move to Confirm Booking Cancellation
                        </p>
                      </div>
                      <div className="flex mx-auto text-center font-semibold text-[12px] mt-2 text-gray-500 mt-0 italic mr-0">
                      <p
                        className="cursor-pointer mx-auto"
                        onClick={() => props.RemarkHandler(props.data)}
                      >
                        View Remarks
                      </p></div></div>
                      
                      :<div className="flex items-center font-semibold text-[12px] text-indigo-500 mt-0 pt-14 italic mr-0">
                        <p
                          className="cursor-pointer"
                          onClick={() => props.followUpHandler(props.data)}
                        >
                          View Remarks
                        </p>
                      </div>}
            </li>
          </ul>
        );

        case "Recently Allocated Cars":
          return (
            <ul role="list" className="divide-y divide-gray-200">
              {/* {console.log(props.item, "sss")} */}
              <li className="flex justify-between items-center px-4">
                <a className=" hover:bg-gray-50 flex items-center  ">
                  <div className="font-bold">{props.data.SrNo}</div>
                  <div className="w-full py-4 px-6 font-medium overflow-x-auto">
                    <p className="flex items-center text-sm text-gray-600 ">
                      Booking Ref no : {props.data.booking_id}
                      <span className="ml-2">
                        Receipt no :{props.data.receipt_num}
                      </span>
                      {props.data.delivered_on && (
                        <span className="ml-2">
                          Delivered On : {dateHandler(props.data.delivered_on)}
                        </span>
                      )}
                      {props.data.OwnerRef ? (
                        <span className="ml-4 bg-rose-100 text-rose-800 p-1 rounded text-xs">
                          {props.data.OwnerRef}
                        </span>
                      ) : (
                        ""
                      )}
                      {props.data.OtherRef ? (
                        <span className="ml-4 bg-sky-100 text-sky-800 p-1 rounded text-xs">
                          {props.data.OtherRef}
                        </span>
                      ) : (
                        ""
                      )}
                      {props.data.exchange === "No" ||
                        props.data.exchange === "NO" ? (
                        ""
                      ) : (
                        <span className="ml-4 bg-indigo-100 text-indigo-800 p-1 rounded text-xs">
                          Exchange
                        </span>
                      )}
                      {props.data.CustomerSpecialNeed
                        ? props.data.CustomerSpecialNeed.split(",").map(
                          (item) => (
                            <span className="ml-2 bg-amber-100 text-amber-800 p-1 rounded text-xs">
                              {item}
                            </span>
                          )
                        )
                        : ""}
                    </p>
  
                    <div className="flex items-center justify-between mt-2">
                      <p className={classes.overflowAllocation}>
                        {props.data.customer_name} {props.data.LastName}
                        <span className="  mt-2 ">
                          <BsDot size={20} />
                        </span>
                        {props.data.win_num}
                        <span className="  mt-2 ">
                          <BsDot size={20} />
                        </span>
                        {props.data.customer_mobile}
                        <span className=" mt-2">
                          <BsDot size={20} />
                        </span>
                        <span>
                          {`1.${props.data.car_model}  ${props.data.car_variant}  ${props.data.car_colour}`}
                        </span>
                      </p>
                    </div>
                    <div className=" flex justify-between items-center">
                      <div className={classes.pendingdetailAllocated}>
                        <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                          {props.data.Location}
                        </p>
                        <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                        <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                          {props.data.AsmName}
                        </p>
                        <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                        <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                          {props.data.ExecutiveName}
                        </p>
                        <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                        <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                          Booking : Rs.{props.data.booking_amount}
                        </p>
                        <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                        <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                          Booking Date : {dateHandler(props.data.booking_date)}
                        </p>
  
                        <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                        <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                          Committed Date :
                          {dateHandler(props.data.CommittedDeliveryDate)}
                        </p>
                        {props.buttonHide ? (
                          ""
                        ) : (
                          <>
                            {" "}
                            {props.data.allocated_on && (
                              <>
                                <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                                <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                                  Allocated On:
                                  {dateHandler(props.data.allocated_on)}
                                </p>
                              </>
                            )}
                          </>
                        )}
  
                        {props.data.PhysicalDate && (
                          <>
                            <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                            <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                              Physical Date :
                              {dateHandler(props.data.PhysicalDate)}
                            </p>
                          </>
                        )}
                      </div>
                      {/* {props.Type === "CBT" ?
                       <></>
                        : <div className="flex items-center text-[12px] text-indigo-500 mt-0 italic mr-0">
                          <p
                            className="cursor-pointer"
                            onClick={() => props.followUpHandler(props.data)}
                          >
                            View Remarks
                          </p>
                        </div>} */}
                    </div>
                  </div>
                </a>
                 {/* {props.Type === "CBT"?<div className="flex items-center text-[14px] text-white border py-2 px-2 rounded-md border-indigo-500 bg-indigo-500  hover:bg-white hover:text-indigo-600 mt-0  mr-0">
                          <p
                            className="cursor-pointer"
                            onClick={() => props.followUpHandler(props.data.BookingID)}
                          >
                            Move to Confirm Booking Cancellation
                          </p>
                        </div>:<div className="flex items-center font-semibold text-[12px] text-indigo-500 mt-0 pt-14 italic mr-0">
                          <p
                            className="cursor-pointer"
                            onClick={() => props.followUpHandler(props.data)}
                          >
                            View Remarks
                          </p>
                        </div>} */}
              </li>
            </ul>
          );
      case "Stm Allocation History":
        return (
          <ul role="list" className="divide-y divide-gray-200">
            {/* {console.log(props.item, "sss")} */}
            <li>
              <a className=" hover:bg-gray-50 flex items-center pl-4 ">
                <div flex items-center justify-between className="font-bold">
                  {props.data.SrNo}
                </div>
                <div className="w-full py-4 px-6 font-medium overflow-x-auto">
                  <p className="flex items-center text-sm text-gray-600 ">
                    Booking Ref no : {props.data.booking_id}
                    <span className="ml-2">
                      Receipt no :{props.data.receipt_num}
                    </span>
                    {props.data.delivered_on && (
                      <span className="ml-2">
                        Delivered On : {dateHandler(props.data.delivered_on)}
                      </span>
                    )}
                    <span className="ml-2">
                      Created On :{" "}
                      {dateHandler(props.data.CreatedAt.slice(0, 10))}
                    </span>
                    {props.data.ETADate && <span className="ml-2">
                      {!props.data.PhysicalDate && (
                        <> ETA : {dateHandler(props.data.ETADate)} </>
                      )}
                    </span>}
                    {props.data.OwnerRef ? (
                      <span className="ml-4 bg-rose-100 text-rose-800 p-1 rounded text-xs">
                        {props.data.OwnerRef}
                      </span>
                    ) : (
                      ""
                    )}
                    {props.data.OtherRef ? (
                      <span className="ml-4 bg-sky-100 text-sky-800 p-1 rounded text-xs">
                        {props.data.OtherRef}
                      </span>
                    ) : (
                      ""
                    )}
                    {props.data.exchange === "No" ||
                      props.data.exchange === "NO" ? (
                      ""
                    ) : (
                      <span className="ml-4 bg-indigo-100 text-indigo-800 p-1 rounded text-xs">
                        Exchange
                      </span>
                    )}
                    {props.data.CustomerSpecialNeed
                      ? props.data.CustomerSpecialNeed.split(",").map(
                        (item) => (
                          <span className="ml-2 bg-amber-100 text-amber-800 p-1 rounded text-xs">
                            {item}
                          </span>
                        )
                      )
                      : ""}
                  </p>

                  <div className="flex items-center justify-between mt-2">
                    <p className={classes.overflowAllocation}>
                      {props.data.customer_name} {props.data.LastName}
                      <span className="  mt-2 ">
                        <BsDot size={20} />
                      </span>
                      {props.data.win_num}
                      <span className="  mt-2 ">
                        <BsDot size={20} />
                      </span>
                      {props.data.customer_mobile}
                      <span className=" mt-2">
                        <BsDot size={20} />
                      </span>
                      <span>
                        {`1.${props.data.car_model}  ${props.data.car_variant}  ${props.data.car_colour}`}
                      </span>
                    </p>
                  </div>
                  <div className=" flex justify-between ">
                    <div className={classes.pendingdetailAllocated}>
                      <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        {props.data.Location}
                      </p>
                      <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                      <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        {props.data.AsmName}
                      </p>
                      <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                      <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        {props.data.ExecutiveName}
                      </p>
                      <span className="ml-1 mr-1 mt-0.5 text-xs	">|</span>
                      <p className="flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        Booking : Rs.{props.data.booking_amount}
                      </p>
                      <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                      <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        Booking Date : {dateHandler(props.data.booking_date)}
                      </p>

                      <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                      <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                        Committed Date :
                        {dateHandler(props.data.CommittedDeliveryDate)}
                      </p>
                      {props.buttonHide ? (
                        ""
                      ) : (
                        <>
                          {" "}
                          {props.data.allocated_on && (
                            <>
                              <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                              <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                                Allocated On:
                                {dateHandler(props.data.allocated_on)}
                              </p>
                            </>
                          )}
                        </>
                      )}
                      {props.data.PhysicalDate && (
                        <>
                          <span className="ml-1 mr-1 mt-0 text-xs	">|</span>
                          <p className=" flex items-center text-[12px] text-gray-600 mt-0 mr-0">
                            Physical Date :
                            {dateHandler(props.data.PhysicalDate)}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="flex items-center text-[12px] text-indigo-500 mt-0 italic mr-0">
                      <p
                        className="cursor-pointer"
                        onClick={() => props.followUpHandler(props.data)}
                      >
                        View Remarks
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        );

      case "Reallocation request stm":
        return (
          <>
            <ul
              role="list"
              className="divide-y divide-gray-200 hover:bg-gray-50"
              key={props.index}
            >
              {/* {console.log(props.item, "sss")} */}
              <li>
                <a className="  flex items-center pl-2 border-t-2 border-black-500">
                  <div flex items-center justify-between className="font-bold">
                    {props.index + 1}
                  </div>
                  <div className="w-full py-4 px-6 font-medium overflow-x-auto">
                    <p className="flex items-center text-[13px] text-gray-600 ">
                      <span className=" font-bold ">From:</span>
                      <span className="ml-2">
                        Booking Ref no : {props.data.FromBooking.booking_id}
                      </span>
                      <span className="ml-2">
                        Receipt no :{props.data.FromBooking.receipt_num}
                      </span>
                      {props.data.FromBooking.OwnerRef ? (
                        <span className="ml-4 bg-rose-100 text-rose-800 p-1 rounded text-xs">
                          {props.data.FromBooking.OwnerRef}
                        </span>
                      ) : (
                        ""
                      )}
                      {props.data.FromBooking.OtherRef ? (
                        <span className="ml-4 bg-sky-100 text-sky-800 p-1 rounded text-xs">
                          {props.data.FromBooking.OtherRef}
                        </span>
                      ) : (
                        ""
                      )}

                      {props.data.FromBooking.exchange === "No" ||
                        props.data.FromBooking.exchange === "NO" ? (
                        ""
                      ) : (
                        <span className="ml-4 bg-indigo-100 text-indigo-800 p-1 rounded text-xs">
                          Exchange
                        </span>
                      )}
                      {props.data.FromBooking.CustomerSpecialNeed
                        ? props.data.FromBooking.CustomerSpecialNeed.split(
                          ","
                        ).map((item) => (
                          <span className="ml-2 bg-amber-100 text-amber-800 p-1 rounded text-xs">
                            {item}
                          </span>
                        ))
                        : ""}
                    </p>

                    <div className="flex items-center justify-between  ">
                      <p className={classes.overflowAllocationAllocation}>
                        {props.data.FromBooking.customer_name}{" "}
                        {props.data.FromBooking.LastName}
                        <span className="  mt-2 ">
                          <BsDot size={20} />
                        </span>
                        {props.data.FromBooking.customer_mobile}
                        <span className=" mt-2">
                          <BsDot size={20} />
                        </span>
                        <span
                        // className={findPreference(props.item.preference1status)}
                        >
                          {`1.${props.data.FromBooking.car_model}  ${props.data.FromBooking.car_variant}  ${props.data.FromBooking.car_colour}`}
                        </span>
                        {props.data.FromBooking.CarModel2 && (
                          <>
                            <span className=" mt-2">
                              <BsDot size={20} />
                            </span>
                            <span
                            // className={findPreference(
                            //   props.item.preference2status
                            // )}
                            >
                              {`2.${props.data.FromBooking.CarModel2}  ${props.data.FromBooking.CarVariant2}  ${props.data.FromBooking.CarColour2}`}
                            </span>
                          </>
                        )}
                      </p>
                      {props.tabType === "Pending Request" ? (
                        <></>
                      ) : (
                        <>
                          {props.data.accept_admin === "Declined" ? (
                            <div className="mr-4 text-xs font-bold bg-red-200 text-red-800 p-1 rounded">
                              <span>Declined</span>
                            </div>
                          ) : (
                            <div className="mr-4 text-xs font-bold bg-green-200 text-green-800 p-1 rounded">
                              <span>Accepted</span>
                            </div>
                          )}
                        </>
                      )}

                      {props.tabType === "Pending Request" ? (
                        <>
                          <div className="ml-2 flex flex-shrink-0 ">
                            <Button
                              buttonText={"Decline"}
                              type={"redFilled"}
                              onClick={() => {
                                props.setCancel(true);
                                props.values(props.data.ReallocationID);
                              }}
                            />
                            {props.data.FromBooking.status ===
                              "Allocation Awaited" &&
                              props.data.ToBooking.status === "Allocated" ? (
                              <div className="ml-2 mr-2">
                                <Button
                                  buttonText={"Accept"}
                                  type={"greenFilled"}
                                  onClick={() => {
                                    props.setAccept(true);
                                    props.values(props.data.ReallocationID);
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <div className={classes.pendingdetailAllocated}>
                        <p className="flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                          {props.data.FromBooking.Location}
                        </p>
                        <span className="ml-1 mr-2 mt-0.5 text-xs	">|</span>
                        <p className="flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                          {props.data.FromBooking.AsmName}
                        </p>
                        <span className="ml-1 mr-2 mt-0.5 text-xs	">|</span>
                        <p className="flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                          {props.data.FromBooking.ExecutiveName}
                        </p>
                        <span className="ml-1 mr-2 mt-0.5 text-xs	">|</span>
                        <p className="flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                          Booking : Rs. {props.data.FromBooking.booking_amount}
                        </p>
                        <span className="ml-1 mr-2 mt-0 text-xs	">|</span>
                        {props.data.FromBooking.booking_date ? (
                          <p className=" flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                            Booking Date :
                            {dateHandler(props.data.FromBooking.booking_date)}
                          </p>
                        ) : (
                          ""
                        )}

                        <span className="ml-1 mr-2 mt-0 text-xs	">|</span>
                        {props.data.FromBooking.CommittedDeliveryDate ? (
                          <p className=" flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                            Committed Date :
                            {dateHandler(
                              props.data.FromBooking.CommittedDeliveryDate
                            )}
                          </p>
                        ) : (
                          ""
                        )}

                        <span className="ml-1 mr-2 mt-0 text-xs	">|</span>
                        {props.data.FromBooking.PhysicalDate ? (
                          <p className=" flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                            Physical Date :
                            {dateHandler(props.data.FromBooking.PhysicalDate)}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="flex items-center text-[13px] text-indigo-500 mt-0 italic mr-4">
                        <p
                          className="cursor-pointer"
                          onClick={() =>
                            props.followUpHandler(props.data.FromBooking)
                          }
                        >
                          View Remarks
                        </p>
                      </div>
                    </div>
                  </div>
                </a>

                <a className="  flex items-center pl-4 ">
                  <div className="w-full  px-6 font-medium overflow-x-auto">
                    <p className="flex items-center  text-[13px] text-gray-600 ">
                      <span className=" font-bold ">To:</span>
                      <span className="ml-2">
                        Booking Ref no : {props.data.ToBooking.booking_id}
                      </span>
                      <span className="ml-2">
                        Receipt no :{props.data.ToBooking.receipt_num}
                      </span>
                      {props.data.ToBooking.OwnerRef ? (
                        <span className="ml-4 bg-rose-100 text-rose-800 p-1 rounded text-xs">
                          {props.data.ToBooking.OwnerRef}
                        </span>
                      ) : (
                        ""
                      )}
                      {props.data.ToBooking.OtherRef ? (
                        <span className="ml-4 bg-sky-100 text-sky-800 p-1 rounded text-xs">
                          {props.data.ToBooking.OtherRef}
                        </span>
                      ) : (
                        ""
                      )}

                      {props.data.ToBooking.exchange === "No" ||
                        props.data.ToBooking.exchange === "NO" ? (
                        ""
                      ) : (
                        <span className="ml-4 bg-indigo-100 text-indigo-800 p-1 rounded text-xs">
                          Exchange
                        </span>
                      )}
                      {props.data.ToBooking.CustomerSpecialNeed
                        ? props.data.ToBooking.CustomerSpecialNeed.split(
                          ","
                        ).map((item) => (
                          <span className="ml-2 bg-amber-100 text-amber-800 p-1 rounded text-xs">
                            {item}
                          </span>
                        ))
                        : ""}
                    </p>

                    <div className="flex items-center justify-between">
                      <p className={classes.overflowAllocationAllocation}>
                        {props.data.ToBooking.customer_name}{" "}
                        {props.data.ToBooking.LastName}
                        <span className="  mt-2 ">
                          <BsDot size={20} />
                        </span>
                        {props.data.ToBooking.customer_mobile}
                        <span className=" mt-2">
                          <BsDot size={20} />
                        </span>
                        <span
                        // className={findPreference(props.item.preference1status)}
                        >
                          {`1.${props.data.ToBooking.car_model}  ${props.data.ToBooking.car_variant}  ${props.data.ToBooking.car_colour}`}
                        </span>
                        {props.data.ToBooking.CarModel2 && (
                          <>
                            <span className=" mt-2">
                              <BsDot size={20} />
                            </span>
                            <span
                            // className={findPreference(
                            //   props.item.preference2status
                            // )}
                            >
                              {`2.${props.data.ToBooking.CarModel2}  ${props.data.ToBooking.CarVariant2}  ${props.data.ToBooking.CarColour2}`}
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                    <div className="mb-2 flex justify-between">
                      <div className={classes.pendingdetailAllocated}>
                        <p className="flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                          {props.data.ToBooking.Location}
                        </p>
                        <span className="ml-1 mr-2 mt-0.5 text-xs	">|</span>
                        <p className="flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                          {props.data.ToBooking.AsmName}
                        </p>
                        <span className="ml-1 mr-2 mt-0.5 text-xs	">|</span>
                        <p className="flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                          {props.data.ToBooking.ExecutiveName}
                        </p>
                        <span className="ml-1 mr-2 mt-0.5 text-xs	">|</span>
                        <p className="flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                          Booking : Rs.{props.data.ToBooking.booking_amount}
                        </p>
                        <span className="ml-1 mr-2 mt-0 text-xs	">|</span>
                        <p className=" flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                          Booking Date :
                          {dateHandler(props.data.ToBooking.booking_date)}
                        </p>

                        <span className="ml-1 mr-2 mt-0 text-xs	">|</span>
                        {props.data.ToBooking.CommittedDeliveryDate ? (
                          <p className=" flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                            Committed Date :
                            {dateHandler(
                              props.data.ToBooking.CommittedDeliveryDate
                            )}
                          </p>
                        ) : (
                          ""
                        )}

                        <span className="ml-1 mr-2 mt-0 text-xs	">|</span>
                        {props.data.ToBooking.PhysicalDate ? (
                          <p className=" flex items-center text-[13px] text-gray-600 mt-0 mr-0">
                            Physical Date :
                            {dateHandler(props.data.ToBooking.PhysicalDate)}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="flex items-center text-[13px] text-indigo-500 mt-0 italic mr-4">
                        <p
                          className="cursor-pointer"
                          onClick={() =>
                            props.followUpHandler(props.data.ToBooking)
                          }
                        >
                          View Remarks
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </>
        );
      default:
        console.log("Invalid type");
        break;
    }
  };
  return <div>{listType(props.type)}</div>;
};
export default StackedList;
