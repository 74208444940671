import { useState, useEffect } from "react";
import SmWrapper from "../../Components/SmWrapper";
import Button from "../../tailwindUI/Button";
import Heading from "../../tailwindUI/Heading";
import Input from "../../tailwindUI/Input";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import classes from "../../module.css/ManageExecutive.module.css";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import { useNavigate, useParams } from "react-router-dom";

const ManageExecutiveEdit = () => {
  const navigate = useNavigate();
  const { asmid } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [asm, setAsm] = useState("");
  const [asmData, setAsmData] = useState([]);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [defaultAsm, setDefaultAsm] = useState("");
  const [loadingList, setLoadingList] = useState(false);
  const [loading, setLoading] = useState(false);
  const UserToken = localStorage.getItem("UserToken");

  useEffect(() => {
    ExecutiveListAPI();
    AsmList();
  }, []);
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };
  const ExecutiveListAPI = () => {
    setLoadingList(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      content: 200,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/srm/AllExecutive`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          if (result.data === "") {
            setName("");
            setEmail("");
            setAsm("");
            setPhone("");
            setSelectedLocation("");
            setPassword("");
          } else {
            const getsingleasm = result.data?.filter((item) => {
              return item?.ExecutiveId === asmid;
            });
            // console.log(getsingleasm, "---<");
            setName(getsingleasm[0]?.user_name);
            setEmail(getsingleasm[0]?.user_email);
            // setAsm(getsingleasm[0]?.AsmName);
            setDefaultAsm(getsingleasm[0]?.AsmName);
            setPhone(getsingleasm[0]?.user_mobile);
            setSelectedLocation(getsingleasm[0]?.user_location);
            setPassword(getsingleasm[0]?.user_password);
          }
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoadingList(false));
  };

  const AsmList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${global.apiBaseUrl}/api/v1/stockone/srm/SRMASMList`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          setAsmData(result.data);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };

  const UpdateExecutive = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    if (asm) {
      var raw = JSON.stringify({
        ExecutiveId: asmid,
        UserToken: UserToken,
        user_name: name,
        user_email: email,
        user_mobile: phone,
        user_password: password,
        user_location: selectedLocation,
        asm_id: asm,
      });
    } else {
      var raw = JSON.stringify({
        ExecutiveId: asmid,
        UserToken: UserToken,
        user_name: name,
        user_email: email,
        user_mobile: phone,
        user_password: password,
        user_location: selectedLocation,
      });
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/srm/SRMExecutiveEdit`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (
          result.status_code === 200 &&
          result.message === "Status Updated Successfully"
        ) {
          setMessage("Executive updated successfully");
        } else if (
          result.status_code === 200 &&
          result.message === "Data Not Updated"
        ) {
          setErrorMessage("Executive not updated");
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const emailRegex =
    /^(([^<>()/.,;:\s@"]+(\.[^<>()/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let alphabetsRegex = /^[a-zA-Z][a-zA-Z ]*$/;

  const formValidation = () => {
    let errors = {};
    let isValid = true;
    if (email && !emailRegex.test(email)) {
      isValid = false;
      errors.email = "Please enter valid email address";
    }
    if (!name || name === "") {
      errors.name = "Please enter Name";
      isValid = false;
    } else if (!alphabetsRegex.test(name)) {
      errors.name = "Please enter alphabets only";
      isValid = false;
    }
    if (!password || password === "") {
      errors.password = "Please  enter password";
      isValid = false;
    }

    if (phone.length < 10) {
      errors.phone = "Please enter valid phone no";
      isValid = false;
    }
    if (phone === "" || !phone) {
      errors.phone = "Please Enter phone number";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const onlyNumericMobile = (e) => {
    var reg = /^[0-9\b]+$/;
    reg.test(e.target.value) || e.target.value === ""
      ? setPhone(e.target.value)
      : console.log("");
  };

  const BreadCrumb = {
    pagename: "Manage Executive",
    pagePath: `/sm-manage-executive`,
    pagename1: "Update Executive",
    pagePath1: `/sm-manage-executive/${asmid}`,
  };

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };

  return (
    <SmWrapper>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {message && <SuccessMessage message={successMessage()} />}
      <Heading
        BreadCrumb={BreadCrumb}
        BreadCrumHomeLink={"/sm-dashboard"}
        headingType={"Page Headings"}
        headingText={"Update Executive"}
        subheadingText={localStorage.getItem("Location")}
      />
      {loadingList ? (
        <div className={classes.loader}>
          <img src="../assets/loadingGif.gif" alt="loading" />
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <div className={classes.innerContainer}>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="text"
                label="Name *"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  error.name = "";
                }}
                error={error.name}
              />
              <p className="peer-invalid:visible text-red-500 italic">
                {error.name}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="email"
                label="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  error.email = "";
                }}
                error={error.email}
              />
              <p className="peer-invalid:visible text-red-500 italic ">
                {error.email}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="tel"
                label="Mobile number *"
                maxLength="10"
                onChange={(e) => {
                  onlyNumericMobile(e);
                  error.phone = "";
                }}
                value={phone}
                error={error.phone}
              />
              <p className="peer-invalid:visible text-red-500 italic">
                {error.phone}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="password"
                label="Password *"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  error.password = "";
                }}
                error={error.password}
              />
              <p className="peer-invalid:visible text-red-500 italic ">
                {error.password}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type={"Select Menus Simple native"}
                label={"ASM"}
                asmData={asmData}
                dropdownType={"asmNew"}
                selected={defaultAsm}
                // selected={asm === ""}
                onChange={(e) => {
                  setAsm(e.target.value);
                  error.asm = "";
                }}
                error={error.asm}
              />
              <p class="peer-invalid:visible text-red-500 italic ">
                {error.asm}
              </p>
            </div>
            <div className={classes.inputContainer}>
              <Input
                type="input with label"
                inputType="text"
                label="Location"
                value={selectedLocation}
                onChange={(e) => {
                  setSelectedLocation(e.target.value);
                }}
                disabled="disabled"
              />
            </div>
            <div
              // className={classes.inputContainer}
              className="mt-8"
            >
              <Button
                onClick={() => {
                  formValidation()
                    ? UpdateExecutive()
                    : console.log("err with update api");
                }}
                type={"redFilled"}
                buttonText={"Submit"}
                loading={loading}
              />
            </div>
          </div>
        </div>
      )}
    </SmWrapper>
  );
};
export default ManageExecutiveEdit;
