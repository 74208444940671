import React, { useState, useEffect } from "react";
import Heading from "../../tailwindUI/Heading";
import Table from "../../tailwindUI/Table";
import classes from "../../module.css/RealTimeStockOne.module.css";
import { useNavigate, useParams } from "react-router-dom";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import DataNotFound from "../../Components/DataNotFound";
import Modal from "../../tailwindUI/Modal";
import Button from "../../tailwindUI/Button";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import Navbar from "../../tailwindUI/Navbar";

const AdminRTSPageThree = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingModal1, setloadingModal1] = useState(false);
  const [loadingModal2, setLoadingModal2] = useState(false);
  const [confirmRequestLoading, setConfirmRequestLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [awaitedCarModal, setAwaitedCarModal] = useState(false);
  const [allocatedCarDetail, setAllocatedCarDetail] = useState([]);
  const [carAwaitedList, setCarAwaitedList] = useState([]);
  const [selectedAwaitedCarDetail, setSelectedAwaitedCarDetail] = useState("");
  const [selectedAllocatedCarDetail, setSelectedAllocatedCarDetail] =
    useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const getUsersData = JSON.parse(localStorage.getItem("userdata"));
  const stockManagerFilter = getUsersData?.filter(
    (stockManager) => stockManager.UserRole === "ShowroomManager"
  );
  const stockManagerToken = stockManagerFilter[0]?.UserToken;

  useEffect(() => realTimeStockOneApi(), []);

  const { modelName, variant } = useParams();
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/creta", { replace: true });
  };
  const realTimeStockOneApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: stockManagerToken,
      Model: modelName.replace(/-/g, "/"),
      Variant: variant,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/common/RTSInstockcarDetails`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          if (result.data === "") {
            setData([]);
          } else {
            setData(result.data);
          }
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const allocatedListApi = (carDetail) => {
    const { model_name, variant, colour } = carDetail;
    setloadingModal1(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: stockManagerToken,
      Model: model_name,
      Variant: variant,
      Colour: colour,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/srm/SRMRTSAllocatedList`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          if (result.data === "") {
            setAllocatedCarDetail([]);
          } else {
            setAllocatedCarDetail(result.data);
          }
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setloadingModal1(false));
  };

  const awaitedListApi = (carDetail) => {
    const { model_name, variant, colour } = carDetail;
    setLoadingModal2(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: stockManagerToken,
      Model: model_name,
      Variant: variant,
      Colour: colour,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/srm/SRMBookingListByModelVariantColor`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          if (result.data === "") {
            setCarAwaitedList([]);
          } else {
            setCarAwaitedList(result.data);
          }
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoadingModal2(false));
  };

  const BreadCrumb = {
    pagename: "Real Time Stock",
    pagePath: "/creta-real-time-stock-one",
    pagename1: "Variant List",
    pagePath1: "/creta-real-time-stock-two/" + modelName,
    pagename2: "Color List",
    pagePath2: `/creta-real-time-stock-three/${modelName}/${variant}`,
  };

  const allocatedCarHandler = (carDetail) => {
    setOpen(true);
    allocatedListApi(carDetail);
  };

  const requestAllocationHandler = (data) => {
    setAwaitedCarModal(true);
    setSelectedAllocatedCarDetail(data);
    awaitedListApi(data);
  };

  const selectCarHandler = (item) => {
    setSelectedAwaitedCarDetail(item);
  };

  const awaitedModalToggleHandler = (boolean) => {
    setAwaitedCarModal(boolean);
    setSelectedAwaitedCarDetail("");
  };

  const allocatedModalToggleHandler = (boolean) => {
    setOpen(boolean);
  };

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };
  const tableHeading = [
    {
      id: 1,
      name: "Color",
    },
    {
      id: 2,
      name: "YOM",
    },
    {
      id: 3,
      name: "Availability",
    },
    {
      id: 4,
      name: "Status",
    },
  ];

  const tableHeading1 = [
    {
      id: 1,
      name: "Sr.No.",
    },
    {
      id: 2,
      name: "Model",
    },
    {
      id: 3,
      name: "Variant",
    },
    {
      id: 4,
      name: "Color",
    },
    {
      id: 5,
      name: "Location",
    },
    {
      id: 5,
      name: "ASM",
    },
    {
      id: 6,
      name: "Car Status",
    },
    {
      id: 7,
      name: "ETA Date",
    },
    {
      id: 8,
      name: "Action",
    },
  ];

  const tableHeading2 = [
    {
      id: 1,
      name: "Name",
    },
    {
      id: 2,
      name: "Mobile",
    },
    {
      id: 3,
      name: "ASM",
    },
    {
      id: 4,
      name: "Executive",
    },
    {
      id: 5,
      name: "Booking Priority",
    },
    {
      id: 6,
      name: "Select Booking",
    },
  ];
  return (
    <>
      <Modal
        modalToggleHandler={allocatedModalToggleHandler}
        open={open}
        type="allocated car modal"
      >
        {loadingModal1 ? (
          <div className="w-[160px] m-auto">
            <img
              className="w-full"
              src="/assets/loadingGif.gif"
              alt="loading"
            />
          </div>
        ) : allocatedCarDetail.length > 0 ? (
          <Table
            userRole={localStorage.getItem("UserRole")}
            type="vertical lines"
            tabledataType={"allocated car list"}
            requestAllocationHandler={requestAllocationHandler}
            TableData={allocatedCarDetail}
            tableHeading={tableHeading1}
          />
        ) : (
          <DataNotFound />
        )}
        <div className="text-end mt-4">
          <Button
            buttonText={"Close"}
            type={"whiteButton"}
            onClick={() => setOpen(false)}
          />
        </div>
      </Modal>

      <Modal
        open={awaitedCarModal}
        type="allocated car modal"
        modalToggleHandler={awaitedModalToggleHandler}
      >
        {loadingModal2 ? (
          <div className="w-[160px] m-auto">
            <img
              className="w-full"
              src="/assets/loadingGif.gif"
              alt="loading"
            />
          </div>
        ) : carAwaitedList.length > 0 ? (
          <Table
            type="vertical lines"
            tabledataType={"allocation awaited list for booking"}
            TableData={carAwaitedList}
            tableHeading={tableHeading2}
            selectCarHandler={selectCarHandler}
            selectedAwaitedCarDetail={selectedAwaitedCarDetail}
          />
        ) : (
          <>
            <div className="font-bold pt-8 text-center ">
              You don't have any booking for this car
            </div>
            <div className="py-2 text-center">
              Please book a car for re-allocation request
            </div>
          </>
        )}
        <div className="flex justify-end mt-4">
          <Button
            buttonText={"Close"}
            type={"whiteButton"}
            onClick={() => awaitedModalToggleHandler(false)}
          />
          {selectedAwaitedCarDetail && (
            <div className="ml-2">
              <Button
                buttonText={"Send request"}
                loading={confirmRequestLoading}
                type={"redFilled"}
              />
            </div>
          )}
        </div>
      </Modal>
      <Navbar navbarType="admin navbar" />
      <Heading
        headingType={"Page Headings"}
        BreadCrumHomeLink={"/creta-dashboard"}
        headingText={`Real Time Stock : ${modelName} : ${variant}`}
        BreadCrumb={BreadCrumb}
      />

      <div className={classes.mainContainer}>
        {message && <SuccessMessage message={successMessage()} />}
        {errorMessage && <ErrorMessage message={errorMessage} />}
        {loading ? (
          <div className={classes.loader}>
            <img src="/assets/loadingGif.gif" alt="loading" />
          </div>
        ) : (
          <div className={classes.innerContainer}>
            <div className="bg-white ">
              {data.length === 0 ? (
                <DataNotFound />
              ) : (
                <Table
                  type="vertical lines"
                  tabledataType={"colorListTable"}
                  TableData={data}
                  setOpen={setOpen}
                  allocatedCarHandler={allocatedCarHandler}
                  buttonText="Add "
                  subHeading="subHeading"
                  tableHeading={tableHeading}
                  dashboard="srm"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminRTSPageThree;
