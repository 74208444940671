import React, { useEffect, useState } from "react";
import Heading from "../../tailwindUI/Heading";
import Navbar from "../../tailwindUI/Navbar";
import classes from "../../module.css/StmDashboard.module.css";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import StatsCard from "../../tailwindUI/StatsCard";

const GMDashboard = () => {
  let navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [apiFailled, setApiFailled] = useState(false);
  const [data, setData] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [totalstockcount, setTotalstockcount] = useState({
    totalstockcountobj: "",
    unAllocatedPriorityCount: "",
    hiddenStockCount: "",
    allocationAgingCount: "",
  });

  const [loading, setLoading] = useState(false);
  const [aMUsersLocationArjunganj, setAMUsersLocationArjunganj] = useState([]);
  const [aMUsersLocationStation, setAMUsersLocationStation] = useState([]);
  const [aMUsersLocationKanpur, setAMUsersLocationKanpur] = useState([]);
  const [aMUsersLocationTelibagh, setAMUsersLocationTelibagh] = useState([]);
  const [aMUsersLocationRaebareli, setAMUsersLocationRaebareli] = useState([]);
  const [aMUsersLocationJagdishpur, setAMUsersLocationJagdishpur] = useState(
    []
  );
  const [aMUsersLocationSultanpur, setAMUsersLocationSultanpur] = useState([]);

  const users = localStorage.getItem("UserToken");

  useEffect(() => {
    totalCountApi();
    AMUsersLocationAPi();
  }, []);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  const totalCountApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: users,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMTotalStock_v1`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 200 && result.status === "Success") {
          setData(true);
          setTotalstockcount({
            totalstockcountobj: result.totalstockcount,
            unAllocatedPriorityCount: result.unAllocatedPriorityCount,
            hiddenStockCount: result.hiddenStockCount,
            allocationAgingCount: result.allocationAgingCount,
          });
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
    // .finally(() => setLoading(false));
  };
  const AMUsersLocationAPi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: users,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMUsersLocation_v1`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 200 && result.status === "Success") {
          setAMUsersLocationArjunganj(result.Arjunganj);
          setAMUsersLocationStation(result.Station);
          setAMUsersLocationKanpur(result.Kanpur);
          setAMUsersLocationTelibagh(result.Telibagh);
          setAMUsersLocationRaebareli(result.Raebareli);
          setAMUsersLocationJagdishpur(result.Jagdishpur);
          setAMUsersLocationSultanpur(result.Sultanpur);
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const currentMonth = [
    {
      Model: "Total Stock",
      Count: totalstockcount.totalstockcountobj,
      icon: "../assets/logos/stock.png",
    },
  ];

  return (
    <>
      {errorMessage && <ErrorMessage message={errorMessage} />}

      <Navbar navbarType={"GM navbar"} />

      <div className="mb-4">
        <Heading headingType={"Page Headings"} headingText={"Dashboard"} />
      </div>
      {loading ? (
        <img
          src="../assets/loadingGif.gif"
          alt="loading"
          className={classes.loader}
        />
      ) : (
        <div className={classes.form}>
          <StatsCard
            type="Edp Model Fuel Ui Change"
            url={"/stm-hidden-stock-report"}
            data={currentMonth}
          />

          <>
            {" "}
            <div className="grid grid-cols-2 gap-2">
              <StatsCard
                type="AM Users Location New Ui"
                heading={"Station Road- Cars Allocated"}
                data={aMUsersLocationStation}
              />
              <StatsCard
                type="AM Users Location New Ui"
                heading={"Kanpur Road- Cars Allocated"}
                data={aMUsersLocationKanpur}
              />
            </div>
            <div className="flex justify-between gap-2">
              <StatsCard
                type="AM Users Location New Ui"
                heading={"Telibagh Road- Cars Allocated"}
                data={aMUsersLocationTelibagh}
              />

              <StatsCard
                type="AM Users Location New Ui"
                heading={" Arjunganj- Cars Allocated"}
                data={aMUsersLocationArjunganj}
              />
            </div>
            <div className="flex justify-between gap-2">
              <StatsCard
                type="AM Users Location New Ui"
                heading={"  Raebareli Road- Cars Allocated"}
                data={aMUsersLocationRaebareli}
              />

              <StatsCard
                type="AM Users Location New Ui"
                heading={" Sultanpur Road- Cars Allocated"}
                data={aMUsersLocationSultanpur}
              />
            </div>
            <StatsCard
              type="AM Users Location New Ui"
              heading={" Jagdishpur Road- Cars Allocated"}
              data={aMUsersLocationJagdishpur}
            />
          </>
        </div>
      )}
    </>
  );
};

export default GMDashboard;
