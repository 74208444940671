import React, { useEffect, useState } from "react";
import Navbar from "../../tailwindUI/Navbar";
import Heading from "../../tailwindUI/Heading";
import classes from "../../module.css/StmAllocationStatus.module.css";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Inject,
  Resize,
  Group,
  Sort,
  ColumnMenu,
  Filter,
  Toolbar,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import { registerLicense } from "@syncfusion/ej2-base";

const AsmExecWiseAllocation = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    AMDeliveredCarsReportAPI();
  }, []);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  const AMDeliveredCarsReportAPI = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: localStorage.getItem("UserToken"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMFullAlloactionReport_v1`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status_code === 200 && result.status === "Success") {
          setData(result.data);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const groupOptions = { showGroupedColumn: true };
  const filterSettings = { type: "CheckBox" };

  registerLicense(
    "Mgo+DSMBaFt+QHFqVk9rWU5FdUBAXWFKblF8QWBTfFhgBShNYlxTR3ZbQ15jS3padU1rUHZX;Mgo+DSMBPh8sVXJ1S0d+X1hPc0BDXnxLflF1VWFTe116d1NWESFaRnZdQV1nSH1TdEFqWndXcXBc;ORg4AjUWIQA/Gnt2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxWeXdUTw==;MTcwNTQwM0AzMjMxMmUzMTJlMzMzOWZUTFVvWkhXMTl6bXFMbDFGTEJDRzlPVlZnNzZhcmYwZ1FIYjRlVW5XejQ9;MTcwNTQwNEAzMjMxMmUzMTJlMzMzOUU5TzdEUkp0UW5NdEFMZ1JjaXFyNTdOSlcveFgvUG9rZTFiQzRBQUs3VHc9;NRAiBiAaIQQuGjN/V0d+XU9Hf1RHQmJKYVF2R2BJfVR0d19FZkwgOX1dQl9gSXpSdUVhXXZdeH1cTmk=;MTcwNTQwNkAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9;MTcwNTQwN0AzMjMxMmUzMTJlMzMzOUNpN0NnYjVpOG5Mb3hORXltenJEUGg5YzJjbVNxcW1tTUNOWFh6QmM2SFU9;Mgo+DSMBMAY9C3t2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxXcHxSTw==;MTcwNTQwOUAzMjMxMmUzMTJlMzMzOUI0QmhiWElTNzN3dDd1aElXMWlFeDdWdTZOanJYM25MWmhKZUlSakFhMVk9;MTcwNTQxMEAzMjMxMmUzMTJlMzMzOWxrQ1hra29TS1RvRzNUa25tY2tnOVBtNDVtMlhKZ29mVi9TK2V0L0padDQ9;MTcwNTQxMUAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9"
  );

  const toolbarOptions = ["ExcelExport", "PdfExport", "CsvExport", "Search"];
  let gridInstance;
  let flag = true;
  function dataBound() {
    if (flag) {
      gridInstance.toolbarModule.toolbar.hideItem(2, true);
      flag = false;
    }
  }

  function toolbarClick(args) {
    switch (args.item.text) {
      case "PDF Export":
        gridInstance.pdfExport();
        break;
      case "Excel Export":
        gridInstance.excelExport();
        break;
      case "CSV Export":
        gridInstance.csvExport();
        break;
    }
  }

  const BreadCrumb = {
    pagename: "Detailed Allocation Report",
    pagePath: "gm-detailed-allocation-report",
    pagename1: "",
    pagePath1: "",
  };
  return (
    <div>
      <Navbar navbarType={"GM navbar"} />
      <div className={classes.maincontainer}>
        <Heading
          headingType={"Page Headings"}
          headingText={"Detailed Allocation Report"}
          BreadCrumb={BreadCrumb}
          BreadCrumHomeLink={"/gm-dashboard"}
        />
        {loading ? (
          <div className="flex justify-center my-2">
            <img src="assets/loadingGif.gif" alt="loading" />
          </div>
        ) : (
          <div className={classes.background}>
            <div className="control-pane">
              <div className="control-section">
                <GridComponent
                  dataSource={data}
                  height="500"
                  width={"100%"}
                  allowPaging={true}
                  allowResizing={true}
                  allowGrouping={true}
                  allowSorting={true}
                  allowFiltering={true}
                  showColumnMenu={true}
                  groupSettings={groupOptions}
                  filterSettings={filterSettings}
                  toolbar={toolbarOptions}
                  allowExcelExport={true}
                  allowPdfExport={true}
                  toolbarClick={toolbarClick.bind(this)}
                  ref={(grid) => (gridInstance = grid)}
                  dataBound={dataBound.bind(this)}
                  pageSettings={{ pageCount: 4, pageSizes: true }}
                  autoFit={true}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="AsmName"
                      headerText="ASM"
                      // width="150"
                      // textAlign="center"
                    ></ColumnDirective>
                    <ColumnDirective
                      field="ExecutiveName"
                      headerText="Executive"
                      // width="150"
                      // textAlign="center"
                    ></ColumnDirective>

                    <ColumnDirective
                      field="car_model"
                      headerText="Car Model"
                      // width="150"
                    ></ColumnDirective>

                    <ColumnDirective
                      field="Location"
                      headerText="Location"
                      // width="150"
                      // textAlign="center"
                    ></ColumnDirective>

                    <ColumnDirective
                      field="car_colour"
                      headerText="Car Colour"
                      // width="150"
                      // textAlign="center"
                    ></ColumnDirective>
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Resize,
                      Group,
                      Sort,
                      ColumnMenu,
                      Filter,
                      Page,
                      Toolbar,
                      ExcelExport,
                      PdfExport,
                    ]}
                  />
                </GridComponent>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AsmExecWiseAllocation;
