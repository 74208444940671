import React, { useEffect, useState } from "react";
import TableHeader from "../../Components/TableHeader";
import Heading from "../../tailwindUI/Heading";
import Pagination from "../../tailwindUI/Pagination";
import Table from "../../tailwindUI/Table";
import classes from "../../module.css/ViewStockDetails.module.css";
import Navbar from "../../tailwindUI/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import DataNotFound from "../../Components/DataNotFound";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";

function ViewStockDetails() {
  const location = useLocation();
  const pathname = window.location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const term = queryParams.get("term");

  const valuesofterm = () => {
    if (term) return term;
    else return "";
  };

  let navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [pageResults, setPageResults] = useState(0);
  const [searchTerm, setSearchTerm] = useState(valuesofterm());

  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState("ASC");
  const [orderSort, setOrderSort] = useState("ASC");
  const [modelSort, setModelSort] = useState("ASC");
  const [variantSort, setVariantSort] = useState("ASC");
  const [feulSort, setFeulSort] = useState("ASC");
  const [colorSort, setColorSort] = useState("ASC");
  const [carStatus, setCarStatus] = useState("ASC");
  const [allExcelData, setAllExcelData] = useState([]);
  const page = parseInt(queryParams.get("page") ? queryParams.get("page") : 1);
  const maxpages = Math.ceil(count / 20);
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    fetchStockDetails();
  }, [searchTerm === "", page]);

  const fetchStockDetails = async () => {
    setLoading(true);
    let fetchedData = await fetch(
      `${global.apiBaseUrl}/api/v1/stockone/edp/EDPStockDetailList`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.authToken,
        },
        body: JSON.stringify({
          UserToken: localStorage.getItem("UserToken"),
          SearchTerm: searchTerm,
          page: page,
        }),
      }
    );

    let parsedData = await fetchedData.json();

    setLoading(false);
    // if (typeof parsedData.data != "string") {
    //   setCount(parsedData.datacount);

    //   setPageResults(parsedData.data.length);
    // } else
    if (
      parsedData.status_code === 200 &&
      parsedData.status === "Success" &&
      parsedData.data ===
        "Invalid User Token, The token may have expired, You may need to re-login" &&
      parsedData.message ===
        "Invalid User Token, The token may have expired, You may need to re-login"
    ) {
      LogOut();
    } else if (
      parsedData.status_code === 200 &&
      parsedData.status === "Success" &&
      parsedData.message === "data found successfully"
    ) {
      if (searchTerm && parsedData.data.length < 2) {
        navigate(pathname);
      }
      setTableData([...parsedData.data]);
      setCount(parsedData.datacount);
      setPageResults(parsedData.data.length);
      setAllExcelData([...parsedData.alldata]);
    } else if (
      parsedData.status_code === 200 &&
      parsedData.status === "Success" &&
      parsedData.message === "data not found "
    ) {
      if (searchTerm) {
        navigate(pathname);
      }
      setTableData([]);
      setCount(0);
      setPageResults(0);
    }
  };

  const BreadCrumb = {
    pagename: "Stock Details",
    pagePath: "/edp-stock-details",
    pagename1: "",
    pagePath1: "",
  };

  const BreadCrumHomeLink = "/edp-dashboard";

  return (
    <>
      <Navbar navbarType={"blue navbar"} />
      <div className={classes.maincontainer}>
        <div className="mb-4">
          <Heading
            headingType={"Page Headings"}
            headingText={"View Stock Details"}
            BreadCrumb={BreadCrumb}
            BreadCrumHomeLink={BreadCrumHomeLink}
          />
        </div>

        <div className={classes.heading}>
          <TableHeader
            buttonText="Search"
            tableHeading="Search Criteria"
            subHeading="subHeading"
            totalResult={count}
            pageResult={pageResults}
            apicall={fetchStockDetails}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            term={term}
          />
          <div className="mt-2">
            <ExcelDownload
              csvData={allExcelData}
              fileName="EDP Stock Details"
            />
          </div>
        </div>
        <div className={classes.table}>
          {loading ? (
            <img
              src="../assets/loadingGif.gif"
              alt="loading"
              className={classes.loader}
            />
          ) : (
            <>
              {tableData.length > 0 ? (
                <Table
                  type="viewStocks"
                  setTableData={setTableData}
                  TableData={tableData}
                  setSort={setSort}
                  sort={sort}
                  orderSort={orderSort}
                  setOrderSort={setOrderSort}
                  setModelSort={setModelSort}
                  modelSort={modelSort}
                  variantSort={variantSort}
                  setVariantSort={setVariantSort}
                  feulSort={feulSort}
                  setFeulSort={setFeulSort}
                  colorSort={colorSort}
                  setColorSort={setColorSort}
                  carStatus={carStatus}
                  setCarStatus={setCarStatus}
                />
              ) : (
                <div className={classes.DataNotFound}>
                  <DataNotFound />
                </div>
              )}
            </>
          )}
        </div>
        {maxpages > 1 && (
          <div className={classes.pagination}>
            <Pagination maxPages={maxpages} />
          </div>
        )}
      </div>
    </>
  );
}

export default ViewStockDetails;
