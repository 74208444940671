import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SmWrapper from "../../Components/SmWrapper";
import GridListCards from "../../tailwindUI/GridListCards";
import Heading from "../../tailwindUI/Heading";
import classes from "../../module.css/RealTimeStockTwo.module.css";
import DataNotFound from "../../Components/DataNotFound";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
const RealTimeStockTwo = () => {
  const [stockData, setStockdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    carStock();
  }, []);
  const { modelName } = useParams();
  const UserToken = localStorage.getItem("UserToken");
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  const BreadCrumb = {
    pagename: "Real Time Stock",
    pagePath: "/sm-real-time-stock-one",
    pagename1: "Variant List",
    pagePath1: "/sm-real-time-stock-two/" + modelName,
  };
  const carStock = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      Model: modelName.replace(/-/g, "/"),
      // "Variant": "1.0 Turbo GDI DCT SX+",
      // "FuelType": "Petrol"
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${global.apiBaseUrl}/api/v1/stockone/common/RTSList`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          setStockdata(result.data);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };
  return (
    <SmWrapper>
      {" "}
      {/* <div className={classes.heading}> */}{" "}
      <Heading
        BreadCrumb={BreadCrumb}
        BreadCrumHomeLink={"/sm-dashboard"}
        headingType={"Page Headings"}
        headingText={`Real Time Stock : ${modelName}`}
      />
      {/* </div> */}
      {loading ? (
        <div className={classes.loader}>
          {" "}
          <img src="../assets/loadingGif.gif" alt="loading" />
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <div className="sm:flex sm:justify-between sm:items center bg-white p-4">
            <div className="sm:flex pl-4 sm:pl-0 ">
              <div className="mr-2 text-xs">
                <span className="bg-green-600 w-2 h-2 inline-block mr-1"></span>
                Available In Stock
              </div>
              <div className="mr-2 text-xs">
                <span className="bg-blue-600 w-2 h-2 inline-block mr-1"></span>
                InStock But Allocated
              </div>
              <div className="mr-2 text-xs">
                <span className="bg-red-500 w-2 h-2 inline-block mr-1"></span>
                Out Of Stock
              </div>
            </div>
          </div>
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {/* <div className={classes.filterContainer}>
          <Filter
            sortOptions={sortOptions}
            filters={filterOption}
            activeFilters={actFilter}
            checkedCheckboxes={checkedCheckboxes}
            setCheckedCheckboxes={setCheckedCheckboxes}
            handleCheckboxChange={handleCheckboxChange}
            removeHandler={removeHandler}
          />
        </div> */}

          {stockData?.length ? (
            <GridListCards modelName={modelName} stockList={stockData} />
          ) : (
            <div className="w-full bg-white">
              {" "}
              <DataNotFound />
            </div>
          )}
        </div>
      )}
    </SmWrapper>
  );
};

export default RealTimeStockTwo;
