import React, { useEffect, useState } from "react";
import Heading from "../../tailwindUI/Heading";
import Navbar from "../../tailwindUI/Navbar";
import classes from "../../module.css/AllocatedVINAwaited.module.css";
import Tab from "../../tailwindUI/Tab";
import Table from "../../tailwindUI/Table";
import Input from "../../tailwindUI/Input";
import Modal from "../../tailwindUI/Modal";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import DataNotFound from "../../Components/DataNotFound";
import { useLocation, useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import PreferenceCard from "../../tailwindUI/PreferenceCard";
import Button from "../../tailwindUI/Button";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import Pagination from "../../tailwindUI/Pagination";

const AllocatedVINAwaited = () => {
  const navigate = useNavigate();
  const [tabType, setTabType] = useState("VIN Arrived");
  const [vinArrived, setVinArrived] = useState([]);
  const [vinPending, setVinPending] = useState([]);
  const [allocateModal, setAllocateModal] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [preferenceModal, setPreferenceModal] = useState(false);
  const [preferenceData, setPreferenceData] = useState({});
  const [preference1, setPreference1] = useState([]);
  const [preference2, setPreference2] = useState([]);
  const [preferenceBookingDetail, setPreferenceBookingDetail] = useState("");
  const [tabCounts, setTabCounts] = useState("");
  const [aMVinArrivedListCounts, setAMVinArrivedListCounts] = useState("");
  const [aMVinPendingListCount, setAMVinPendingListCount] = useState("");

  const [stockManagerEdd, setStockManagerEdd] = useState("");
  const [apiFailled, setApiFailled] = useState(false);

  const [message, setMessage] = useState("");
  const [cancel, setCancel] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [values, setValues] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;
  const maxPages = Math.ceil(
    tabType === "VIN Arrived"
      ? aMVinArrivedListCounts / 20
      : aMVinPendingListCount / 20
  );
  const UserToken = localStorage.getItem("UserToken");

  useEffect(() => {
    if (tabType === "VIN Arrived") {
      AMVinArrivedListAPi();
    }
    if (tabType === "VIN Pending") {
      AMVinPendingListAPi();
    }
    AMAllocatedButVinAwatedTabCountAPi();
  }, [page, tabType]);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  const AMVinArrivedListAPi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      page: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMVinArrivedList`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          setVinArrived(result.data);
          setAMVinArrivedListCounts(result.datacount);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const AMVinPendingListAPi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      page: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMVinPendingList`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          setVinPending(result.data);
          setAMVinPendingListCount(result.datacount);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const AMBookingListByModelVariantColorAPi = (model_name, variant, colour) => {
    setModalLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,

      Model: model_name,
      Variant: variant,
      Colours: colour,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMBookingListByModelVariantColor`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setPreference1(result.data1);
          setPreference2(result.data2);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setModalLoading(false));
  };

  const AMAllocatedButVinAwatedTabCountAPi = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMAllocatedButVinAwatedTabCount`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setTabCounts(result);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const lastmodalAPiSuccess = () => {
    setPreferenceModal(false);
    setAllocateModal(false);
    setStockManagerEdd("");
    setErrors({});
  };

  const AMCarAllocateVinArrivedAPi = () => {
    if (validation()) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", global.authToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserToken: UserToken,
        CarstockId: preferenceData.CarStockID,
        OldBookingId: preferenceData.BookingID,
        NewBookingId: preferenceBookingDetail.BookingID,
        AmEdd: stockManagerEdd,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${global.apiBaseUrl}/api/v1/stockone/am/AMCarAllocateVinArrived`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status_code === 200 && result.status === "Success") {
            setStockManagerEdd("");
            AMVinArrivedListAPi();
            AMVinPendingListAPi();
            AMAllocatedButVinAwatedTabCountAPi();
            lastmodalAPiSuccess();
            setMessage(result.message);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setErrorMessage("Something went wrong please contact support !");
        });
    }
  };

  const AMCancelAllocationAPi = (values) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      CarStockID: values.CarStockID,
      BookingID: values.BookingID,
      AutoAllocationCancleReason: cancellationReason,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMCancelAllocation`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setCancel(false);
          AMVinPendingListAPi();
          AMVinArrivedListAPi();
          setMessage(result.message);
          setCancellationReason("");
          setErrors("");
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };
  const BreadCrumb = {
    pagename: "Allocated But VIN Awaited",
    pagePath: "/stm-allocated-vin-awaited",
    pagename1: "",
    pagePath1: "",
  };
  const tabs = [
    {
      name: "VIN Arrived",
      count: tabCounts.VinArrived,
      current: true,
    },

    {
      name: "VIN Pending",
      count: tabCounts.VinPending,
      current: false,
    },
  ];

  const columns = [
    "VIN",
    "Order No.",
    "Model",
    "Variant",
    "Fuel Type",
    "Color",
    "Status",
    "ETA",
    "Outlet",
    "Allocate",
    "Cancel Allocation",
  ];

  const datavalues = (values) => {
    setPreferenceData(values);
  };
  const dateHandler = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
  };

  const getSmEdd = () => {
    if (preferenceBookingDetail !== "") {
      return setAllocateModal(true);
    }
  };
  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };

  const validation = () => {
    let isValid = true;
    const error = {};
    if (stockManagerEdd === "") {
      isValid = false;
      error.stockManagerEdd = "Please select EDD";
    }
    setErrors(error);
    return isValid;
  };

  const Params = (value) => {
    setValues(value);
  };

  const validationCancel = () => {
    let isValid = true;
    const error = {};
    if (cancellationReason === "") {
      isValid = false;
      error.cancellationReason = "Enter Cancellation Reason";
    }
    setErrors(error);
    return isValid;
  };

  return (
    <>
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
          apiFailled={apiFailled}
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {preferenceModal && (
        <Modal
          type="Preference Modal"
          setOpen={setPreferenceModal}
          open={preferenceModal}
          onClose={() => {
            setPreferenceModal(false);
            setPreferenceBookingDetail("");
          }}
        >
          <div className="bg-indigo-700 text-white p-6">
            <div className="flex justify-between items-center">
              <div className="font-bold text-xl">{`${preferenceData.model_name} ${preferenceData.variant} ${preferenceData.colour}`}</div>
              <div>
                <IoCloseSharp
                  className="text-white cursor-pointer text-xl"
                  onClick={() => {
                    setPreferenceModal(false);
                    setPreferenceBookingDetail("");
                  }}
                />
              </div>
            </div>
            {preferenceData.etaDate ? (
              <div className="text-blue-200 text-xl font-medium my-1">
                {dateHandler(preferenceData.etaDate)}
              </div>
            ) : (
              ""
            )}

            <div className="flex">
              <div className="text-gray-400 text-xl mb-2 mr-2">
                {preferenceData.availabilitystatus}
              </div>
              <div className="text-gray-400 text-xl mb-2 mr-2">
                {preferenceData.carstatus}
              </div>
            </div>
            {/* <div className="flex  font-bold ">
              <div className="pr-8">{`1.${preferenceData.car_model} ${preferenceData.car_variant} ${preferenceData.car_colour}`}</div>

              <div>{`2.${preferenceData.CarModel2} ${preferenceData.CarVariant2} ${preferenceData.CarColour2}`}</div>
            </div> */}
          </div>

          <div className="flex justify-between  items-center p-6">
            <div className="font-bold text-lg">Preference1</div>
            <div>
              {preference1.length === 0 && preference2.length === 0 ? (
                <></>
              ) : (
                <>
                  {preferenceBookingDetail !== "" && (
                    <Button
                      buttonText={"Allocate"}
                      type={"greenFilled"}
                      onClick={() => getSmEdd()}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {modalLoading ? (
            <div className={"w-full bg-white d-flex m-auto justify-center "}>
              <img
                className="m-auto"
                src="../assets/loadingGif.gif"
                alt="loading"
              />
            </div>
          ) : (
            <>
              <div className="px-6">
                <div className=" grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {preference1?.length > 0 ? (
                    <>
                      {preference1?.map((item) => {
                        return (
                          <PreferenceCard
                            cardType="Allocated Vin Awaited"
                            preference={item}
                            onClick={() => {
                              setPreferenceBookingDetail(item);
                              setErrors({});
                            }}
                            preferenceBookingDetail={preferenceBookingDetail}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <div>Preference 1 is not available</div>
                  )}
                </div>
              </div>

              <div className="font-bold text-lg p-6">Preference2</div>
              <div className="px-6 mb-16">
                <div className=" grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {preference2?.length > 0 ? (
                    <>
                      {preference2?.map((item) => {
                        return (
                          <PreferenceCard
                            cardType="Allocated Vin Awaited"
                            preference={item}
                            onClick={() => {
                              setPreferenceBookingDetail(item);
                              setErrors({});
                            }}
                            preferenceBookingDetail={preferenceBookingDetail}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <div>Preference 2 is not available</div>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal>
      )}

      {allocateModal && (
        <Modal
          type="Allocate Vin awaited"
          desc={"Are you sure you want to allocate ?"}
          heading={"Vin Arrived"}
          loading={loading}
          setOpen={setAllocateModal}
          open={allocateModal}
          onCancel={() => {
            setAllocateModal(false);
            setErrors({});
            setPreferenceBookingDetail("");
            setStockManagerEdd("");
          }}
          onClick={() => AMCarAllocateVinArrivedAPi()}
        >
          <div className="mt-3 text-center sm:mt-2 sm:ml-20 sm:mr-24 sm:text-left   ">
            <PreferenceCard
              cardType="Allocated Vin Awaited checked"
              preference={preferenceBookingDetail}
            />
          </div>
          <div className="mt-3 text-center sm:mt-2 sm:ml-20 sm:mr-24 sm:text-left  ">
            <Input
              type={"input with label"}
              label="Stock Manager EDD"
              value={stockManagerEdd}
              inputType={"date"}
              onChange={(e) => {
                setStockManagerEdd(e.target.value);
              }}
              onKeyDown={(e) => e.preventDefault()}
            />
            <span className="error">{errors.stockManagerEdd}</span>
          </div>
        </Modal>
      )}

      {cancel && (
        <Modal
          setOpen={() => {
            setCancel(false);
            setCancellationReason("");
            setErrors("");
          }}
          desc={"Are you sure you want to cancel allocation?"}
          heading={"Cancel Allocation"}
          open={cancel}
          onCancel={() => {
            setCancel(false);
            setCancellationReason("");
            setErrors("");
          }}
          onClick={() => {
            validationCancel()
              ? AMCancelAllocationAPi(values)
              : console.log("err");
          }}
          type="Simple alert"
          loading={loading}
        >
          <div className="mb-2 mt-2">
            <Input
              type={"input with label"}
              placeholder="Enter reason for cancellation"
              label="Cancellation Reason ?"
              inputType="text"
              onInput={() => (errors.cancellationReason = "")}
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
              error={errors.cancellationReason}
            />
            <span className="error">{errors.cancellationReason}</span>
          </div>
        </Modal>
      )}
      <Navbar navbarType={"black navbar"} />
      <Heading
        headingType={"Page Headings"}
        headingText={"Allocated But VIN Awaited"}
        BreadCrumb={BreadCrumb}
        BreadCrumHomeLink={"/stm-dashboard"}
      />
      <div className={classes.maincontainer}>
        <Tab tabs={tabs} type="Reallocation request" setTabType={setTabType} />
        {loading ? (
          <div className={"w-full bg-white  "}>
            <img
              className="m-auto"
              src="../assets/loadingGif.gif"
              alt="loading"
            />
          </div>
        ) : (
          <>
            {tabType === "VIN Arrived" ? (
              <>
                {vinArrived?.length > 0 ? (
                  <Table
                    type="Allocate but vin awaited"
                    columnList={columns}
                    // setAllocateModal={setAllocateModal}
                    setPreferenceModal={setPreferenceModal}
                    data={vinArrived}
                    tabType={tabType}
                    Params={Params}
                    setCancel={setCancel}
                    datavalues={datavalues}
                    AMBookingListByModelVariantColorAPi={
                      AMBookingListByModelVariantColorAPi
                    }
                  />
                ) : (
                  <DataNotFound />
                )}
              </>
            ) : (
              <>
                {vinPending?.length > 0 ? (
                  <Table
                    type="Allocate but vin awaited"
                    columnList={columns}
                    // setAllocateModal={setAllocateModal}
                    setPreferenceModal={setPreferenceModal}
                    data={vinPending}
                    tabType={tabType}
                    Params={Params}
                    setCancel={setCancel}
                    datavalues={datavalues}
                    AMBookingListByModelVariantColorAPi={
                      AMBookingListByModelVariantColorAPi
                    }
                  />
                ) : (
                  <DataNotFound />
                )}
              </>
            )}
          </>
        )}

        <div className="bg-white">
          {maxPages > 1 && <Pagination maxPages={maxPages} />}
        </div>
      </div>
    </>
  );
};

export default AllocatedVINAwaited;
