import React, { useEffect, useState } from "react";
import Navbar from "../../tailwindUI/Navbar";
import Heading from "../../tailwindUI/Heading";
import classes from "../../module.css/StmDashboard.module.css";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
const StmNotifications = () => {
  const [loading, setLoading] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState("");
  const [notifiication, setNotifiication] = useState([]);
  const users = localStorage.getItem("UserToken");

  useEffect(() => {
    Notification();
  }, []);

  const Notification = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: users,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMGetNotifications`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setNotifiication(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const ReadNotification = (id) => {
    setNotificationLoading(id);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: users,
      NotificationID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMNotificationMarkRead`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          Notification();
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setNotificationLoading(""));
  };
  return (
    <>
      <Navbar navbarType={"black navbar"} />
      <div className="mb-4">
        <Heading
          headingType={"Page Headings"}
          headingText={"Notifications"}
          // subheadingText={localStorage.getItem("Location")}
        />
      </div>

      {loading ? (
        <img
          src="../assets/loadingGif.gif"
          alt="loading"
          className={classes.loader}
        />
      ) : (
        <div className={classes.form}>
          {notifiication.length ? (
            <SuccessMessage
              type="Stm Notifications"
              MarkRead={ReadNotification}
              loading={notificationLoading}
              data={notifiication}
              condition="Stm"
            />
          ) : (
            <>No Notification Available</>
          )}
        </div>
      )}
    </>
  );
};

export default StmNotifications;
