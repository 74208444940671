import React, { useEffect, useState } from "react";
import classes from "../../module.css/TodaysActivitylog.module.css";
import Navbar from "../../tailwindUI/Navbar";
import Heading from "../../tailwindUI/Heading";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Inject,
  Resize,
  Group,
  Sort,
  ColumnMenu,
  Filter,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import { registerLicense } from "@syncfusion/ej2-base";
import "./../../index.css";

const AdminEdpActivityLog = () => {
  const getUsersData = JSON.parse(localStorage.getItem("userdata"));
  const edpFilter = getUsersData?.filter((edp) => edp.UserRole === "EDP");
  const edpToken = edpFilter[0].UserToken;

  // const stockManagerFilter = getUsersData?.filter(stockManager => stockManager.UserRole === "StockManager")
  // const stockManagerToken = stockManagerFilter[0].UserToken;

  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/creta", { replace: true });
  };

  useEffect(() => {
    Activitylog();
  }, []);

  const Activitylog = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: edpToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}tockone/edp/EDPActivityLogSearch`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (
          result.status === "Success" &&
          result.status_code === 200 &&
          result.message === "data found successfully"
        ) {
          setData(result.data);
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "data not found "
        ) {
          setData([]);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })

      .finally(() => setLoading(""));
  };

  const BreadCrumb = {
    pagename: "Activity Log",
    pagePath: "/creta-activity-log",
    pagename1: "",
    pagePath1: "",
  };

  const BreadCrumHomeLink = "/creta-dashboard";

  const toolbarOptions = ["Search", "ExcelExport", "PdfExport", "CsvExport"];
  const groupOptions = { showGroupedColumn: true };
  const filterSettings = { type: "CheckBox" };

  let gridInstance;
  let flag = true;
  function dataBound() {
    if (flag) {
      gridInstance.toolbarModule.toolbar.hideItem(2, true);
      flag = false;
    }
  }

  function toolbarClick(args) {
    switch (args.item.text) {
      case "PDF Export":
        gridInstance.pdfExport();
        break;
      case "Excel Export":
        gridInstance.excelExport();
        break;
      case "CSV Export":
        gridInstance.csvExport();
        break;
    }
  }

  registerLicense(
    "Mgo+DSMBaFt+QHFqVk9rWU5FdUBAXWFKblF8QWBTfFhgBShNYlxTR3ZbQ15jS3padU1rUHZX;Mgo+DSMBPh8sVXJ1S0d+X1hPc0BDXnxLflF1VWFTe116d1NWESFaRnZdQV1nSH1TdEFqWndXcXBc;ORg4AjUWIQA/Gnt2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxWeXdUTw==;MTcwNTQwM0AzMjMxMmUzMTJlMzMzOWZUTFVvWkhXMTl6bXFMbDFGTEJDRzlPVlZnNzZhcmYwZ1FIYjRlVW5XejQ9;MTcwNTQwNEAzMjMxMmUzMTJlMzMzOUU5TzdEUkp0UW5NdEFMZ1JjaXFyNTdOSlcveFgvUG9rZTFiQzRBQUs3VHc9;NRAiBiAaIQQuGjN/V0d+XU9Hf1RHQmJKYVF2R2BJfVR0d19FZkwgOX1dQl9gSXpSdUVhXXZdeH1cTmk=;MTcwNTQwNkAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9;MTcwNTQwN0AzMjMxMmUzMTJlMzMzOUNpN0NnYjVpOG5Mb3hORXltenJEUGg5YzJjbVNxcW1tTUNOWFh6QmM2SFU9;Mgo+DSMBMAY9C3t2VFhhQlJNfVpdXGBWfFN0RnNbdVpzflZAcC0sT3RfQF5jTX9QdkdnUHxXcHxSTw==;MTcwNTQwOUAzMjMxMmUzMTJlMzMzOUI0QmhiWElTNzN3dDd1aElXMWlFeDdWdTZOanJYM25MWmhKZUlSakFhMVk9;MTcwNTQxMEAzMjMxMmUzMTJlMzMzOWxrQ1hra29TS1RvRzNUa25tY2tnOVBtNDVtMlhKZ29mVi9TK2V0L0padDQ9;MTcwNTQxMUAzMjMxMmUzMTJlMzMzOUFpd1dNbkpxOW1PQjdvOWI0WUEwckIvVVUyOHAwS0xRTUgybzBCdW1jOUU9"
  );
  return (
    <>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <Navbar navbarType="admin navbar" />
      <div className={classes.maincontainer}>
        <div className="mb-4">
          <Heading
            headingType="Page Headings"
            headingText={"Todays Activity Log"}
            BreadCrumHomeLink={BreadCrumHomeLink}
            BreadCrumb={BreadCrumb}
          />
        </div>

        <div className={classes.heading}>
          <div className="control-pane">
            <div className="control-section row">
              <GridComponent
                dataSource={data}
                height="500"
                toolbar={toolbarOptions}
                allowPaging={true}
                allowResizing={true}
                allowGrouping={true}
                allowSorting={true}
                allowFiltering={true}
                showColumnMenu={true}
                groupSettings={groupOptions}
                filterSettings={filterSettings}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbarClick={toolbarClick.bind(this)}
                ref={(grid) => (gridInstance = grid)}
                dataBound={dataBound.bind(this)}
                pageSettings={{ pageCount: 4, pageSizes: true }}
                autoFit={true}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="OrderId"
                    headerText="Order No"
                    width="170"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="win_num"
                    headerText="VIN No."
                    width="150"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="model_name"
                    headerText="Model"
                    width="180"
                    textAlign="Right"
                  />
                  <ColumnDirective
                    field="fueltype"
                    headerText="Fuel Type"
                    width="150"
                    textAlign="Right"
                  />
                  <ColumnDirective
                    field="variant"
                    headerText="Variant"
                    width="150"
                    textAlign="Right"
                  />
                  <ColumnDirective
                    field="colour"
                    headerText="Colour"
                    width="150"
                    textAlign="Right"
                  />
                  <ColumnDirective
                    field="carstatus"
                    headerText="Car Status"
                    width="150"
                    textAlign="Right"
                  />
                  <ColumnDirective
                    field="TransitMode"
                    headerText="Transit Mode"
                    width="150"
                    textAlign="Right"
                  />
                  <ColumnDirective
                    field="driver_name"
                    headerText="Driver Name"
                    width="150"
                    textAlign="Right"
                  />
                  <ColumnDirective
                    field="driver_num"
                    headerText="Driver No."
                    width="150"
                    textAlign="Right"
                  />
                  <ColumnDirective
                    field="truck_num"
                    headerText="Truck/Rail No."
                    width="150"
                    textAlign="Right"
                  />
                  <ColumnDirective
                    field="allocation_status"
                    headerText="Allocation Status"
                    width="150"
                    textAlign="Right"
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Resize,
                    Group,
                    Sort,
                    ColumnMenu,
                    Filter,
                    Page,
                    Toolbar,
                    ExcelExport,
                    PdfExport,
                  ]}
                />
              </GridComponent>
            </div>
          </div>
          {/* } */}
        </div>
      </div>
    </>
  );
};

export default AdminEdpActivityLog;
