import React from "react";
import { useNavigate } from "react-router-dom";

const StatsCard = (props) => {
  const navigate = useNavigate();

  const Aging = [
    {
      name: ` `,
      Count: props?.data?.allocationAgingCount
        ? props?.data?.allocationAgingCount
        : 0,
    },
  ];

  const YTD = [
    {
      name: "Booking Cancelled Yearly",
      Count: props?.data?.currentyearcancelled
        ? props?.data?.currentyearcancelled
        : 0,
      icon: "./assets/logos/year.png",
    },
  ];

  const switchHandler = (type) => {
    switch (type) {
      case "Edp Model Fuel Ui Change":
        return (
          <div>
            <dl className="grid mt-2 grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6 ">
              {props?.data?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${
                      props.className ? props.className : "border-y-zinc-800"
                    } relative overflow-hidden rounded-lg bg-white px-2 py-2  border-2  shadow-lg`}
                  >
                    <dt>
                      <div className="absolute rounded-md bg-blue-50 p-3">
                        <img
                          src={item.icon}
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16  text-sm font-medium text-zinc-800">
                        {item.model_name || item.fueltype || item.Model}
                      </p>
                    </dt>
                    <dd className="ml-16 mb-2 flex items-baseline   ">
                      <p className="text-2xl font-semibold text-zinc-800">
                        {item.count || item.Count}
                      </p>
                    </dd>
                    {/* #ViewReport */}

                    {item.Model === "Hidden Stock" ? (
                      <div className="absolute inset-x-0 bottom-0 bg-gray-100 px-4 py-1.5  ">
                        <div className="text-sm">
                          <div
                            className="font-medium cursor-pointer text-zinc-800"
                            target="_blank"
                            onClick={() => navigate(props.url)}
                          >
                            View Report
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </dl>
          </div>
        );

      case "AMCurMonthDel Ui Change":
        return (
          <div>
            <dl className="mt-2 grid grid-cols-1  gap-5 sm:grid-cols-2 lg:grid-cols-6 ">
              <div className="relative overflow-hidden rounded-lg bg-white px-2 pb-8 pt-2  border-2 border-y-zinc-800 shadow-lg">
                <dt>
                  <div className="absolute rounded-md bg-blue-50 p-3">
                    <img
                      src="./assets/logos/smallocation.png"
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>

                  <p className="ml-16  text-sm font-medium text-zinc-800">
                    {props.heading}
                  </p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-2">
                  <p className="text-2xl font-semibold text-zinc-800">
                    {props.data}
                  </p>

                  <div className="absolute inset-x-0 bottom-0 bg-gray-100 px-4 py-2  ">
                    <div className="text-sm">
                      <div
                        className="font-medium cursor-pointer text-zinc-800"
                        target="_blank"
                        onClick={() => navigate(props.url)}
                      >
                        View Report
                      </div>
                    </div>
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        );

      case "AM Users Location New Ui":
        return (
          <div className="  py-2  border rounded-2xl border-zinc-800 my-2 px-2 bg-gray-50">
            <div className="mx-auto max-w-2xl   lg:max-w-none">
              <h2 className="text-base font-bold leading-6 text-gray-900 ">
                {props.heading}
              </h2>
            </div>
            <dl className="mt-4 grid grid-cols-1 gap-1.5 overflow-hidden text-center sm:grid-cols-2 lg:grid-cols-6">
              {props?.data?.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col rounded-lg shadow-lg bg-white  p-1 "
                >
                  <dt className="text-sm font-semibold leading-6 text-gray-900">
                    {item.Model}
                  </dt>
                  <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900">
                    {item.Count}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        );
      case "EDP Total Real Time Stock ui changes":
        return (
          <div>
            <h3 className="text-base font-bold leading-6 text-gray-900 mt-2">
              Total Real Time Stock
            </h3>
            <dl className="mt-2 grid grid-cols-1  gap-5 sm:grid-cols-2 lg:grid-cols-5 ">
              {props?.data?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-8 pt-2 border-2 border-y-indigo-700 shadow-lg"
                  >
                    <dt>
                      <div className="absolute rounded-md bg-indigo-50 p-3">
                        <img
                          src={item.icon}
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16  text-sm font-medium text-gray-600">
                        {item.model_name || item.fueltype}
                      </p>
                    </dt>
                    <dd className="ml-16 flex items-baseline pb-2">
                      <p className="text-2xl font-semibold text-gray-900">
                        {item.count}
                      </p>

                      <div className="absolute inset-x-0 bottom-0 bg-gray-100 px-4 py-2  ">
                        <div className="text-sm">
                          <a
                            href={item.url}
                            target="_blank"
                            className="font-medium text-blue-600 hover:text-blue-500"
                          >
                            View
                          </a>
                        </div>
                      </div>
                    </dd>
                  </div>
                );
              })}
            </dl>
          </div>
        );

      case "YTD SRM":
        return (
          <div>
            <h3 className="text-base font-bold leading-6 text-gray-900">
              Yearly Booking Cancelled
            </h3>
            <dl className="grid mt-2 grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-5 ">
              {YTD?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-8 pt-5  border-2 border-y-blue-600 shadow-lg"
                  >
                    <dt>
                      <div className="absolute rounded-md bg-blue-50 p-3">
                        <img
                          src={item.icon}
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16  text-sm font-medium text-gray-600">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="ml-16 flex items-baseline pb-2 ">
                      <p className="text-2xl font-semibold text-gray-900">
                        {item.Count}
                      </p>

                      <div className="absolute inset-x-0 bottom-0 bg-gray-100 px-4 py-2 ">
                        <div className="text-sm">
                          <a
                            href={`/sm-dashboard-report/${item.name
                              ?.toLowerCase()
                              ?.replaceAll(" ", "-")}`}
                            target="_blank"
                            className="font-medium text-blue-600 hover:text-red-500"
                          >
                            View Report
                          </a>
                        </div>
                      </div>
                    </dd>
                  </div>
                );
              })}
            </dl>
          </div>
        );
      case "Aging":
        return (
          <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-5 lg:grid-cols-5  md:grid-cols-4 sm:grid-cols-3 cursor-pointer">
              {Aging?.map((item, index) => (
                <div
                  key={index}
                  className="relative overflow-hidden rounded-lg bg-white px-1 pt-1 pb-1 shadow sm:w-48"
                  onClick={() => props.setAgingModalShow(true)}
                >
                  <dt>
                    <p className=" ml-2 truncate text-sm font-medium text-gray-600 pb-4"></p>
                  </dt>
                  <dd className=" ml-2 flex items-baseline ">
                    <p className="text-2xl font-semibold text-gray-900">
                      {item.Count}
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        );

      default:
        return (
          <div>
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-5 ">
              {props?.data?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="relative overflow-hidden rounded-lg bg-white px-4 pb-8 pt-5  border-2 border-y-blue-600 shadow-lg"
                  >
                    <dt>
                      <div className="absolute rounded-md bg-blue-50 p-3">
                        <img
                          src={item.icon}
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16  text-sm font-medium text-gray-500">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="ml-16 flex items-baseline pb-2">
                      <p className="text-2xl font-semibold text-gray-900">
                        {item.Count}
                      </p>

                      <div className="absolute inset-x-0 bottom-0 bg-gray-100 px-4 py-2 ">
                        <div className="text-sm">
                          <a
                            href={`/sm-dashboard-report/${item.name
                              ?.toLowerCase()
                              ?.replaceAll(" ", "-")}`}
                            className="font-medium text-blue-600 hover:text-blue-500"
                            target="_blank"
                          >
                            View Report
                          </a>
                        </div>
                      </div>
                    </dd>
                  </div>
                );
              })}
            </dl>
          </div>
        );
    }
  };

  return <div>{switchHandler(props?.type)}</div>;
};
export default StatsCard;
