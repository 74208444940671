import React from "react";
import { useState } from "react";
import Pin from "../Components/OTP/Pin";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const AdminOtpVerify = () => {
  const navigate = useNavigate();

  const [otpVerification, setOtpVerification] = useState("");
  const [error, setError] = useState({});
  const [resendOtpMsg, setResendOtpMsg] = useState("");
  const [loginFailed, setLoginFailed] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const mobileNum = localStorage.getItem("verifynumber");

  const AdminLoginVerifyOtpApi = () => {
    setBtnLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobile: mobileNum,
      otp: otpVerification,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/common/AdminLoginVerifyOtp`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "Login Successfully"
        ) {
          localStorage.setItem("UserName", result.data.UserName);
          localStorage.setItem("Name", result.data.Name);
          localStorage.setItem("UserToken", result.data.Token);
          localStorage.setItem("UserRole", result.data.Role);
          localStorage.setItem("Email", result.data.Email);
          localStorage.setItem("UserLoggedin", true);
          localStorage.setItem("userdata", JSON.stringify(result.userdata));
          navigate("/creta-dashboard");
        } else if (
          result.status_code === 200 &&
          result.status === "Failed" &&
          result.message === "OTP not matched"
        ) {
          setLoginFailed("Invalid OTP Entered");
        } else if (
          result.status_code === 300 &&
          result.status === "Fail" &&
          result.Remark === "Send valid data"
        ) {
          setLoginFailed("Please enter otp");
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "Please login with a different account"
        ) {
          setLoginFailed("Invalid Credentials");
        } else {
          setLoginFailed("OOP's Technical Error. Please contact support");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setBtnLoading(false));
  };

  const ResendOTPApi = () => {
    setBtnLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobile: mobileNum,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/common/AdminLoginSendOtp`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "Otp send successfully"
        ) {
          setResendOtpMsg(`${result.message} to ${mobileNum}`);
        } else {
          setLoginFailed("OOP's Technical Error. Please contact support");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setBtnLoading(false));
  };
  const formValidation = () => {
    let errors = {};
    let isValid = true;
    if (!otpVerification || otpVerification === "") {
      errors.otpVerification = "Enter otp send to your mobile number";
      isValid = false;
    } else {
      console.log("true");
    }
    setError(errors);
    return isValid;
  };

  const getLoginLogo = () => {
    const currentHostname = window.location.hostname;

    // Define your hostnames and their corresponding logo paths
    if (currentHostname === "stockone.sashyundai.com" || currentHostname ==="stockone-git-preview-sas-strikers.vercel.app") {
      return "/assets/sas_hyundai_logo_b.svg"; // Logo for sashyundai.com
    } else if (currentHostname === "stockone.saskiaindia.com" || currentHostname ==="kia-stockone-git-preview-sas-strikers.vercel.app") {
      return "/assets/sas_kia_logo_b.svg"; // Logo for vercel.app
    } else if (currentHostname === "localhost" && window.location.port === "3000") {
      return "/assets/sas_kia_logo_b.svg"; // Logo for localhost:3000
    } else if (currentHostname === "localhost" && window.location.port === "3001") {
      return "/assets/sas_hyundai_logo_b.svg"; // Logo for localhost:3001
    } else {
      return "/assets/stockone_logo.svg";
    }
  };

  const otpSuccessMessage = () => {
    setTimeout(() => {
      setResendOtpMsg("");
    }, 4000);
    return resendOtpMsg;
  };

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 ">
      <div className="w-full  max-w-md space-y-8 border-solid border-2 w-50 rounded p-6 text-center outline-none shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src={getLoginLogo()}
            alt="StockOne"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Verify OTP
          </h2>
        </div>
        <form
          className="mt-8"
          onSubmit={(e) => {
            e.preventDefault();
            formValidation()
              ? AdminLoginVerifyOtpApi()
              : console.log("Error in otp verify");
          }}
        >
          <div>
            <label className="block text-sm   font-bold text-gray-700">
              Enter OTP sent on your mobile number
            </label>
            <div className="mt-1">
              <Pin
                inputLength={4}
                setOtpHandler={setOtpVerification}
                error={error}
              />
            </div>
          </div>

          <label className="block text-[12px]  mt-6 text-end cursor-pointer font-bold text-gray-700">
            <a
              className="hover:underline  hover:text-blue-600"
              onClick={() => ResendOTPApi()}
            >
              Resend OTP
            </a>
          </label>

          <label className="block text-[12px]   text-end cursor-pointer font-bold text-gray-700">
            <a className="hover:underline  hover:text-blue-600">
              {otpSuccessMessage()}
            </a>
          </label>

          {loginFailed && (
            <div
              className="p-2 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
              role="alert"
            >
              <span className="font-medium"> {loginFailed}</span>
            </div>
          )}

          <button
            type="submit"
            className="group relative flex w-full justify-center mt-8 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {btnLoading ? (
              <ThreeDots
                height="20"
                width="40"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
              />
            ) : (
              <span>Verify</span>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminOtpVerify;
