import React, { useState, Suspense, useEffect } from "react";
import classes from "../../module.css/Dashboard.module.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../../tailwindUI/Navbar";
import CBTDashboarCard from "../../Components/CBTDashboarCard";
const Heading = React.lazy(() => import("../../tailwindUI/Heading"));

const CBTDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState({});
  const UserToken = localStorage.getItem("UserToken");
  const navigate = useNavigate();
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    countApi();
  }, []);
  const countApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/DailyStockStatusCount`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data === "You dont have permission to access data" &&
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "Data Found Successfully"
        ) {
          setData(result);
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Suspense fallback={<div className="text-center">Loading...</div>}>
      <Navbar navbarType={"CBT"} />
      {loading ? (
        <div className={classes.loader}>
          {" "}
          <img src="assets/loadingGif.gif" alt="loading" />
        </div>
      ) : (
        <div className="mb-16">
          <div className={classes.heading}>
            <Heading
              headingType={"Section Headings"}
              BreadCrumHomeLink={"/cbt-dashboard"}
              headingText={"CBT Dashboard"}
              subheadingText={""}
            />
          </div>
          <div className="px-4  lg:px-28 ">
            {" "}
            {loading ? (
              <div className={"w-full bg-white  "}>
                <img
                  className="m-auto"
                  src="../assets/loadingGif.gif"
                  alt="loading"
                />
              </div>
            ) : (
              <CBTDashboarCard data={data} />
            )}
          </div>
        </div>
      )}
    </Suspense>
  );
};

export default CBTDashboard;
