import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataNotFound from "../../Components/DataNotFound";
import FollowUpRemarks from "../../Components/FollowUpRemarks";
import classes from "../../module.css/StmBookingCancellationByShowroomManager.module.css";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import Heading from "../../tailwindUI/Heading";
import Navbar from "../../tailwindUI/Navbar";
import Pagination from "../../tailwindUI/Pagination";
import StackedList from "../../tailwindUI/StackedList";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import TableHeader from "../../Components/TableHeader";
import AddRemarkInput from "../../Components/AddRemarkInput";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
import CBTTAbleHeader from "../../Components/CBTTAbleHeader";
const BookingCancellation = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [dataCount, setDataCount] = useState("");
  const [followUpModal, setFollowUpModal] = useState(false);
  const [viewRemark, setViewRemark] = useState(false);
  const [detail, setDetail] = useState({});

  const [carDatail, setCarDetail] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [viewText, setViewText] = useState(false);
  const [error, setError] = useState({});
  const [addRemark, setAddRemark] = useState("");
  const [allExcelData, setAllExcelData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;
  const maxPages = Math.ceil(dataCount / 20);

  useEffect(() => {
    ShowroomBookingcancledAPi();
  }, [searchTerm === "", page]);
  useEffect(() => {
    ShowroomBookingcancledAPi();
  }, [startDate, endDate]);
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  const ShowroomBookingcancledAPi = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: localStorage.getItem("UserToken"),
      page: page,
      SearchTerm: searchTerm,
      StartDate: startDate,
      EndDate: endDate,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/ShowroomBookingCancelledNotConfirmCBT`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 200 && result.status === "Success") {
          setData(result.cancleddata);
          setDataCount(result.datacount);
          setAllExcelData(result.cancleddata);
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };
  const followUpHandler = (carDetail) => {
    setFollowUpModal(true);
    setCarDetail(carDetail);
  };

  const formValidation = () => {
    let errors = {};
    let isValid = true;

    if (!addRemark || addRemark === "") {
      errors.addRemark = "Please Enter Remark First";
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  const SuccessApi = () => {
    if (formValidation()) {
      setIsLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", global.authToken);

      const formdata = new FormData();
      formdata.append("UserToken", localStorage.getItem("UserToken"));
      formdata.append("BookingID", carDatail);
      formdata.append("CBTRemark", addRemark);
      formdata.append("MoveToConfirmCancel", "1");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `${global.apiBaseUrl}/api/v1/stockone/am/MoveToConfirmCancelledAndCBTRemark`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (
            result.status_code === 200 &&
            result.status === "Success" &&
            result.message === "data updated successfully"
          ) {
            setViewText(true);
            setTimeout(() => {
              setFollowUpModal(false);
              ShowroomBookingcancledAPi();
            }, 2000);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const RemarkHandler = (data) => {
    setViewRemark(true);
    setDetail(data);
  };

  return (
    <>
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
          apiFailled={apiFailled}
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {viewRemark && (
        <FollowUpRemarks
          bookingData={detail}
          setOpen={setViewRemark}
          open={viewRemark}
        />
      )}
      {followUpModal && (
        <AddRemarkInput
          open={followUpModal}
          setOpen={setFollowUpModal}
          value={addRemark}
          onChange={(e) => {
            setAddRemark(e.target.value);
            error.addRemark = "";
          }}
          error={error.addRemark}
          onClose={() => setFollowUpModal(false)}
          onClick={SuccessApi}
          loading={isLoading}
          success={viewText}
        />
      )}
      <Navbar navbarType={"CBT"} />
      <div className={classes.maincontainer}>
        <Heading
          headingType={"Page Headings"}
          headingText={"Booking Cancellation By Showroom Manager"}
        />
        <div className={classes.background}>
          <CBTTAbleHeader
            buttonText="Search"
            tableHeading="Search Criteria"
            subHeading="subHeading"
            totalResult={dataCount}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            pageResult={data.length}
            placeholderType="Enter Model,Variant,Color,Outlet (For Example:-Grand,i10,Fiery red,Telibagh)"
            apicall={ShowroomBookingcancledAPi}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
          <div>
            <div className="my-2 text-end">
              <ExcelDownload
                csvData={allExcelData}
                fileName="Booking Cancellation List"
              />
            </div>
          </div>
          {loading ? (
            <div className={"w-full bg-white  "}>
              <img
                className="m-auto"
                src="../assets/loadingGif.gif"
                alt="loading"
              />
            </div>
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  {data?.map((item) => {
                    return (
                      <div className={classes.list}>
                        <div className={classes.stackListContainer}>
                          <div role="list" className={classes.stackListWrapper}>
                            <StackedList
                              type="Stm-booking-cancellation-by-showroom-manager"
                              data={item}
                              followUpHandler={followUpHandler}
                              setCustomerRemark={setAddRemark}
                              buttonHide={"Hide"}
                              RemarkHandler={RemarkHandler}
                              Type="CBT"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <DataNotFound />
              )}
            </>
          )}

          <div className="bg-white">
            {maxPages > 1 && <Pagination maxPages={maxPages} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingCancellation;
