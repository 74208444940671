import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataNotFound from "../../Components/DataNotFound";
import classes from "../../module.css/StmAutoSuggestOrders.module.css";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import Heading from "../../tailwindUI/Heading";
import Navbar from "../../tailwindUI/Navbar";
import Pagination from "../../tailwindUI/Pagination";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import Table from "../../tailwindUI/Table";
import ExcelDownload from "../../Components/ExcelDownload/ExcelDownload";
const StmAutoSuggestOrders = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;
  const maxPages = Math.ceil(dataCount / 20);

  useEffect(() => {
    OutofStockData();
  }, []);

  const OutofStockData = () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: localStorage.getItem("UserToken"),
      page: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/OutofStockData`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "data found successfully"
        ) {
          setData(result.allocation);
          setDataCount(result.datacount);
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };
  const BreadCrumb = {
    pagename: "Auto Suggest Orders",
    pagePath: "/stm-auto-suggest-orders",
    pagename1: "",
    pagePath1: "",
  };

  const columns = ["Model", "Variant", "Fuel Type", "Color", "Quantity"];

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };

  return (
    <>
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
          apiFailled={apiFailled}
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <Navbar navbarType={"black navbar"} />
      <div className={classes.maincontainer}>
        <Heading
          headingType={"Page Headings"}
          headingText={"Auto Suggest Orders"}
          // subheadingText={localStorage.getItem("Location")}
          BreadCrumb={BreadCrumb}
          BreadCrumHomeLink={"/stm-dashboard"}
        />
        <div className={classes.background}>
          {loading ? (
            <div className={"w-full bg-white  "}>
              <img
                className="m-auto"
                src="../assets/loadingGif.gif"
                alt="loading"
              />
            </div>
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  <div className="my-2 text-end">
                    <ExcelDownload
                      csvData={data}
                      fileName="Auto Suggest Orders"
                    />
                  </div>
                  <Table
                    type="Allocate Auto Suggest Orders"
                    columnList={columns}
                    data={data}
                  />
                </>
              ) : (
                <DataNotFound />
              )}
            </>
          )}
          <div className="bg-white">
            {maxPages > 1 && <Pagination maxPages={maxPages} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default StmAutoSuggestOrders;
