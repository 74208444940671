import React, { useState, useEffect } from "react";
import SmWrapper from "../../Components/SmWrapper";
import classes from "../../module.css/NewBooking.module.css";
import Heading from "../../tailwindUI/Heading";
import Input from "../../tailwindUI/Input";
import Button from "../../tailwindUI/Button";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import { useNavigate } from "react-router-dom";

const todayDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = yyyy + "-" + mm + "-" + dd;
  return formattedToday;
};

const NewBooking = () => {
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [mobile, setMobile] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [committedDate, setCommittedDate] = useState("");
  const [bookingAmount, setBookingAmount] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [uploadReceipt, setUploadReceipt] = useState("");
  const [yom, setYom] = useState(new Date().getFullYear());

  const [filteredList1, setFilteredList1] = useState([]);
  const [filteredList2, setFilteredList2] = useState([]);
  const [selectedModel1, setSelectedModel1] = useState("");
  const [selectedVariant1, setSelectedVariant1] = useState("");
  const [selectedColor1, setSelectedColor1] = useState("");

  const [selectedModel2, setSelectedModel2] = useState("");
  const [selectedVariant2, setSelectedVariant2] = useState("");
  const [selectedColor2, setSelectedColor2] = useState("");

  const [asmList, setAsmList] = useState([]);
  const [selectedAsm, setSelectedAsm] = useState("");
  const [executiveList, setExecutiveList] = useState([]);
  const [selectExecutive, setSelectExecutive] = useState("");
  // const [selectedStatus, setSelectedStatus] = useState("Allocation Awaited");
  const [exchange, setExchange] = useState("No");
  const [remark, setRemark] = useState("");
  const [followup1, setFollowup1] = useState("");
  const [followup2, setFollowup2] = useState("");
  const [followup3, setFollowup3] = useState("");
  const [checkedPriority, setCheckedPriority] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wings, setWings] = useState("");

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");

  const UserToken = localStorage.getItem("UserToken");
  const checkedPriorityInString = checkedPriority
    .map((item) => item.name)
    .toString();
  const navigate = useNavigate();
  useEffect(
    () => FilterList(selectedModel1, setFilteredList1),
    [selectedModel1]
  );
  useEffect(
    () => FilterList(selectedModel2, setFilteredList2),
    [selectedModel2]
  );

  useEffect(() => {
    asmListApi();
    executiveListApi();
  }, [selectedAsm]);

  const bookingPriorityList = [
    {
      name: "Full Payment",
    },

    {
      name: "Special Occasion",
    },

    {
      name: "Committed Date",
    },
    {
      name: "Owner Reference",
    },
    {
      name: "Other Reference",
    },
  ];

  const priorityCheckboxHandler = (option) => {
    const isChecked = checkedPriority.some((item) => item.name === option.name);
    if (isChecked) {
      setCheckedPriority(
        checkedPriority.filter((item) => item.name !== option.name)
      );
    } else {
      setCheckedPriority(checkedPriority.concat(option));
    }
  };
  const notificationMethods = [
    { id: "Yes", title: "Yes" },
    { id: "No", title: "No" },
  ];

  const onlyNumeric = (e, setState) => {
    var reg = /^-?\d+$/;
    reg.test(e.target.value) || e.target.value === ""
      ? setState(e.target.value)
      : console.log("");
  };

  const validYear = (date) => {
    return date.split("-")[0];
  };

  const validation = () => {
    var commitedDate = new Date(committedDate);
    var bookingdate = new Date(bookingDate);
    let regPhone = /^[6789][0-9]{9}$/;
    let yomregex = /^[0-9\b]+$/;
    let receiptregex =
      /^[a-zA-Z0-9]+\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/;
    let receiptnewregex = /^[a-zA-Z]{2}\d{10}$/;
    let isValid = true;
    let error = {};
    if (fName.trim() === "") {
      isValid = false;
      error.fName = "Please enter first name";
    }
    if (lName.trim() === "") {
      isValid = false;
      error.lName = "Please enter last name";
    }
    if (!regPhone.test(mobile)) {
      isValid = false;
      error.mobile = "Invalid mobile number";
    }
    if (mobile.trim() === "") {
      isValid = false;
      error.mobile = "Please enter mobile number";
    }
    if (bookingAmount.trim() === "") {
      isValid = false;
      error.bookingAmount = "Please enter booking amount";
    }
    if (bookingDate === "") {
      isValid = false;
      error.bookingDate = "Please select booking date";
    }

    if (
      parseInt(validYear(bookingDate)) < 2016 ||
      parseInt(validYear(bookingDate)) > 2099
    ) {
      isValid = false;
      error.bookingDate = "Enter valid date";
    }

    if (committedDate === "") {
      isValid = false;
      error.committedDate = "Please select committed date";
    }

    if (
      parseInt(validYear(committedDate)) < 2016 ||
      parseInt(validYear(committedDate)) > 2099
    ) {
      isValid = false;
      error.committedDate = "Enter valid date";
    }
    if (commitedDate < bookingdate) {
      error.committedDate = "Please enter valid date";
      isValid = false;
    }

    if (yom === "") {
      isValid = false;
      error.yom = "Please enter YOM";
    } else if (!yomregex.test(yom)) {
      isValid = false;
      error.yom = "Please enter correct year of manufacturing";
    } else if (yom > new Date().getFullYear() + 1) {
      isValid = false;
      error.yom = "Year of manufacture should be valid";
    }

    if (receiptNo.trim() === "") {
      isValid = false;
      error.receiptNo = "Please enter receipt number";
    } else if (
      !receiptnewregex.test(receiptNo) &&
      !receiptregex.test(receiptNo)
    ) {
      isValid = false;
      error.receiptNo = "Please enter receipt number in correct format";
    }

    // if (uploadReceipt === "") {
    //   isValid = false;
    //   error.uploadReceipt = "Please upload receipt";
    // }

    if (selectedModel1 === "") {
      isValid = false;
      error.selectedModel1 = "Please select car model";
    }

    if (selectedVariant1 === "") {
      isValid = false;
      error.selectedVariant1 = "Please select car variant";
      // window.scrollTo(0, 600);
    }

    if (selectedColor1 === "") {
      isValid = false;
      error.selectedColor1 = "Please select car color";
      // window.scrollTo(0, 600);
    }

    if (selectedVariant2 === "" && selectedModel2 !== "") {
      isValid = false;
      error.selectedVariant2 = "Please select car variant";
      // window.scrollTo(0, 600);
    }

    if (selectedColor2 === "" && selectedModel2 !== "") {
      isValid = false;
      error.selectedColor2 = "Please select car color";
      // window.scrollTo(0, 600);
    }

    if (
      selectedModel1 === selectedModel2 &&
      selectedVariant1 === selectedVariant2 &&
      selectedColor1 === selectedColor2 &&
      selectedModel1 !== "" &&
      selectedColor2 !== "" &&
      selectedVariant2 !== ""
    ) {
      isValid = false;
      error.prefrence2 = "Prefrence1 and Prefrence2 can not be same";
    }

    // if (wings === "") {
    //   isValid = false;
    //   error.wings = "Please enter wings id";

    // }
    if (selectedAsm === "") {
      isValid = false;
      error.selectedAsm = "Please select ASM";
      // window.scrollTo(0, 900);
    }
    if (selectExecutive === "") {
      isValid = false;
      error.selectExecutive = "Please select executive";
      // window.scrollTo(0, 900);
    }
    // if (selectedStatus === "") {
    //   isValid = false;
    //   error.selectedStatus = "Please select status";
    //   // window.scrollTo(0, 900);
    // }
    // if (statusDate === "") {
    //   isValid = false;
    //   error.statusDate = "Please select status date";
    //   // window.scrollTo(0, 900);
    // }

    if (exchange === "") {
      isValid = false;
      error.exchange = "Please select exchange";
    }
    setErrors(error);
    return isValid;
  };
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };
  const FilterList = (model, setFilteredList) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Model: model,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/common/GetFilterList`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setFilteredList(result.data);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };

  const asmListApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${global.apiBaseUrl}/api/v1/stockone/srm/SRMASMList`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (
          result.status === "Success" &&
          result.status_code === 200 &&
          result.message === "Data Found Successfully"
        ) {
          setAsmList(result.data);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };

  const executiveListApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      AsmId: selectedAsm,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/srm/SRMExicutivesList`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (
          result.status === "Success" &&
          result.status_code === 200 &&
          result.message === "Data Found Successfully"
        ) {
          setExecutiveList(result.data);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };

  const formSubmitHandler = () => {
    if (validation()) {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", global.authToken);

      var formdata = new FormData();
      formdata.append("UserToken", UserToken);
      formdata.append("FirstName", fName);
      formdata.append("LastName", lName);
      formdata.append("MobileNumber", mobile);
      formdata.append("Model1", selectedModel1);
      formdata.append("Model2", selectedModel2);
      formdata.append("Variant1", selectedVariant1);
      formdata.append("Variant2", selectedVariant2);
      formdata.append("Colour1", selectedColor1);
      formdata.append("Colour2", selectedColor2);
      formdata.append("BookingAmount", bookingAmount);
      formdata.append("YOM", yom);
      formdata.append("DateOfBooking", bookingDate);
      formdata.append("CommittedDeliveryDate", committedDate);
      formdata.append("BookingPriority", checkedPriorityInString);
      formdata.append("BookingPriorityResoan", "");
      // formdata.append("Status", selectedStatus);
      formdata.append("UploadReceipt", uploadReceipt);
      formdata.append("ReceiptNo", receiptNo);
      formdata.append("WingsId", wings);
      formdata.append("Exchange", exchange);
      formdata.append("ASM", selectedAsm);
      formdata.append("Executive", selectExecutive);
      // formdata.append("StatusDate", statusDate);
      // formdata.append("VinNo", vinNumber);
      formdata.append("Remark", remark);
      formdata.append("Followup1", followup1);
      formdata.append("Followup2", followup2);
      formdata.append("Followup3", followup3);
      // formdata.append("WingsId", "123456");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `${global.apiBaseUrl}/api/v1/stockone/srm/SRMBookingAdd`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status_code === 200 && result.status === "Success") {
            setMessage("Booking Added Successfully");
            setFname("");
            setLname("");
            setMobile("");
            setWings("");
            setBookingDate("");
            setCommittedDate("");
            setCheckedPriority([]);
            setSelectedModel1("");
            setSelectedVariant1("");
            setSelectedColor1("");
            setSelectedModel2("");
            setSelectedVariant2("");
            setSelectedColor2("");
            setBookingAmount("");
            setYom("");
            // setSelectedStatus("");
            setUploadReceipt(null);
            setReceiptNo("");
            setExchange("");
            setSelectedAsm("");
            setSelectExecutive("");
            // setStatusDate("");
            // setVinNumber("");
            setRemark("");
            setFollowup1("");
            setFollowup2("");
            setFollowup3("");
          } else if (result.status_code === 400) {
            setErrorMessage("Something went wrong please contact support !");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setErrorMessage("Something went wrong please contact support !");
        })
        .finally(() => setLoading(false));
    }
  };

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
      navigate("/sm-search-update");
    }, 2000);
    return message;
  };
  const BreadCrumb = {
    pagename: "New booking",
    pagePath: "/sm-new-booking",
  };
  return (
    <SmWrapper>
      {message && <SuccessMessage message={successMessage()} />}
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <Heading
        headingType={"Page Headings small"}
        BreadCrumHomeLink={"/sm-dashboard"}
        headingText={"New Booking"}
        BreadCrumb={BreadCrumb}
        subheadingText={localStorage.getItem("Location")}
      />

      <div className={classes.mainContainer}>
        <div className={classes.searchContainer}>
          <div className={classes.headingFormContainer}>
            <Heading
              headingType={"sub heading small"}
              headingText={"Customer Details"}
            />

            <div className={classes.inputRow}>
              <div>
                <Input
                  type={"input with label low height"}
                  inputType={"text"}
                  label={"First Name *"}
                  onChange={(e) => {
                    setFname(e.target.value);
                    errors.fName = "";
                  }}
                  value={fName}
                  error={errors.fName}
                />
                <span className={classes.error}>{errors.fName}</span>
              </div>
              <div>
                <Input
                  type={"input with label low height"}
                  inputType={"text"}
                  label={"Last Name *"}
                  onChange={(e) => {
                    setLname(e.target.value);
                    errors.lName = "";
                  }}
                  value={lName}
                  error={errors.lName}
                />
                <span className={classes.error}>{errors.lName}</span>
              </div>
              <div>
                <Input
                  type={"input with label low height"}
                  inputType={"text"}
                  maxLength={10}
                  label={"Mobile Number *"}
                  onChange={(e) => {
                    onlyNumeric(e, setMobile);
                    errors.mobile = "";
                  }}
                  value={mobile}
                  error={errors.mobile}
                />
                <span className={classes.error}>{errors.mobile}</span>
              </div>

              <div>
                <Input
                  type={"input with label low height"}
                  inputType={"text"}
                  label={"Booking Amount *"}
                  value={bookingAmount}
                  onChange={(e) => {
                    onlyNumeric(e, setBookingAmount);
                    errors.bookingAmount = "";
                  }}
                  error={errors.bookingAmount}
                />
                <span className={classes.error}>{errors.bookingAmount}</span>
              </div>

              <div>
                <Input
                  type={"Select Menus Simple native low height"}
                  label={"ASM *"}
                  dropdownType={"asmNew"}
                  asmData={asmList}
                  selected={selectedAsm === ""}
                  onChange={(e) => {
                    setSelectedAsm(e.target.value);
                    errors.selectedAsm = "";
                  }}
                  error={errors.selectedAsm}
                />
                <span className={classes.error}>{errors.selectedAsm}</span>
              </div>
              <div>
                <Input
                  type={"Select Menus Simple native low height"}
                  label={"Executive *"}
                  dropdownType={"newExecutive"}
                  executiveData={executiveList}
                  selected={selectExecutive === ""}
                  onChange={(e) => {
                    setSelectExecutive(e.target.value);
                    errors.selectExecutive = "";
                  }}
                  error={errors.selectExecutive}
                />
                <span className={classes.error}>{errors.selectExecutive}</span>
              </div>
            </div>
            <div className={classes.inputRow}>
              <div>
                <Input
                  type={"input with label low height"}
                  inputType={"date"}
                  label={"Date of Booking *"}
                  onChange={(e) => {
                    setBookingDate(e.target.value);
                    errors.bookingDate = "";
                  }}
                  value={bookingDate}
                  error={errors.bookingDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
                <span className={classes.error}>{errors.bookingDate}</span>
              </div>

              <div>
                <Input
                  type={"input with label low height"}
                  inputType={"date"}
                  label={"Commited Delivery Date*"}
                  onChange={(e) => {
                    setCommittedDate(e.target.value);
                    errors.committedDate = "";
                  }}
                  value={committedDate}
                  error={errors.committedDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
                <span className={classes.error}>{errors.committedDate}</span>
              </div>
              <div>
                <Input
                  type={"input with label low height"}
                  inputType={"text"}
                  label={"WINGS ID"}
                  onChange={(e) => {
                    setWings(e.target.value);
                    errors.wings = "";
                  }}
                  value={wings}
                  error={errors.wings}
                />
                <span className={classes.error}>{errors.wings}</span>
              </div>
              <div>
                <div className="block text-sm font-medium text-gray-700">
                  Booking Priority
                </div>
                <Input
                  type={"Select Menus with checkbox"}
                  label={"Booking Priority"}
                  data={bookingPriorityList}
                  priorityCheckboxHandler={priorityCheckboxHandler}
                  checkedPriority={checkedPriority}
                  // error={errors.checkedPriority}
                  // allError={errors}
                />
                {/* <span className={classes.error}>{errors.checkedPriority}</span> */}
              </div>
              <div>
                <Input
                  type={"input with label low height"}
                  inputType={"text"}
                  label={"Receipt No *"}
                  value={receiptNo}
                  placeholder=""
                  onChange={(e) => {
                    setReceiptNo(e.target.value);
                    errors.receiptNo = "";
                  }}
                  error={errors.receiptNo}
                />
                <span className={classes.error}>{errors.receiptNo}</span>
              </div>
              <div>
                <Input
                  type={"input with label low height type file"}
                  inputType={"file"}
                  accept={"image/png, image/gif, image/jpeg"}
                  label={"Upload Receipt"}
                  onChange={(e) => {
                    setUploadReceipt(e.target.files[0]);
                    errors.uploadReceipt = "";
                  }}
                  error={errors.uploadReceipt}
                />
                <span className={classes.error}>{errors.uploadReceipt}</span>
              </div>
            </div>
          </div>
          <div className={classes.headingFormContainer}>
            <Heading
              headingType={"sub heading small"}
              headingText={"Vehicle preference"}
            />
            <div>
              <div className={classes.preferenceWrapper}>
                <div>
                  <div className={classes.preference}>Preference 1</div>
                  <div className={`${classes.preferenceRow}`}>
                    <div>
                      <Input
                        type={"Select Menus Simple native low height"}
                        label={"Model *"}
                        dropdownType={"Model"}
                        filteredData={filteredList1}
                        selected={selectedModel1 === ""}
                        model={selectedModel1}
                        onChange={(e) => {
                          setSelectedModel1(e.target.value);
                          errors.selectedModel1 = "";
                        }}
                        error={errors.selectedModel1}
                      />
                      <span className={classes.error}>
                        {errors.selectedModel1}
                      </span>
                    </div>
                    <div>
                      <Input
                        type={"Select Menus Simple native low height"}
                        label={"Variant *"}
                        dropdownType={"Variant"}
                        selected={selectedVariant1 === ""}
                        filteredData={filteredList1}
                        onChange={(e) => {
                          setSelectedVariant1(e.target.value);
                          errors.selectedVariant1 = "";
                        }}
                        error={errors.selectedVariant1}
                      />
                      <span className={classes.error}>
                        {errors.selectedVariant1}
                      </span>
                    </div>
                    <div>
                      <Input
                        type={"Select Menus Simple native low height"}
                        label={"Color *"}
                        dropdownType={"Color"}
                        filteredData={filteredList1}
                        selected={selectedColor1 === ""}
                        onChange={(e) => {
                          setSelectedColor1(e.target.value);
                          errors.selectedColor1 = "";
                        }}
                        error={errors.selectedColor1}
                      />
                      <span className={classes.error}>
                        {errors.selectedColor1}
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <div className={classes.preference}>Preference 2</div>

                  <div className={`${classes.preferenceRow} `}>
                    <div>
                      <Input
                        type={"Select Menus Simple native low height"}
                        label={"Model"}
                        dropdownType={"Model"}
                        filteredData={filteredList2}
                        selected={selectedModel2 === ""}
                        onChange={(e) => {
                          setSelectedModel2(e.target.value);
                          errors.prefrence2 = "";
                        }}
                      />
                    </div>

                    <div>
                      <Input
                        type={"Select Menus Simple native low height"}
                        label={"Variant"}
                        dropdownType={"Variant"}
                        selected={selectedVariant2 === ""}
                        filteredData={filteredList2}
                        onChange={(e) => {
                          setSelectedVariant2(e.target.value);
                          errors.selectedVariant2 = "";
                          errors.prefrence2 = "";
                        }}
                        error={errors.selectedVariant2}
                      />
                      <span className={classes.error}>
                        {errors.selectedVariant2}
                      </span>
                    </div>
                    <div>
                      <Input
                        type={"Select Menus Simple native low height"}
                        label={"Color"}
                        dropdownType={"Color"}
                        filteredData={filteredList2}
                        selected={selectedColor2 === ""}
                        onChange={(e) => {
                          setSelectedColor2(e.target.value);
                          errors.selectedColor2 = "";
                          errors.prefrence2 = "";
                        }}
                        error={errors.selectedColor2}
                      />
                      <span className={classes.error}>
                        {errors.selectedColor2}
                      </span>
                    </div>
                  </div>
                  <span className={classes.error}>{errors.prefrence2}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Heading
              headingType={"sub heading small"}
              headingText={"Other Details"}
            />
            <div className={classes.inputRow}>
              <div>
                <Input
                  type={"input with label low YOM"}
                  inputType={"text"}
                  label={"YOM *"}
                  maxLength={4}
                  onChange={(e) => {
                    onlyNumeric(e, setYom);
                    errors.yom = "";
                  }}
                  value={yom}
                  error={errors.yom}
                />
                <span className={classes.error}>{errors.yom}</span>
              </div>

              <div className={classes.radiobuttonWrapper}>
                <div>
                  <Input
                    type={"radio group"}
                    label="Exchange *"
                    value={exchange}
                    setState={setExchange}
                    data={notificationMethods}
                    onChange={() => (errors.exchange = "")}
                  />

                  <span className={classes.error}>{errors.exchange}</span>
                </div>
              </div>

              <div className={`${classes.inputWrapper} col-span-4`}>
                <div>
                  <Input
                    type={"textarea"}
                    inputType={"text"}
                    rows={1}
                    label={"Remark "}
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                    value={remark}
                  />
                </div>
              </div>
              {/* <div>
                <Input
                  type={"Select Menus Simple native low height"}
                  label={"Status *"}
                  dropdownType={"status"}
                  status={selectedStatus}
                  error={errors.selectedStatus}
                />
                <span className={classes.error}>{errors.selectedStatus}</span>
              </div> */}
              {/* <div>
                <Input
                  type={"input with label low height"}
                  inputType={"date"}
                  label={"Status Date *"}
                  disabled={true}
                  // onChange={(e) => {
                  //   console.log(e.target.value);
                  //   errors.statusDate = "";
                  // }}
                  value={statusDate}
                  error={errors.statusDate}
                />
                <span className={classes.error}>{errors.statusDate}</span>
              </div> */}

              {/* <div>
                <Input
                  type={"input with label low height"}
                  inputType={"text"}
                  label={"VIN No "}
                  disabled={true}
                  onChange={(e) => {
                    setVinNumber(e.target.value);
                  }}
                  value={vinNumber}
                />
              </div> */}
            </div>

            <div className={classes.rigthSide}>
              <div className={classes.inputWrapper}>
                <Input
                  type={"textarea"}
                  inputType={"text"}
                  rows={1}
                  label={"Follow Up 1"}
                  onChange={(e) => setFollowup1(e.target.value)}
                  value={followup1}
                />
              </div>
              <div className={classes.inputWrapper}>
                <Input
                  type={"textarea"}
                  inputType={"text"}
                  rows={1}
                  label={"Follow Up 2"}
                  onChange={(e) => setFollowup2(e.target.value)}
                  value={followup2}
                />
              </div>
              <div className={classes.inputWrapper}>
                <Input
                  type={"textarea"}
                  inputType={"text"}
                  rows={1}
                  label={"Follow Up 3"}
                  onChange={(e) => setFollowup3(e.target.value)}
                  value={followup3}
                />
              </div>
            </div>
            <div className={classes.submitButtonWrapper}>
              <Button
                type={"whiteButton"}
                buttonText={"Cancel"}
                onClick={() => navigate("/sm-dashboard")}
              />
              <div className={classes.submitButton}>
                <Button
                  type={"smallButton"}
                  buttonText={"Save"}
                  onClick={() => {
                    formSubmitHandler();
                  }}
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SmWrapper>
  );
};

export default NewBooking;
