import React from "react";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Listbox, Transition, Popover } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import classes from "../module.css/NewBooking.module.css";
const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const getLoginLogo = () => {
  const currentHostname = window.location.hostname;

  // Define your hostnames and their corresponding logo paths
  if (currentHostname === "stockone.sashyundai.com" || currentHostname ==="stockone-git-preview-sas-strikers.vercel.app") {
    return "/assets/sas_hyundai_logo_b.svg"; // Logo for sashyundai.com
  } else if (currentHostname === "stockone.saskiaindia.com" || currentHostname ==="kia-stockone-git-preview-sas-strikers.vercel.app") {
    return "/assets/sas_kia_logo_b.svg"; // Logo for vercel.app
  } else if (currentHostname === "localhost" && window.location.port === "3000") {
    return "/assets/sas_kia_logo_b.svg"; // Logo for localhost:3000
  } else if (currentHostname === "localhost" && window.location.port === "3001") {
    return "/assets/sas_hyundai_logo_b.svg"; // Logo for localhost:3001
  } else {
    return "/assets/stockone_logo.svg";
  }
};

const Input = (props) => {
  // const [selected, setSelected] = useState(people[3]);
  let navigate = useNavigate();
  const DropdowonOptions = (open) => {
    switch (props.dropdownType) {
      case "cancelreason":
        return (
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
              <span className="block truncate">
                {props.selected?.name ? props.selected.name : "Select reason"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-20 mt-1 h-40 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {props.cancelReasons?.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {person.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        );
      case "models":
        return (
          <div className="relative mt-1">
            <Listbox.Button
              className={`relative w-full cursor-default rounded-md border  py-2 pl-3 pr-10 text-left shadow-sm  focus:outline-none focus:ring-1  sm:text-sm ${props.error
                ? "border-red-500 focus:ring-orange-500 bg-white"
                : "focus:border-indigo-500 focus:ring-indigo-500 bg-white"
                }`}
            >
              <span className="block truncate">
                {props.value?.length ? props.value : "Select model"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-20 mt-1 h-40 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {props.models?.map((person) => (
                  <Listbox.Option
                    key={person}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {person}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        );
      default:
        break;
    }
  };
  const SimpledropDown = () => {
    switch (props.dropdownType) {
      case "AsmList":
        return (
          <>
            <option value={""}>
              {props.selected ? props.selected : "Select ASM"}
            </option>
            {props.asmData &&
              props.asmData?.map((item) => {
                if (props.selected !== item.AsmName) {
                  return <option value={item.AsmID}>{item.AsmName}</option>;
                }
              })}
          </>
        );
      case "asmNew":
        return (
          <>
            <option value={""} disabled selected={props.selected}>
              {props.selected ? props.selected : "Select ASM"}
            </option>
            {props.asmData &&
              props.asmData?.map((item) => {
                return <option value={item.AsmID}>{item.AsmName}</option>;
              })}
          </>
        );
      case "status":
        return <option value={props.status}>{props.status}</option>;

      case "ExecutiveList":
        return (
          <>
            <option value={""}>
              {props.selected ? props.selected : "Select Executive"}
            </option>
            {props.executiveData &&
              props.executiveData?.map((item) => {
                if (props.selected !== item.user_name) {
                  return (
                    <option value={item.ExecutiveId}>{item.user_name}</option>
                  );
                }
              })}
          </>
        );
      case "newExecutive":
        return (
          <>
            <option value={""} disabled selected={props.selected}>
              Select Executive
            </option>
            {props.executiveData &&
              props.executiveData?.map((item) => {
                return (
                  <option value={item.ExecutiveId}>{item.user_name}</option>
                );
              })}
          </>
        );
      case "fuel type":
        return (
          <>
            <option hidden>Select Fuel Type</option>
            {props.filteredData?.FuelType?.map((item) => {
              return <option>{item}</option>;
            })}
          </>
        );
      case "Variant":
        return (
          <>
            <option value={""} hidden selected={props.selected}>
              Select Variant
            </option>
            {props.filteredData?.Variant?.map((item) => {
              return <option>{item}</option>;
            })}
          </>
        );
      case "Color":
        return (
          <>
            <option value={""} hidden selected={props.selected}>
              Select color
            </option>
            {props.filteredData?.Colour?.map((item) => {
              return <option>{item}</option>;
            })}
          </>
        );
      case "cancelreason":
        return (
          <>
            <option value={""} hidden>
              Select reason
            </option>
            {props.data.map((item) => {
              return <option value={item.name}>{item.name}</option>;
            })}
          </>
        );
      case "Stock Status":
        return (
          <>
            <option hidden>Select Stock Status</option>
            {props.data?.map((item) => {
              return <option>{item}</option>;
            })}
          </>
        );
      case "Number Of Vehicles":
        return (
          <>
            <option hidden>Select Number Of Vehicles</option>
            {props.data?.map((item) => {
              return <option>{item}</option>;
            })}
          </>
        );
      case "Model":
        return (
          <>
            <option value="" hidden selected={props.selected}>
              Select model
            </option>
            {props.filteredData?.Model?.map((item) => {
              return <option>{item}</option>;
            })}
          </>
        );
      case "status":
        return (
          <>
            <option value="">Select status</option>
            {props.data.map((item) => {
              return <option>{item.status}</option>;
            })}
          </>
        );
      case "bookingPriority":
        return (
          <>
            <option value="">Select booking priority</option>
            {props.data.map((item) => {
              return <option>{item}</option>;
            })}
          </>
        );
      case "location":
        return (
          <>
            <option value="" selected={props.selected}>
              Select location
            </option>
            {props.data.map((item) => {
              return <option>{item.name}</option>;
            })}
          </>
        );
      default:
        return (
          <>
            <option hidden>Select</option>
            <option value={"Canada"}>Canada</option>
            <option value={"United States"}>United States</option>
            <option value={"Mexico"}>Mexico</option>
          </>
        );
        break;
    }
  };
  const inputType = (type) => {
    switch (type) {
      case "textarea":
        return (
          <div>
            <label
              htmlFor={props.id}
              className="block text-sm font-medium text-gray-700"
            >
              {props.label}
            </label>
            <div className="mt-1">
              <textarea
                rows={props.rows ? props.rows : 4}
                name="comment"
                id="comment"
                className={`block w-full rounded-md border-gray-300 p-2 border outline-none border-gray-400shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm 
                ${props.error
                    ? "border-red-500 focus:ring-orange-500"
                    : "focus:border-indigo-500 focus:ring-indigo-500"
                  }`}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
              />
            </div>
          </div>
        );
      case "Select Menus Simple custom":
        return (
          <Listbox value={props.value} onChange={props.onChange} disabled={props.disabled}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium text-gray-700">
                  {props.label}
                </Listbox.Label>

                {DropdowonOptions()}
              </>
            )}
          </Listbox>
        );
      case "Select Menus Simple native":
        return (
          <div>
            <label
              htmlFor={props.label}
              className="block text-sm font-medium text-gray-700"
            >
              {props.label}
            </label>
            <select
              id={props.label}
              className={`mt-1 block w-full border  outline-none text-sm rounded-md border-gray-300 py-2 pl-3 pr-10  ${props.error
                ? "border-red-500 focus:ring-orange-500 bg-white"
                : "focus:border-indigo-500 focus:ring-indigo-500 bg-white"
                }`}
              disabled={props.disabled}
              value={props.value}
              onChange={props.onChange}
            >
              {SimpledropDown()}
            </select>
          </div>
        );
      case "Select Menus Simple native low height":
        return (
          <div>
            <label
              htmlFor={props.label}
              className="block text-sm font-medium text-gray-700"
            >
              {props.label}
            </label>
            <select
              id={props.label}
              className={`mt-1 block w-full border  outline-none text-sm rounded-md border-gray-300 px-2 py-1   ${props.error
                ? "border-red-500 focus:ring-orange-500 bg-white"
                : "focus:border-indigo-500 focus:ring-indigo-500 bg-white"
                }`}
              // defaultValue="Canada"
              value={props.value}
              // selectedValue={"props.selectedValue"}
              onChange={props.onChange}
            >
              {SimpledropDown()}
            </select>
          </div>
        );

      case "input with label":
        return (
          <div>
            <label
              htmlFor={props.label}
              className="block text-sm font-medium text-gray-700 "
            >
              {props.label}
            </label>
            <div className="mt-1">
              <input
                type={props.inputType ? props.inputType : "email"}
                id={props.label}
                accept={props.accept}
                readOnly={props.readOnly}
                defaultValue={props.defaultValue}
                className={`block w-full rounded-md border-gray-300 p-2 border 
                outline-none 
                  sm:text-sm ${props.error
                    ? "border-red-500 focus:ring-orange-500"
                    : "focus:border-indigo-500 focus:ring-indigo-500"
                  }`}
                disabled={props.disabled}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                onInput={props.onInput}
                maxLength={props.maxLength}
                min={props.min}
                onKeyDown={props.onKeyDown}

              />
            </div>
          </div>
        );
      case "input with label low height":
        return (
          <div>
            <label
              htmlFor={props.label}
              className="block text-sm font-medium text-gray-700 "
            >
              {props.label}
            </label>
            <div className="mt-1">
              <input
                type={props.inputType ? props.inputType : "email"}
                id={props.label}
                accept={props.accept}
                readOnly={props.readOnly}
                defaultValue={props.defaultValue}
                className={`block w-full rounded-md border-gray-300 px-2 py-1 border 
                  outline-none 
                    sm:text-sm ${props.error
                    ? "border-red-500 focus:ring-orange-500"
                    : "focus:border-indigo-500 focus:ring-indigo-500"
                  }`}
                disabled={props.disabled}

                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                onInput={props.onInput}
                maxLength={props.maxLength}
                min={props.min}
                onKeyDown={props.onKeyDown}
              />
            </div>
          </div>
        );
      case "input with label low YOM":
        return (
          <div>
            <label
              htmlFor={props.label}
              className="block text-sm font-medium text-gray-700 "
            >
              {props.label}
            </label>
            <div className="mt-1">
              <input
                type={props.inputType ? props.inputType : "email"}
                id={props.label}
                accept={props.accept}
                readOnly={props.readOnly}
                defaultValue={props.defaultValue}
                className={`block w-full h-[38px] rounded-md border-gray-300 px-2 py-1 border 
                    outline-none 
                      sm:text-sm ${props.error
                    ? "border-red-500 focus:ring-orange-500"
                    : "focus:border-indigo-500 focus:ring-indigo-500"
                  }`}
                disabled={props.disabled}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                onInput={props.onInput}
                maxLength={props.maxLength}
                min={props.min}
              />
            </div>
          </div>
        );

      case "input with label low height type file":
        return (
          <div>
            <label
              htmlFor={props.label}
              className="block text-sm font-medium text-gray-700 "
            >
              {props.label}
            </label>
            <div className="mt-1 relative cursor-pointer">
              <input
                type={props.inputType}
                id={props.label}
                accept={props.accept}
                readOnly={props.readOnly}
                defaultValue={props.defaultValue}
                className={`block w-full rounded-md border-gray-300 px-2 py-1 border 
                    outline-none 
                      sm:text-sm ${props.error
                    ? "border-red-500 focus:ring-orange-500"
                    : "focus:border-indigo-500 focus:ring-indigo-500"
                  } ${classNames(classes.inputFile)}`}
                disabled={props.disabled}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                onInput={props.onInput}
                maxLength={props.maxLength}
                min={props.min}
              />
              <label
                htmlFor={props.label}
                className="block text-sm font-medium text-gray-700 cursor-pointer"
              >
                <MdOutlineDriveFolderUpload className="h-full w-6 text-gray-600 absolute bottom-0 right-1" />
              </label>
            </div>
          </div>
        );

      case "input no label":
        return (
          <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
              <div className="w-full max-w-md space-y-8">
                <div>
                  <img
                    className="mx-auto h-12 w-auto"
                    src={getLoginLogo()}
                    alt="StockOne"
                  />
                  <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    Sign in to your account
                  </h2>
                  {/* <p className="mt-2 text-center text-sm text-gray-600">
                    Or{" "}
                    <a
                      href="#"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      start your 14-day free trial
                    </a>
                  </p> */}
                </div>
                <form className="mt-8 space-y-6" action="#" method="POST">
                  <input type="hidden" name="remember" defaultValue="true" />
                  <div className="-space-y-px rounded-md shadow-sm">
                    <div>
                      <label htmlFor="email-address" className="sr-only">
                        Email address
                      </label>
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Email address"
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="sr-only">
                        Password
                      </label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Password"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm">
                      <a
                        href="#"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                        onClick={navigate("/forget-password")}
                      >
                        Forgot your password?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <LockClosedIcon
                          className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                          aria-hidden="true"
                        />
                      </span>
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        );
      case "radio group":
        return (
          <div>
            <label className="text-base font-medium text-gray-900">
              {props.label ? props.label : "Notifications"}
            </label>
            <fieldset className="mt-2">
              <legend className="sr-only">Notification method</legend>
              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                {props.data?.map((item) => (
                  <div key={item.id} className="flex items-center">
                    <input
                      id={item.title}
                      onChange={props.onChange}
                      name={item.title}
                      type="radio"
                      value={item.title}
                      checked={props.value === item.title}
                      onClick={() => props.setState(item.title)}
                      disabled={props.disabled}

                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={item.title}
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      {item.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
        );

      case "forgot password":
        return (
          <>
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
              <div className="w-full max-w-md space-y-8">
                <div>
                  <img
                    className="mx-auto h-12 w-auto"
                    src={getLoginLogo()}
                    alt="StockOne"
                  />
                  <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                    Forgot Password
                  </h2>
                </div>
                <form className="mt-8 space-y-6" action="#" method="POST">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm   font-bold text-gray-700"
                    >
                      Enter your user ID
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block w-full rounded-md border-gray-300 p-2 border outline-none border-gray-400shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder={
                          props.placeholder
                            ? props.placeholder
                            : "you@example.com"
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    // onClick={navigate("/create-password")}
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        );

      case "Select Menus with checkbox":
        return (
          <div className="mt-1">
            <div className="flow-root">
              <Popover.Group className="w-full  divide-gray-200">
                <Popover className=" relative block text-left ">
                  <Popover.Button
                    className={`flex justify-between w-full rounded-md border-gray-300 px-2 py-1 border  outline-none  sm:text-sm ${props.error
                      ? "border-red-500 focus:ring-orange-500"
                      : !props.disable
                        ? "focus:border-indigo-500 focus:ring-indigo-500"
                        : "opacity-60"
                      }`}
                  >
                    <span>Select priority</span>

                    <ChevronDownIcon
                      className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Popover.Panel className="origin-top-right absolute right-0 mt-2 z-10  bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none w-max">
                      <form className="space-y-4">
                        {props.data?.map((option, optionIdx) => (
                          <div
                            className={`${props.disable
                              ? "pointer-events-none opacity-80"
                              : ""
                              }`}
                          >
                            <div key={optionIdx} className="flex items-center">
                              <input
                                id={`filter-${optionIdx}`}
                                type="checkbox"
                                className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500 cursor-pointer	"
                                onChange={() => {
                                  props.priorityCheckboxHandler(option);
                                  props.allError.checkedPriority = "";
                                }}
                                checked={props.checkedPriority.some(
                                  (item) => item.name === option.name
                                )}
                              />

                              <label
                                htmlFor={`filter-${optionIdx}`}
                                className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap cursor-pointer	hover:text-indigo-500"
                              >
                                {option.name}
                              </label>
                            </div>
                          </div>
                        ))}
                      </form>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </Popover.Group>
            </div>
          </div>
        );
      default:
        console.log("Invalid type");
        break;
    }
  };
  return <div>{inputType(props.type)}</div>;
};

export default Input;
