import React, { useState, useEffect, Suspense } from "react";
import classes from "../../module.css/Dashboard.module.css";
import { useNavigate } from "react-router-dom";
const SmWrapper = React.lazy(() => import("../../Components/SmWrapper"));
const Heading = React.lazy(() => import("../../tailwindUI/Heading"));
const StatsCard = React.lazy(() => import("../../tailwindUI/StatsCard"));
const Table = React.lazy(() => import("../../tailwindUI/Table"));
const ExcelDownload = React.lazy(() =>
  import("../../Components/ExcelDownload/ExcelDownload")
);

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [carStatusData, setCarStatusData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalWiseCarStatus, setModalWiseCarStatus] = useState([]);
  const [srmExecutiveReportMonth, setSrmExecutiveReportMonth] = useState([]);
  const [srmExecutiveReportYear, setSrmExecutiveReportYear] = useState([]);

  // const [agingModalShow, setAgingModalShow] = useState(false)
  // const [agingData, setAgingData] = useState([])

  const UserToken = localStorage.getItem("UserToken");
  const navigate = useNavigate();
  useEffect(() => {
    dataCount();
    alocationStatusApi();
    ModelWisealocationStatusApi();
    SRMASMExecutiveReportApi();
    // SRMallocationagingstockdetail()
  }, []);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  const dataCount = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${global.apiBaseUrl}/api/v1/stockone/srm/StockCount`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          setData(result);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(""));
  };

  const alocationStatusApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/srm/SRMGrandTotal`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          setCarStatusData(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(""));
  };

  const ModelWisealocationStatusApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/srm/SRMVariantsGrandTotal`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          setModalWiseCarStatus(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(""));
  };

  const SRMASMExecutiveReportApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/srm/SRMASMExicutiveReportnew`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message === "Please login with a different account"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          setSrmExecutiveReportMonth(result.monthlyData);
          setSrmExecutiveReportYear(result.yearlyData);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(""));
  };

  // const SRMallocationagingstockdetail = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", global.authToken);
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     "UserToken": UserToken
  //   });

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: 'follow'
  //   };

  //   fetch("https://promote.onecorp.co.in/api/v1/stockone/srm/SRMallocationagingstockdetail", requestOptions)
  //     .then(response => response.json())
  //     .then(result => {
  //       if (
  //         result.message ===
  //         "Invalid User Token, The token may have expired, You may need to re-login" ||
  //         result.message === "Please login with a different account"
  //       ) {
  //         LogOut();
  //       } else if (result.status === "Success" && result.status_code === 200) {
  //         setAgingData(result.data);
  //       }
  //     })
  //     .catch(error => console.log('error', error));
  // }

  const asmTableHeading = [
    { name: "Model" },
    { name: "Active Bookings" },
    { name: "Allocated (Physical)" },
    { name: "Allocated (Transit)" },
    { name: "Allocated (Pending)" },
    { name: "DLD" },
  ];
  const asmTableHeading1 = [
    { name: "Model" },
    { name: "Variant" },
    { name: "Active Bookings" },
    { name: "Allocated (Physical)" },
    { name: "Allocated (Transit)" },
    { name: "Allocated (Pending)" },
    { name: "DLD" },
  ];

  const asmTableHeading2 = [
    { name: "ASM" },
    { name: "Executive" },
    { name: "Allocated " },
    { name: "Allocation Awaited" },
    { name: "Active Bookings" },
    { name: "Cancelled" },
    { name: "Delivered" },
    { name: "Total Bookings (Active + Delivered Booking's)" },
  ];

  const asmTableHeading3 = [
    { name: "ASM" },
    { name: "Executive" },
    { name: "Active Bookings" },
    { name: "Cancelled" },
    { name: "Delivered" },
    { name: "Total Bookings (Active + Delivered Booking's)" },
  ];

  // const tablecolumns = [

  //   {
  //     name: "Model Name",
  //   },
  //   {
  //     name: "Fuel Type",
  //   },
  //   {
  //     name: "Variant",
  //   },
  //   {
  //     name: "Colour",
  //   },
  //   {
  //     name: "Car Status",
  //   },
  //   {
  //     name: "YOM",
  //   },
  //   {
  //     name: "Allocation Aging",
  //   },
  //   // {
  //   //   name: "Customer No.",
  //   // },
  //   // {
  //   //   name: "ASM",
  //   // },
  //   // {
  //   //   name: "Executive",
  //   // },
  //   // {
  //   //   name: "Exchange",
  //   // },
  //   // {
  //   //   name: "Booking Amount",
  //   // },
  //   // {
  //   //   name: "Booking Date",
  //   // },
  //   // {
  //   //   name: "Status",
  //   // },

  // ];
  const MTD = [
    {
      name: `Total Active Bookings`,
      Count:
        data.currentmonthallocated +
        data.currentmonthawaited +
        data.currentmonthautocancelled,
      icon: "./assets/logos/booking.png",
    },

    {
      name: `Allocated`,
      Count: data.currentmonthallocated ? data.currentmonthallocated : 0,
      icon: "./assets/logos/allocate.png",
    },
    {
      name: "Allocation Awaited",
      Count: data.currentmonthawaited ? data.currentmonthawaited : 0,
      icon: "./assets/logos/await.png",
    },
    {
      name: "Auto Allocation Cancelled",
      Count: data.currentmonthautocancelled
        ? data.currentmonthautocancelled
        : 0,
      icon: "./assets/logos/autocancel.png",
    },
    {
      name: "Booking Cancelled",
      Count: data.currentmonthcancelled ? data.currentmonthcancelled : 0,
      icon: "./assets/logos/cancel.png",
    },
  ];

  const stats = [
    {
      name: "Stock Allocated today",
      Count: data.stockallocatedToday_count
        ? data.stockallocatedToday_count
        : 0,
      icon: "./assets/logos/today.png",
    },

    {
      name: "Delivery Scheduled today",
      Count: data.delivereshudule_count ? data.delivereshudule_count : 0,
      icon: "./assets/logos/deliveredreq.png",
    },
    {
      name: "Delivery Request Pending",
      Count: data.markeddeliveredINonemonth_count
        ? data.markeddeliveredINonemonth_count
        : 0,
      icon: "./assets/logos/request.png",
    },

    {
      name: `Delivered This Month`,
      Count: data.deliverytilldate ? data.deliverytilldate : 0,
      icon: "./assets/logos/delivered.png",
    },
    {
      name: `Current Month bookings`,
      Count: data.currentmonthbooking ? data.currentmonthbooking : 0,
      icon: "./assets/logos/currentmonth.png",
    },
  ];
  return (
    <Suspense fallback={<div className="text-center">Loading...</div>}>
      <SmWrapper>
        {/* {agingModalShow && (
          <Modal
            type="manage transit info"
            setOpen={setAgingModalShow}
            open={agingModalShow}
            onCancel={() => setAgingModalShow(false)}
          >
            <div className="flex justify-end mb-2 ">
              <GrClose onClick={() => setAgingModalShow(false)} />
            </div>
            <Table
              type="stripped rows"
              TableData={agingData}
              tableHeading={tablecolumns}
              tabledataType="SM Allocated Aging Modal"
            />
          </Modal>
        )} */}
        {loading ? (
          <div className={classes.loader}>
            {" "}
            <img src="assets/loadingGif.gif" alt="loading" />
          </div>
        ) : (
          <div className="mb-16">
            <div className={classes.heading}>
              <Heading
                headingType={"Section Headings"}
                BreadCrumHomeLink={"/sm-dashboard"}
                headingText={"Dashboard"}
                subheadingText={localStorage.getItem("Location")}
              />

              <div className={classes.MainContainer}>
                <StatsCard data={stats} />

                <div className="my-4 ">
                  <StatsCard data={MTD} />
                </div>
                <div className="my-4 ">
                  <StatsCard data={data} type={"YTD SRM"} />
                </div>

                {/* <div className="mt-4 -mb-4 text-lg text-gray-900 font-bold">
                  Allocaton Aging
                </div>
                <StatsCard data={data} type={"Aging"} setAgingModalShow={setAgingModalShow} /> */}
              </div>

              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-bold leading-6 text-gray-900">
                    Allocation status
                  </h1>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <ExcelDownload
                    csvData={carStatusData}
                    fileName="Allocation status report"
                    className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  />
                </div>
              </div>
              <Table
                tableHeading={asmTableHeading}
                type="vertical lines ui changes"
                tabledataType="allocation status grid list dashboard"
                data={carStatusData}
              />
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-bold leading-6 text-gray-900">
                    All Model Booking Status
                  </h1>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <ExcelDownload
                    csvData={modalWiseCarStatus}
                    fileName="All Model Booking Status Report"
                    className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  />
                </div>
              </div>
              <Table
                tableHeading={asmTableHeading1}
                type="vertical lines ui changes"
                tabledataType="Model wise allocation status grid list dashboard"
                data={modalWiseCarStatus}
              />
              {/* <div>
              <div className="mt-8 sm:-mb-5 flex justify-between">
                <div className="text-lg text-gray-900 font-bold  ml-2">
                  ASM Executive Allocation status
                </div>
                <ExcelDownload
                  csvData={srmExecutiveReport}
                  fileName="Allocation status"
                />
              </div>
              <Table
                tableHeading={asmTableHeading2}
                type="vertical lines"
                tabledataType="Executive allocation status grid list dashboard"
                data={srmExecutiveReport}
              />
            </div> */}

              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-bold leading-6 text-gray-900">
                    Monthly Booking Status
                  </h1>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <ExcelDownload
                    csvData={srmExecutiveReportMonth}
                    fileName="Monthly Booking Status Report"
                    className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  />
                </div>
              </div>
              <Table
                tableHeading={asmTableHeading2}
                type="vertical lines ui changes"
                tabledataType="Executive allocation status grid list dashboard"
                data={srmExecutiveReportMonth}
              />

              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-bold leading-6 text-gray-900">
                    Yearly Booking Status
                  </h1>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <ExcelDownload
                    csvData={srmExecutiveReportYear}
                    fileName="Yearly Booking Status Report"
                    className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  />
                </div>
              </div>
              <Table
                tableHeading={asmTableHeading3}
                type="vertical lines ui changes"
                tabledataType="Executive allocation status grid list dashboard yearly"
                data={srmExecutiveReportYear}
              />
            </div>
          </div>
        )}
      </SmWrapper>
    </Suspense>
  );
};

export default Dashboard;
