import React, { useState, useEffect, useMemo } from "react";
import Heading from "../../tailwindUI/Heading";
import Pagination from "../../tailwindUI/Pagination";
import Tab from "../../tailwindUI/Tab";
import StackedList from "../../tailwindUI/StackedList";
import classes from "../../module.css/ManageDelivery.module.css";
import Navbar from "../../tailwindUI/Navbar";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../tailwindUI/Modal";
import TableHeader from "../../Components/TableHeader";
import Input from "../../tailwindUI/Input";
import FollowUpRemarks from "../../Components/FollowUpRemarks";

const ManageDelivery = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openRemark, setOpenRemark] = useState(false);
  const [remarkData, setRemarkData] = useState(null);
  const [data, setData] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [dataAllCount, setDataAllCount] = useState([]);
  const [dataDelivered, setDataDelivered] = useState([]);
  const [dataDeliveredCount, setDataDeliveredCount] = useState([]);
  const [dataCancelled, setDataCancelled] = useState([]);
  const [dataCancelledCount, setDataCancelledCount] = useState(0);
  const [dataRequest, setDataRequest] = useState([]);
  const [dataRequestCount, setDataRequestCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState({ current: 0, total: 0 });
  const [tabNum, setTabNum] = useState(0);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [cancel, setCancel] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [report, setReport] = useState(false);
  const [staticData, setStaticData] = useState({
    bookId: "",
    carId: "",
  });
  const [maxpages, setMaxpages] = useState(1);
  const [tabname, setTabname] = useState("DeliveryRequest");
  const [updateDeliveryDate, setUpdateDeliveryDate] = useState("");
  const location = useLocation();
  const pathname = window.location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page") ? queryParams.get("page") : 1);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    fetchDelivery();
  }, [searchTerm === "", page]);

  const fetchDelivery = async () => {
    let fetchedData = await fetch(
      `${global.apiBaseUrl}/api/v1/stockone/edp/EDPDeliveryList`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.authToken,
        },
        body: JSON.stringify({
          UserToken: localStorage.getItem("UserToken"),
          page: page,
        }),
      }
    );

    let parseData = await fetchedData.json();

    if (parseData.All?.length > 0) {
      switch (tabNum) {
        case 0: {
          setData(parseData.DeliveryRequested);
          setCount({
            current: parseData.DeliveryRequested.length,
            total: parseData.DeliveryRequestedCount,
          });
          setMaxpages(Math.ceil(parseData.DeliveryRequestedCount / 20));
          break;
        }
        case 1: {
          setData(parseData.Delivered);
          setCount({
            current: parseData.Delivered.length,
            total: parseData.DeliveredCount,
          });
          setMaxpages(Math.ceil(parseData.DeliveredCount / 20));
          break;
        }
        case 2: {
          setData(parseData.Cancelled);
          setCount({
            current: parseData.Cancelled.length,
            total: parseData.CancelledCount,
          });
          setMaxpages(Math.ceil(parseData.CancelledCount / 20));
          break;
        }
        case 3: {
          setData(parseData.All);
          setCount({
            current: parseData.All.length,
            total: parseData.AllCount,
          });
          setMaxpages(Math.ceil(parseData.AllCount / 20));
          break;
        }
        default: {
          setData(parseData.All);
        }
      }

      setDataAll(parseData.All);
      setDataAllCount(parseData.AllCount);
      setDataRequest(parseData.DeliveryRequested);
      setDataRequestCount(parseData.DeliveryRequestedCount);
      setDataDelivered(parseData.Delivered);
      setDataDeliveredCount(parseData.DeliveredCount);
      setDataCancelled(parseData.Cancelled);
      setDataCancelledCount(parseData.CancelledCount);
    }

    if (
      parseData.status_code === 200 &&
      parseData.status === "Success" &&
      parseData.data ===
        "Invalid User Token, The token may have expired, You may need to re-login" &&
      parseData.message ===
        "Invalid User Token, The token may have expired, You may need to re-login"
    ) {
      LogOut();
    }
    setLoading(false);
  };

  const setTabNumber = async (tabIndex) => {
    setLoading(true);
    setTabNum(tabIndex);
    getTabName(tabIndex);
    switch (parseInt(tabIndex)) {
      case 0: {
        setData(dataRequest);
        let length = dataRequest.length;
        setCount({
          current: length,
          total: dataRequestCount,
        });
        setMaxpages(Math.ceil(dataRequestCount / 20));
        setLoading(false);
        break;
      }
      case 1: {
        setData(dataDelivered);
        let length = dataDelivered.length;
        setCount({
          current: length,
          total: dataDeliveredCount,
        });
        setMaxpages(Math.ceil(dataDeliveredCount / 20));
        setLoading(false);
        break;
      }
      case 2: {
        setData(dataCancelled);
        let length = dataCancelled.length;
        setCount({
          current: length,
          total: dataCancelledCount,
        });
        setMaxpages(Math.ceil(dataCancelledCount / 20));
        setLoading(false);
        break;
      }
      case 3: {
        setData(dataAll);
        let length = dataAll.length;
        setCount({
          current: length,
          total: dataAllCount,
        });
        setMaxpages(Math.ceil(dataAllCount / 20));
        setLoading(false);
        break;
      }
      default: {
        setData([]);
      }
    }
  };

  const cancelDelivery = async (bookingId, carId) => {
    let fetchedData = await fetch(
      `${global.apiBaseUrl}/api/v1/stockone/edp/EDPDeliveryCancel`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.authToken,
        },
        body: JSON.stringify({
          UserToken: localStorage.getItem("UserToken"),
          CarStockID: carId,
          BookingID: bookingId,
        }),
      }
    );

    let parseData = await fetchedData.json();

    if (parseData.status === "Success" && parseData.status_code === 200) {
      setTabNumber(tabNum);
      setMessage(parseData.message);
      setLoading(true);
      fetchDelivery();
    } else {
    }
  };

  const confirmDelivery = async (bookingId, carId) => {
    let fetchedData = await fetch(
      `${global.apiBaseUrl}/api/v1/stockone/edp/EDPDeliveryConfirm`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.authToken,
        },
        body: JSON.stringify({
          UserToken: localStorage.getItem("UserToken"),
          CarStockID: carId,
          BookingID: bookingId,
          DeliveryDate: updateDeliveryDate,
        }),
      }
    );

    let parseData = await fetchedData.json();

    if (parseData.status === "Success" && parseData.status_code === 200) {
      setTabNumber(tabNum);
      setMessage(parseData.message);
      setLoading(true);
      fetchDelivery();
    }
  };

  const reportSus = async (bookingId) => {
    let fetchedData = await fetch(
      `${global.apiBaseUrl}/api/v1/stockone/edp/EDPSuspiciousActivity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: global.authToken,
        },
        body: JSON.stringify({
          UserToken: localStorage.getItem("UserToken"),
          BookingID: bookingId,
          SuspiciousActivity: 1,
        }),
      }
    );

    let parseData = await fetchedData.json();

    if (parseData.status_code === 200 && parseData.status === "Success") {
      setMessage(parseData.message);
      fetchDelivery();
    }
  };

  const EDPDeliveryListSearchApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: localStorage.getItem("UserToken"),
      SearchTerm: searchTerm,
      TabName: tabname,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/edp/EDPDeliveryListSearch`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "data found successfully"
        ) {
          if (searchTerm && result.data.length < 2) {
            navigate(pathname);
          }
          setData(result.data);
          setMaxpages(Math.ceil(result.data / 20));
        } else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data.length < 1
        ) {
          if (searchTerm) {
            navigate(pathname);
          }
          setData([]);
          setMaxpages(Math.ceil(result.count / 20));
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };

  const tabs = [
    { name: "Delivery Request", href: "#", current: true },
    { name: "Delivered", href: "#", current: false },
    { name: "Cancelled", href: "#", current: false },
    { name: "All", href: "#", current: false },
  ];

  const modalState = (identity, bookingId, carId, updatedDelivery) => {
    switch (identity) {
      case "CANCEL": {
        setCancel(true);
        break;
      }
      case "CONFIRM": {
        setConfirm(true);
        break;
      }
      case "REPORT": {
        setReport(true);
        break;
      }
    }

    setOpen(true);

    setStaticData({
      bookId: bookingId,
      carId: carId,
      updatedDelivery: updatedDelivery,
    });
  };

  const modalProcess = () => {
    switch (true) {
      case cancel: {
        setCancel(false);
        cancelDelivery(staticData.bookId, staticData.carId);
        setStaticData({
          bookId: "",
          carId: "",
          updatedDelivery: "",
        });
        break;
      }
      case confirm: {
        setConfirm(false);
        confirmDelivery(
          staticData.bookId,
          staticData.carId,
          staticData.updatedDelivery
        );
        setStaticData({
          bookId: "",
          carId: "",
          updatedDelivery: "",
        });
        break;
      }
      case report: {
        setReport(false);
        reportSus(staticData.bookId);
        setStaticData({
          bookId: "",
          carId: "",
          updatedDelivery: "",
        });
        break;
      }
    }
    setOpen(false);
  };

  const BreadCrumb = {
    pagename: "Manage Delivery",
    pagePath: "/edp-manage-delivery",
    pagename1: "",
    pagePath1: "",
  };

  const keyWords = () => {
    switch (true) {
      case cancel: {
        return "cancel";
        break;
      }
      case confirm: {
        return "confirm";
        break;
      }
      case report: {
        return "report";
        break;
      }
      default: {
        return "proceed";
      }
    }
  };

  const headingKeys = () => {
    switch (true) {
      case cancel: {
        return "Cancel Delivery";
      }
      case confirm: {
        return "Confirm Delivery";
      }
      case report: {
        return "Report Delivery";
      }
      default: {
        return "Manage Delivery";
      }
    }
  };

  useMemo(() => {
    if (open === false) {
      setCancel(false);
      setConfirm(false);
      setReport(false);
    }
  }, [open]);

  const getTabName = (tabIndex) => {
    if (tabIndex === 0 || tabIndex === "") {
      setTabname("DeliveryRequest");
    } else if (tabIndex === 1) {
      setTabname("Delivered");
    } else if (tabIndex === 2) {
      setTabname("Cancelled");
    } else if (tabIndex === 3) {
      setTabname("All");
    } else {
      return;
    }
  };

  const getremark = (item) => {
    setRemarkData(item);
  };
  return (
    <>
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
        />
      )}
      {openRemark && (
        <FollowUpRemarks
          bookingData={remarkData}
          setOpen={setOpenRemark}
          open={openRemark}
        />
      )}
      {open && (
        <Modal
          setOpen={setOpen}
          desc={`Are you sure you want to ${keyWords()} ?`}
          heading={`${headingKeys()}`}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          // onClick={() =>
          //   formValidation() ? CancelBooking() : console.log("err")
          // }
          onClick={() => {
            modalProcess();
          }}
          type="Simple alert"
        >
          {keyWords() === "confirm" && (
            <div className="my-4">
              <Input
                type={"input with label"}
                label="Update Delivery Date"
                inputType="date"
                // onInput={() => (errors.signOffDate = "")}
                value={staticData.updatedDelivery}
                onChange={(e) => {
                  setUpdateDeliveryDate(e.target.value);
                  setStaticData({
                    bookId: staticData.bookId,
                    carId: staticData.carId,
                    updatedDelivery: e.target.value,
                  });
                }}
              />
            </div>
          )}
        </Modal>
      )}
      <Navbar navbarType={"blue navbar"} />
      <div className="mb-4">
        <div className={classes.headingCrump}>
          <Heading
            headingType={"Page Headings"}
            headingText={"Manage Delivery"}
            BreadCrumb={BreadCrumb}
            BreadCrumHomeLink={"/edp-dashboard"}
          />
        </div>
      </div>
      {/* <FollowUpRemarks open={open} bookingData={bookingDetail} /> */}

      <div className={classes.mainContainer}>
        <div className={classes.innerContainer}>
          <Tab
            setTabNumber={setTabNumber}
            tabs={tabs}
            count={count}
            type={"manageDel"}
          />
          <div className="my-2">
            <TableHeader
              buttonText="Search"
              tableHeading="Search Criteria"
              subHeading="subHeading"
              condition="managedevlivery"
              // totalResult={totalCarCount}
              // pageResult={listdata.length}
              placeholderType="Enter VIN No."
              apicall={EDPDeliveryListSearchApi}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
            />
          </div>
          {loading ? (
            <img
              src="../assets/loadingGif.gif"
              alt="loading"
              className={classes.loader}
            />
          ) : (
            <>
              <StackedList
                type="modBookingList"
                data={data}
                tabNum={tabNum}
                cancelDelivery={cancelDelivery}
                confirmDelivery={confirmDelivery}
                reportSus={reportSus}
                setOpen={setOpen}
                modalState={modalState}
                getremark={getremark}
                setOpenRemark={setOpenRemark}
              />
            </>
          )}
        </div>
        {maxpages > 1 && (
          <div className={classes.pagination}>
            <Pagination maxPages={maxpages} />
          </div>
        )}
      </div>
    </>
  );
};

export default ManageDelivery;
