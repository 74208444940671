import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataNotFound from "../../Components/DataNotFound";
import classes from "../../module.css/AddStock.module.css";
import Button from "../../tailwindUI/Button";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import Heading from "../../tailwindUI/Heading";
import Input from "../../tailwindUI/Input";
import Navbar from "../../tailwindUI/Navbar";
import Pagination from "../../tailwindUI/Pagination";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import Table from "../../tailwindUI/Table";

const AddStock = () => {
  let navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [variant, setVariant] = useState([]);
  const [color, setColor] = useState([]);
  const [fuelType, setFuelType] = useState([]);
  const [yom, setYom] = useState("");
  const [stockStatus, setStockStatus] = useState("");
  const [signOffDate, setSignOffDate] = useState("");
  const [hmilDate, setHmilDate] = useState("");
  const [eta, setEta] = useState("");
  const [vin, setVin] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [freeStockBBND, setFreeStockBBND] = useState("Free Stock");
  const [numberOfVehicles, setNumberOfVehicles] = useState(0);
  const [transitMode, setTransitMode] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverNo, setDriverNo] = useState("");
  const [truckRailNo, setTruckRailNo] = useState("");
  const [lastLocation, setLastLocation] = useState("");
  const [errors, setErrors] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [modelFilter, setModelFilter] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [listCount, setListCount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page") ? queryParams.get("page") : 1);
  const maxpages = Math.ceil(listCount / 20);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    if (selected !== "") {
      MFVCapi();
    } else {
      MFVCapi();
      EDPAddedStockList();
    }
  }, [selected, fuelType, page]);

  const MFVCapi = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Model: selected,
      Fuel: fuelType.toString(),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      `${global.apiBaseUrl}/api/v1/stockone/common/GetFilterList`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          if (selected === "") {
            setModelFilter(result.data.Model);
          } else {
            setFilteredData(result.data);
          }
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      });
  };

  const addStockInsertapi = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: localStorage.getItem("UserToken"),
      Model: selected,
      FuelType: fuelType,
      Variant: variant,
      Colour: color,
      YOM: yom,
      StockStatus: stockStatus,
      SignoffDate: signOffDate,
      HMILInvoiceDate: hmilDate,
      ETADate: eta,
      VINNumber: vin,
      OrderID: orderNo,
      FreeStockBBND: freeStockBBND,
      NumberofVehicles: numberOfVehicles === 0 ? "" : numberOfVehicles,
      TransitMode: transitMode,
      DriverName: driverName,
      DriverNumber: driverNo,
      TruckRailNumber: truckRailNo.toUpperCase(),
      LastLocation: lastLocation,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      `${global.apiBaseUrl}/api/v1/stockone/edp/EDPStockAdd`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "fail" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (
          result.status === "Success" &&
          result.status_code === 200 &&
          result.message !== "VINNumber already exist " &&
          result.message !== ",OrderID already exist " &&
          result.message !== "VINNumber,OrderID already exist "
        ) {
          setMessage(result.message);
          setApiFailled(false);
          setErrors("");
          setSelected("");
          setVariant([]);
          setColor([]);
          setFuelType([]);
          setYom("");
          setStockStatus("");
          setSignOffDate("");
          setHmilDate("");
          setEta("");
          setVin("");
          setOrderNo("");
          setNumberOfVehicles("");
          setTransitMode("");
          setDriverName("");
          setDriverNo("");
          setTruckRailNo("");
          setLastLocation("");
          EDPAddedStockList();
        } else if (
          result.message === "VINNumber already exist " &&
          result.data === "VINNumber already exist"
        ) {
          setErrors({
            ...errors,
            vin: "VIN number already exists",
          });
        } else if (
          result.message === ",OrderID already exist " &&
          result.data === ",OrderID already exist"
        ) {
          setErrors({
            ...errors,
            orderNo: "Order number already exists",
          });
        } else if (
          result.message === "VINNumber,OrderID already exist " &&
          result.data === "VINNumber,OrderID already exist"
        ) {
          setErrors({
            ...errors,
            vin: "VIN number already exists",

            orderNo: "Order number already exists",
          });
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        } else {
          setApiFailled(true);
          setMessage(result.Reason);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const EDPAddedStockList = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: localStorage.getItem("UserToken"),
      page: page,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      `${global.apiBaseUrl}/api/v1/stockone/edp/EDPStockNewList`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "fail" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 200 && result.status === "Success") {
          setTableList(result.data);
          setListCount(result.total_count);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(""));
  };

  const formValidation = (e) => {
    switch (stockStatus) {
      case "InTransit": {
        const ordernoregex = /^[\w\-\s]+$/;
        const yomregex = /^[0-9\b]+$/;
        let isValid = true;
        let error = {};
        if (!orderNo || orderNo === "") {
          isValid = false;
          error.orderNo = "Please select Order No.";
        } else if (!ordernoregex.test(orderNo)) {
          isValid = false;
          error.orderNo = "Please enter correct Order No.";
        }
        if (!selected || selected === "") {
          isValid = false;
          error.selected = "Please select model";
        }
        if (!variant || variant.length === 0) {
          isValid = false;
          error.variant = "Please select variant";
        }
        if (!color || color.length === 0) {
          isValid = false;
          error.color = "Please select colour";
        }
        if (!fuelType || fuelType.length === 0) {
          isValid = false;
          error.fuelType = "Please select fuel type";
        }
        if (!signOffDate || signOffDate === "") {
          isValid = false;
          error.signOffDate = "Please select Sign Off Date";
        }
        if (!hmilDate || hmilDate === "") {
          isValid = false;
          error.hmilDate = "Please select HMIL Date";
        }
        if (!eta || eta === "") {
          isValid = false;
          error.eta = "Please select ETA";
        }
        // if (!driverName || driverName === "") {
        //   isValid = false;
        //   error.driverName = "Please select driver name";
        // } else if (!drivernameregex.test(driverName)) {
        //   isValid = false;
        //   error.driverName = "Please enter correct driver name";
        // }
        // if (!driverNo || driverNo === "") {
        //   isValid = false;
        //   error.driverNo = "Please select driver number";
        // } else if (!drivernumber.test(driverNo) || driverNo.length < 10) {
        //   isValid = false;
        //   error.driverNo = "Please enter correct driver number";
        // }

        // if (!truckRailNo || truckRailNo === "") {
        //   isValid = false;
        //   error.truckRailNo = "Please select truck rail no";
        // }

        // if (!lastLocation || lastLocation === "") {
        //   isValid = false;
        //   error.lastLocation = "Please select last location";
        // } else if (!drivernameregex.test(lastLocation)) {
        //   isValid = false;
        //   error.lastLocation = "Please enter correct last location";
        // }
        // if (!transitMode || transitMode === "") {
        //   isValid = false;
        //   error.transitMode = "Please select transit mode";
        // }
        if (!yom || yom === "") {
          isValid = false;
          error.yom = "Please select year of manufacturing";
        } else if (!yomregex.test(yom) || yom.length < 4) {
          isValid = false;
          error.yom = "Please enter correct year of manufacturing";
        } else if (yom > new Date().getFullYear() + 1) {
          isValid = false;
          error.yom = "Year of manufacture should be valid";
        }

        if (!stockStatus || stockStatus === "") {
          isValid = false;
          error.stockStatus = "Please select stock status";
        }
        // if (!vin || vin === "") {
        //   isValid = false;
        //   error.vin = "Please select VIN number";
        // } else if (!vinregex.test(vin)) {
        //   isValid = false;
        //   error.vin = "Please enter correct VIN No.";
        // }
        if (!freeStockBBND || freeStockBBND === "") {
          isValid = false;
          error.freeStockBBND = "Please select Free Stock/BBND";
        }
        setErrors(error);
        return isValid;
      }
      case "Physical": {
        const ordernoregex = /^[\w\-\s]+$/;
        const yomregex = /^[0-9\b]+$/;
        const vinregex = /^[0-9a-zA-Z]+$/;

        let isValid = true;
        let error = {};
        if (!orderNo || orderNo === "") {
          isValid = false;
          error.orderNo = "Please select Order No.";
        } else if (!ordernoregex.test(orderNo)) {
          isValid = false;
          error.orderNo = "Please enter correct Order No.";
        }
        if (!selected || selected === "") {
          isValid = false;
          error.selected = "Please select model";
        }
        if (!variant || variant.length === 0) {
          isValid = false;
          error.variant = "Please select variant";
        }
        if (!color || color.length === 0) {
          isValid = false;
          error.color = "Please select colour";
        }
        if (!fuelType || fuelType.length === 0) {
          isValid = false;
          error.fuelType = "Please select fuel type";
        }
        if (!signOffDate || signOffDate === "") {
          isValid = false;
          error.signOffDate = "Please select sign off date";
        }
        if (!hmilDate || hmilDate === "") {
          isValid = false;
          error.hmilDate = "Please select HMIL date";
        }

        if (!yom || yom === "") {
          isValid = false;
          error.yom = "Please select year of manufacturing";
        } else if (!yomregex.test(yom) || yom.length < 4) {
          isValid = false;
          error.yom = "Please enter correct year of manufacturing";
        } else if (yom > new Date().getFullYear() + 1) {
          isValid = false;
          error.yom = "Year of manufacture should be valid";
        }
        if (!stockStatus || stockStatus === "") {
          isValid = false;
          error.stockStatus = "Please select stock status";
        }
        if (!vin || vin === "") {
          isValid = false;
          error.vin = "Please select VIN number";
        } else if (!vinregex.test(vin)) {
          isValid = false;
          error.vin = "Please enter correct VIN number";
        }
        if (!freeStockBBND || freeStockBBND === "") {
          isValid = false;
          error.freeStockBBND = "Please select Free Stock/BBND";
        }
        setErrors(error);
        return isValid;
      }
      case "Pending": {
        const ordernoregex = /^[\w\-\s]+$/;
        const yomregex = /^[0-9\b]+$/;
        const noofvehicles = /^[1-9][0-9]*$/;

        let isValid = true;
        let error = {};
        if (numberOfVehicles < 2 && (!orderNo || orderNo === "")) {
          isValid = false;
          error.orderNo = "Please select Order No.";
        } else if (numberOfVehicles < 2 && !ordernoregex.test(orderNo)) {
          isValid = false;
          error.orderNo = "Please enter correct Order No.";
        }
        if (!selected || selected === "") {
          isValid = false;
          error.selected = "Please select model";
        }
        if (!variant || variant.length === 0) {
          isValid = false;
          error.variant = "Please select variant";
        }
        if (!color || color.length === 0) {
          isValid = false;
          error.color = "Please select colour";
        }
        if (!fuelType || fuelType.length === 0) {
          isValid = false;
          error.fuelType = "Please select fuel type";
        }
        if (!numberOfVehicles || numberOfVehicles === "") {
          isValid = false;
          error.numberOfVehicles = "Please select number of vehicles";
        } else if (!noofvehicles.test(numberOfVehicles)) {
          isValid = false;
          error.numberOfVehicles = "Please enter correct vehicle count";
        }
        if (!yom || yom === "") {
          isValid = false;
          error.yom = "Please select year of manufacturing";
        } else if (!yomregex.test(yom) || yom.length < 4) {
          isValid = false;
          error.yom = "Please enter correct year of manufacturing";
        } else if (yom > new Date().getFullYear() + 1) {
          isValid = false;
          error.yom = "Year of manufacture should be valid";
        }
        if (!stockStatus || stockStatus === "") {
          isValid = false;
          error.stockStatus = "Please select stock status";
        }

        if (!freeStockBBND || freeStockBBND === "") {
          isValid = false;
          error.freeStockBBND = "Please select Free Stock/BBND";
        }
        setErrors(error);
        return isValid;
      }
      default: {
        const ordernoregex = /^[\w\-\s]+$/;
        const yomregex = /^[0-9\b]+$/;

        let isValid = true;
        let error = {};
        if (!orderNo || orderNo === "") {
          isValid = false;
          error.orderNo = "Please select Order No.";
        } else if (!ordernoregex.test(orderNo)) {
          isValid = false;
          error.orderNo = "Please enter correct Order No.";
        }
        if (!selected || selected === "") {
          isValid = false;
          error.selected = "Please select model";
        }
        if (!variant || variant.length === 0) {
          isValid = false;
          error.variant = "Please select variant";
        }
        if (!color || color.length === 0) {
          isValid = false;
          error.color = "Please select colour";
        }
        if (!fuelType || fuelType.length === 0) {
          isValid = false;
          error.fuelType = "Please select fuel type";
        }

        if (!yom || yom === "") {
          isValid = false;
          error.yom = "Please select year of manufacturing";
        } else if (!yomregex.test(yom) || yom.length < 4) {
          isValid = false;
          error.yom = "Please enter correct year of manufacturing";
        } else if (yom > new Date().getFullYear() + 1) {
          isValid = false;
          error.yom = "Year of manufacture should be valid";
        }
        if (!stockStatus || stockStatus === "") {
          isValid = false;
          error.stockStatus = "Please select stock status";
        }

        if (!freeStockBBND || freeStockBBND === "") {
          isValid = false;
          error.freeStockBBND = "Please select Free Stock/BBND";
        }
        setErrors(error);
        return isValid;
      }
    }
  };

  const notificationMethods = [
    { id: "freestock", title: "Free Stock" },
    { id: "BBND", title: "BBND" },
  ];
  const notificationMethods1 = [
    { id: "Truck", title: "Truck" },
    { id: "Rail", title: "Rail" },
  ];

  const tablecolumns = [
    {
      name: "Order No.",
    },
    {
      name: "VIN No.",
    },
    {
      name: "Model ",
    },
    {
      name: "Fuel Type",
    },
    {
      name: "Variant",
    },
    {
      name: "Colour",
    },
    {
      name: "Status",
    },

    {
      name: "Allocation Status",
    },
    {
      name: "Allocated To",
    },
    {
      name: "Edit",
    },
  ];
  const stockStatusData = ["Pending", "InTransit", "Physical"];
  const NumOfVehicles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    return message;
  };

  const cancelButton = () => {
    setSelected("");
    setVariant([]);
    setColor([]);
    setFuelType([]);
    setYom("");
    setStockStatus("");
    setSignOffDate("");
    setHmilDate("");
    setEta("");
    setVin("");
    setOrderNo("");

    setNumberOfVehicles("");
    setTransitMode("");
    setDriverName("");
    setDriverNo("");
    setTruckRailNo("");
    setLastLocation("");
  };

  const BreadCrumb = {
    pagename: "Add Stock",
    pagePath: "/edp-add-stock",
    pagename1: "",
    pagePath1: "",
  };
  const BreadCrumHomeLink = "/edp-dashboard";

  return (
    <>
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
          apiFailled={apiFailled}
        />
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <Navbar navbarType={"blue navbar"} />

      <div className={classes.maincontainer}>
        <Heading
          headingType={"Page Headings"}
          headingText={"Add New Stock"}
          BreadCrumb={BreadCrumb}
          BreadCrumHomeLink={BreadCrumHomeLink}
        />

        <div className={classes.form}>
          <Heading headingType={"Section Headings"} headingText={"SAS Stock"} />

          {loading ? (
            <img
              src="../assets/loadingGif.gif"
              alt="loading"
              className={classes.loader}
            />
          ) : (
            <>
              <form>
                <div className={classes.flex}>
                  <div className={classes.feildswidth}>
                    <Input
                      type={"Select Menus Simple custom"}
                      label={"Model"}
                      value={selected}
                      onInput={() => (errors.selected = "")}
                      models={modelFilter}
                      // selected={selected}
                      onChange={(e) => {
                        setSelected(e);
                        errors.selected = "";
                      }}
                      dropdownType="models"
                      error={errors.selected}
                    />
                    <span className="error">{errors.selected}</span>
                  </div>

                  <div className={classes.feildswidth}>
                    <Input
                      type={"Select Menus Simple native"}
                      label="Fuel Type"
                      value={fuelType}
                      onInput={() => (errors.fuelType = "")}
                      onChange={(e) => {
                        setFuelType(e.target.value);
                        errors.fuelType = "";
                      }}
                      filteredData={filteredData}
                      dropdownType="fuel type"
                      error={errors.fuelType}
                    />
                    <span className="error">{errors.fuelType}</span>
                  </div>
                  <div className={classes.feildswidth}>
                    <Input
                      type={"Select Menus Simple native"}
                      label="Variant"
                      onInput={() => (errors.variant = "")}
                      value={variant}
                      onChange={(e) => {
                        setVariant(e.target.value);
                        errors.variant = "";
                      }}
                      filteredData={filteredData}
                      dropdownType="Variant"
                      error={errors.variant}
                    />
                    <span className="error">{errors.variant}</span>
                  </div>
                  <div className={classes.feildswidth}>
                    <Input
                      type={"Select Menus Simple native"}
                      label="Colour"
                      onInput={() => (errors.color = "")}
                      value={color}
                      onChange={(e) => {
                        setColor(e.target.value);
                        errors.color = "";
                      }}
                      filteredData={filteredData}
                      dropdownType="Color"
                      error={errors.color}
                    />
                    <span className="error">{errors.color}</span>
                  </div>
                  <div className={classes.feildswidth}>
                    <Input
                      type={"input with label"}
                      inputType="text"
                      maxLength="4"
                      min="4"
                      label="Year Of Manufacturing"
                      placeholder="Enter year of manufacturing"
                      onInput={() => (errors.yom = "")}
                      value={yom}
                      onChange={(e) => setYom(e.target.value)}
                      error={errors.yom}
                    />
                    <span className="error">{errors.yom}</span>
                  </div>
                  <div className={classes.feildswidth}>
                    <Input
                      type={"Select Menus Simple native"}
                      label="Stock Status"
                      onInput={() => (errors.stockStatus = "")}
                      value={stockStatus}
                      onChange={(e) => {
                        setStockStatus(e.target.value);
                        errors.stockStatus = "";
                      }}
                      data={stockStatusData}
                      dropdownType="Stock Status"
                      error={errors.stockStatus}
                    />
                    <span className="error">{errors.stockStatus}</span>
                  </div>
                  {stockStatus === "Physical" || stockStatus === "InTransit" ? (
                    <div className={classes.feildswidth}>
                      <Input
                        type={"input with label"}
                        label="Sign Off Date"
                        inputType="date"
                        onInput={() => (errors.signOffDate = "")}
                        value={signOffDate}
                        onChange={(e) => setSignOffDate(e.target.value)}
                        error={errors.signOffDate}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                      <span className="error">{errors.signOffDate}</span>
                    </div>
                  ) : (
                    <></>
                  )}

                  {stockStatus === "Physical" || stockStatus === "InTransit" ? (
                    <div className={classes.feildswidth}>
                      <Input
                        type={"input with label"}
                        label="HMIL Invoice Date"
                        inputType="date"
                        onInput={() => (errors.hmilDate = "")}
                        value={hmilDate}
                        onChange={(e) => setHmilDate(e.target.value)}
                        error={errors.hmilDate}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                      <span className="error">{errors.hmilDate}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {stockStatus === "InTransit" ? (
                    <div className={classes.feildswidth}>
                      <Input
                        type={"input with label"}
                        label="ETA"
                        inputType="date"
                        onInput={() => (errors.eta = "")}
                        value={eta}
                        onChange={(e) => setEta(e.target.value)}
                        error={errors.eta}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                      <span className="error">{errors.eta}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {stockStatus === "InTransit" || stockStatus === "Physical" ? (
                    <div className={classes.feildswidth}>
                      <Input
                        type={"input with label"}
                        inputType="text"
                        label="VIN Number"
                        placeholder="Enter VIN number"
                        onInput={() => (errors.vin = "")}
                        value={vin}
                        onChange={(e) => setVin(e.target.value)}
                        error={errors.vin}
                      />
                      <span className="error">{errors.vin}</span>
                    </div>
                  ) : (
                    <></>
                  )}

                  {stockStatus === "Pending" ? (
                    <div className={classes.feildswidth}>
                      <Input
                        type={"Select Menus Simple native"}
                        label="Number Of Vehicles"
                        onInput={() => (errors.numberOfVehicles = "")}
                        value={numberOfVehicles === 0 ? "" : numberOfVehicles}
                        onChange={(e) => {
                          setNumberOfVehicles(e.target.value);
                          errors.stockStatus = "";
                        }}
                        data={NumOfVehicles}
                        dropdownType="Number Of Vehicles"
                        error={errors.numberOfVehicles}
                      />

                      <span className="error">{errors.numberOfVehicles}</span>
                    </div>
                  ) : (
                    <></>
                  )}

                  {numberOfVehicles < 2 ? (
                    <div className={classes.feildswidth}>
                      <Input
                        type={"input with label"}
                        inputType="text"
                        label="Order Number"
                        placeholder="Enter Order number"
                        onInput={() => (errors.orderNo = "")}
                        value={orderNo}
                        onChange={(e) => setOrderNo(e.target.value)}
                        error={errors.orderNo}
                      />
                      <span className="error">{errors.orderNo}</span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className={classes.feildswidth}>
                    <Input
                      type={"radio group"}
                      label="Free Stock/ BBND"
                      onChange={() => {
                        errors.freeStockBBND = "";
                      }}
                      value={freeStockBBND}
                      setState={setFreeStockBBND}
                      data={notificationMethods}
                      error={errors.freeStockBBND}
                    />
                    <span className="error">{errors.freeStockBBND}</span>
                  </div>

                  {stockStatus === "InTransit" ? (
                    <div className={classes.feildswidth}>
                      <Input
                        type={"radio group"}
                        label="Transit Mode"
                        onChange={() => (errors.transitMode = "")}
                        value={transitMode}
                        setState={setTransitMode}
                        data={notificationMethods1}
                        error={errors.transitMode}
                      />
                      {/* <span className="error">{errors.transitMode}</span> */}
                    </div>
                  ) : (
                    <></>
                  )}

                  {stockStatus === "InTransit" ? (
                    <div className={classes.feildswidth}>
                      <Input
                        type={"input with label"}
                        inputType="text"
                        label="Driver Name"
                        placeholder="Enter driver name"
                        onInput={() => (errors.driverName = "")}
                        value={driverName}
                        onChange={(e) => setDriverName(e.target.value)}
                        error={errors.driverName}
                      />
                      <span className="error">{errors.driverName}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {stockStatus === "InTransit" ? (
                    <div className={classes.feildswidth}>
                      <Input
                        type={"input with label"}
                        inputType="text"
                        maxLength="10"
                        label="Driver Number"
                        placeholder="Enter driver number"
                        onInput={() => (errors.driverNo = "")}
                        value={driverNo}
                        onChange={(e) => setDriverNo(e.target.value)}
                        error={errors.driverNo}
                      />
                      <span className="error">{errors.driverNo}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {stockStatus === "InTransit" ? (
                    <div className={classes.feildswidth}>
                      <Input
                        type={"input with label"}
                        inputType="text"
                        label="Truck/ Rail Number"
                        placeholder="Enter truck/ rail number"
                        onInput={() => (errors.truckRailNo = "")}
                        value={truckRailNo}
                        onChange={(e) => setTruckRailNo(e.target.value)}
                        error={errors.truckRailNo}
                      />
                      {/* <span className="error">{errors.truckRailNo}</span> */}
                    </div>
                  ) : (
                    <></>
                  )}

                  {stockStatus === "InTransit" ? (
                    <div className={classes.feildswidth}>
                      <Input
                        type={"input with label"}
                        label="Last Location"
                        inputType="text"
                        placeholder="Enter last location"
                        onInput={() => (errors.lastLocation = "")}
                        value={lastLocation}
                        onChange={(e) => setLastLocation(e.target.value)}
                        error={errors.lastLocation}
                      />
                      <span className="error">{errors.lastLocation}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className={classes.buttons}>
                  <Button
                    buttonText={"Cancel"}
                    type={"whiteButton"}
                    onClick={cancelButton}
                  />
                  <div className="pl-4">
                    <Button
                      buttonText={"Save"}
                      type={"blueFilled"}
                      loading={loading}
                      onClick={() => {
                        formValidation()
                          ? addStockInsertapi()
                          : console.log("Request error");
                      }}
                    />
                  </div>
                </div>
              </form>
              {tableList.length > 0 ? (
                <div className={classes.table}>
                  <Table
                    type="stripped rows"
                    TableData={tableList}
                    tableHeading={tablecolumns}
                    tabledataType="EDP Stock AddStock List"
                  />
                </div>
              ) : (
                <DataNotFound />
              )}

              {maxpages > 1 && (
                <div className={classes.pagination}>
                  <Pagination maxPages={maxpages} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default AddStock;
