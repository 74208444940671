import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Navbar from "../../tailwindUI/Navbar";
import Pagination from "../../tailwindUI/Pagination";
import StackedList from "../../tailwindUI/StackedList";
import classes from "../../module.css/AllocationAwaited.module.css";

import Heading from "../../tailwindUI/Heading";
import ErrorMessage from "../../tailwindUI/ErrorMessage";
import SuccessMessage from "../../tailwindUI/SuccessMessage";
import Modal from "../../tailwindUI/Modal";
import Button from "../../tailwindUI/Button";
import Input from "../../tailwindUI/Input";
import PreferenceCard from "../../tailwindUI/PreferenceCard";
import DataNotFound from "../../Components/DataNotFound";
import FollowUpRemarks from "../../Components/FollowUpRemarks";
import TableHeader from "../../Components/TableHeader";

const AutoAllocation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCarCount, setTotalCarCount] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [cancel, setCancel] = useState(false);
  const [extendCDDModal, setExtendCDDModal] = useState(false);
  const [newCDD, setNewCDD] = useState("");
  const [cddExtentionReason, setCddExtentionReason] = useState("");
  const [cancellationReason, setCancellationReason] = useState("");
  const [values, setValues] = useState({});
  const UserToken = localStorage.getItem("UserToken");
  const [followUpModal, setFollowUpModal] = useState(false);
  const [carDatail, setCarDetail] = useState({});
  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page")) ? queryParams.get("page") : 1;
  const maxPages = Math.ceil(totalCarCount / 20);

  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/login", { replace: true });
  };
  useEffect(() => {
    AMAutoAllocationCancelledListAPi();
  }, [searchTerm === "", page]);

  const AMAutoAllocationCancelledListAPi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      page: page,
      SearchTerm: searchTerm,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMAutoAllocationCancelledList`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" &&
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status_code === 200 && result.status === "Success") {
          setListdata(result.data);
          setTotalCarCount(result.datacount);
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const AMCancelAllocationAPi = (values) => {
    const { CarStockID, BookingID } = values;
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      CarStockID: CarStockID,
      BookingID: BookingID,
      AutoAllocationCancleReason: cancellationReason,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMCancelAllocation`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setCancel(false);
          setMessage(result.message);
          AMAutoAllocationCancelledListAPi();
          setCancellationReason("");
          setErrors("");
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const AMExtendCommitedDateApi = (values) => {
    const { BookingID, CarStockID } = values;
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: UserToken,
      BookingID: BookingID,
      CDDExtensionReason: cddExtentionReason,
      CommittedDeliveryDate: newCDD,
      CarStockID: CarStockID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${global.apiBaseUrl}/api/v1/stockone/am/AMExtendCommitedDate`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setExtendCDDModal(false);
          setMessage(result.message);
          AMAutoAllocationCancelledListAPi();
          setNewCDD("");
          setCddExtentionReason("");
          setErrors("");
        } else if (result.status_code === 400) {
          setErrorMessage("Something went wrong please contact support !");
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMessage("Something went wrong please contact support !");
      })
      .finally(() => setLoading(false));
  };

  const BreadCrumb = {
    pagename: "Allocation Awaited",
    pagePath: "/stm-auto-allocation",
    pagename1: "",
    pagePath1: "",
  };

  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 2500);
    return message;
  };
  const Params = (value) => {
    setValues(value);
  };

  const followUpHandler = (carDetail) => {
    setFollowUpModal(true);
    setCarDetail(carDetail);
  };

  const validation = () => {
    let isValid = true;
    const error = {};
    if (newCDD === "") {
      isValid = false;
      error.newCDD = "Enter New CDD";
    }
    if (cddExtentionReason === "") {
      isValid = false;
      error.cddExtentionReason = "Enter CDD Extension Reason ";
    }
    setErrors(error);
    return isValid;
  };

  const validationCancel = () => {
    let isValid = true;
    const error = {};
    if (cancellationReason === "") {
      isValid = false;
      error.cancellationReason = "Enter Cancellation Reason";
    }
    setErrors(error);
    return isValid;
  };
  return (
    <>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {message && (
        <SuccessMessage
          onClose={() => setMessage("")}
          message={successMessage()}
        />
      )}

      {followUpModal && (
        <FollowUpRemarks
          bookingData={carDatail}
          setOpen={setFollowUpModal}
          open={followUpModal}
          typeAllocation="Auto Allocation Cancelled"
        />
      )}
      {cancel && (
        <Modal
          setOpen={() => {
            setCancel(false);
            setCancellationReason("");
            setErrors("");
          }}
          desc={"Are you sure you want to cancel allocation?"}
          heading={"Cancel Allocation"}
          open={cancel}
          onCancel={() => {
            setCancel(false);
            setCancellationReason("");
            setErrors("");
          }}
          onClick={() => {
            validationCancel()
              ? AMCancelAllocationAPi(values)
              : console.log("err");
          }}
          type="Simple alert"
          loading={loading}
        >
          <div className="mb-2 mt-2">
            <Input
              type={"input with label"}
              placeholder="Enter reason for cancellation"
              label="Cancellation Reason ?"
              inputType="text"
              onInput={() => (errors.cancellationReason = "")}
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
              error={errors.cancellationReason}
            />
            <span className="error">{errors.cancellationReason}</span>
          </div>
        </Modal>
      )}

      {extendCDDModal && (
        <Modal
          setOpen={() => {
            setExtendCDDModal(false);
            setErrors("");
            setNewCDD("");
            setCddExtentionReason("");
          }}
          desc={"Are you sure you want to extend CDD?"}
          heading={"Extend CDD"}
          open={extendCDDModal}
          type="Extend CDD"
        >
          <div className="mb-2 mt-2">
            <PreferenceCard cardType="autoAllocationCancel" values={values} />
          </div>
          <div className="mb-2">
            <Input
              type={"input with label"}
              label="New CDD"
              placeholder="Enter New CDD"
              inputType="date"
              onInput={() => (errors.newCDD = "")}
              value={newCDD}
              onChange={(e) => setNewCDD(e.target.value)}
              error={errors.newCDD}
            />
            <span className="error">{errors.newCDD}</span>
          </div>
          <div className="mb-2">
            <Input
              type={"input with label"}
              placeholder="Enter reason for CDD Extenion"
              label="CDD Extension Reason ?"
              inputType="text"
              onInput={() => (errors.cddExtentionReason = "")}
              value={cddExtentionReason}
              onChange={(e) => setCddExtentionReason(e.target.value)}
              error={errors.cddExtentionReason}
            />
            <span className="error">{errors.cddExtentionReason}</span>
          </div>
          <div className=" mt-4 flex  flex-row-reverse">
            <Button
              buttonText={"Continue"}
              type={"redFilled"}
              loading={loading}
              onClick={() => {
                validation()
                  ? AMExtendCommitedDateApi(values)
                  : console.log("err");
              }}
            />
          </div>
        </Modal>
      )}

      <Navbar navbarType={"black navbar"} />
      <Heading
        headingType={"Page Headings"}
        headingText={"Auto Allocation Cancelled"}
        BreadCrumb={BreadCrumb}
        BreadCrumHomeLink={"/stm-dashboard"}
      />
      <div className={classes.maincontainter}>
        <TableHeader
          buttonText="Search"
          tableHeading="Search Criteria"
          subHeading="subHeading"
          totalResult={totalCarCount}
          pageResult={listdata.length}
          placeholderType={
            "For Ex:-(Model, Variant, Colour,ASM, Executive, Customer Name, Customer Number)"
          }
          apicall={AMAutoAllocationCancelledListAPi}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />

        {loading ? (
          <div className={"w-full bg-white  "}>
            <img
              className="m-auto"
              src="../assets/loadingGif.gif"
              alt="loading"
            />
          </div>
        ) : (
          <div className={classes.list}>
            <div className={classes.stackListContainer}>
              <div role="list" className={classes.stackListWrapper}>
                {listdata?.length > 0 ? (
                  listdata?.map((item, index) => {
                    return (
                      <div className={classes.list}>
                        <div className={classes.stackListContainer}>
                          <div role="list" className={classes.stackListWrapper}>
                            <StackedList
                              type="Auto allocation cancelled"
                              data={item}
                              setCancel={setCancel}
                              setExtendCDDModal={setExtendCDDModal}
                              Params={Params}
                              followUpHandler={followUpHandler}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <DataNotFound />
                )}
              </div>
            </div>

            <div className="bg-white">
              {maxPages > 1 && <Pagination maxPages={maxPages} />}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AutoAllocation;
