import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GridListCards from "../../tailwindUI/GridListCards";
import Heading from "../../tailwindUI/Heading";
import classes from "../../module.css/RealTimeStockTwo.module.css";
import Navbar from "../../tailwindUI/Navbar";

const AdminRTSPageTwo = () => {
  const navigate = useNavigate();
  const { modelName } = useParams();
  const [stockData, setStockdata] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    carStock();
  }, []);

  const getUsersData = JSON.parse(localStorage.getItem("userdata"));
  const stockManagerFilter = getUsersData?.filter(
    (stockManager) => stockManager.UserRole === "ShowroomManager"
  );
  const stockManagerToken = stockManagerFilter[0]?.UserToken;

  const BreadCrumb = {
    pagename: "Real Time Stock",
    pagePath: "/creta-real-time-stock-one",
    pagename1: "Variant List",
    pagePath1: "/creta-real-time-stock-two/" + modelName,
  };
  const LogOut = () => {
    localStorage.removeItem("UserName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("Location");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserLoggedin");
    navigate("/creta", { replace: true });
  };

  const carStock = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", global.authToken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: stockManagerToken,
      Model: modelName.replace(/-/g, "/"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${global.apiBaseUrl}/api/v1/stockone/common/RTSList`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (
          result.data ===
            "Invalid User Token, The token may have expired, You may need to re-login" ||
          result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else if (result.status === "Success" && result.status_code === 200) {
          setStockdata(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {" "}
      <Navbar navbarType="admin navbar" />
      <Heading
        headingType={"Page Headings"}
        headingText={"Dashboard"}
        BreadCrumb={BreadCrumb}
      />
      {loading ? (
        <div className={classes.loader}>
          <img src="../assets/loadingGif.gif" alt="loading" />
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <div className="sm:flex sm:justify-between sm:items center bg-white p-4">
            <div className="sm:flex pl-4 sm:pl-0 ">
              <div className="mr-2 text-xs">
                <span className="bg-green-600 w-2 h-2 inline-block mr-1"></span>
                Available In Stock
              </div>
              <div className="mr-2 text-xs">
                <span className="bg-blue-600 w-2 h-2 inline-block mr-1"></span>
                InStock But Allocated
              </div>
              <div className="mr-2 text-xs">
                <span className="bg-red-500 w-2 h-2 inline-block mr-1"></span>
                Out Of Stock
              </div>
            </div>
          </div>
          <GridListCards
            modelName={modelName}
            stockList={stockData}
            url={"/creta-real-time-stock-three"}
          />
        </div>
      )}
    </>
  );
};

export default AdminRTSPageTwo;
